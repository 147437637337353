import React,{useState,useEffect} from 'react'
import { Tabs} from 'antd-mobile'
import {withRouter} from 'react-router-dom'
import cls from 'classnames'

import Head from '../../../component/Head'
import Scroll from '../../../component/scroll'

import { medcategoryRequest } from '../../../api/request/med'
import { limitactivityRequest } from '../../../api/request/home'

import seckill from '../../../static/img/med/title.png'
import './seckill.less'

const timelist=[
    { time: '22:00',mark:'秒杀进行中'},
    { time: '23:00',mark:'即将开始'},
    { time: '00:00',mark:'明日开始'},
    { time: '10:00',mark:'明日开始'},
]

function Seckill(props) {

    const [category, setCategory] = useState([])
    const [seckilllist, setSeckilllist] = useState([])
    const [timeindex, settimeindex] = useState(0)

    useEffect(() => {
        seckillReq()
    }, [])

    const seckillReq = async() => {
        const res = await Promise.all([
            medcategoryRequest(),
            limitactivityRequest(2)
        ])
        if(res[0].code === 1 && res[0].data[0].length > 0) {
            setCategory(res[0].data[0])
        }
        if(res[1].code === 1 && res[1].data[0].length > 0) {
            setSeckilllist(res[1].data[0])
        }
    }

    const tabchange=(tab,index)=>{
        settimeindex(0)
        limitactivityRequest(tab.categoryId).then(res => {
            if(res.code === 1 && res.data[0].length > 0) {
                setSeckilllist(res.data[0])
            } else {
                setSeckilllist([])
            }
        })
    }

    const medDetail = (medId) => {
        props.history.push(`/med/${medId}`)
    }

    const renderseckilllist=(list=[])=>(
        <Scroll
        className='menu-scroll'
        dataSource={list}
        >
            {list.map((v,index)=>{
                return(
                    <div className='seckill-list-detail flex-row' key={index}>
                        <img src={v.medPhoto} alt=''/>
                        <div className='med-card-detail flexc'>
                            <div className='med-name'>{v.medName}</div>
                            <div className='med-name'>{v.medSpec}</div>
                            <div className='med-mark'>{v.pharmacyName}</div>
                            <div className='med-tag flexr'>
                            {v.promoteFull ?
                                <div>满{v.promoteFull}减{v.promoteCut}</div>
                                : null
                            }
                            </div>
                            <div className='meditem-price flexr'>
                                <span>¥{v.medPrice/100}</span>
                                {v.priceSingle === null ? null :
                                <s>¥{v.priceSingle/100}</s>}
                            </div>
                        </div>
                        <div className='buyseckill flex-col' onClick={()=>medDetail(v.medId)}>
                            <span>马上抢</span>
                            <div className='flex-row'>
                                <div className='progress flexr'>
                                    <div className='progress-bar' style={{width:25*86/100}}></div>
                                </div>
                                <div aria-hidden='true'>86%</div>
                            </div>
                        </div>
                    </div>
                )
            })}
        </Scroll>
    )

    const renderContent=()=>(
        <div className='seckill-content'>
            <div className='seckill-timetab flexr'>
                {timelist.map((v,index)=>{
                    return(
                        <div key={index}
                        className={cls('seckill-timetab-item','flex-col',timeindex === index ? 'activetime' : 'waittime')}
                        onClick={()=>settimeindex(index)}
                        >
                            <p>{v.time}</p>
                            <p>{v.mark}</p>
                        </div>
                    )
                })}
            </div>
            <div className='seckill-list'>
                {renderseckilllist(seckilllist)}
            </div>
        </div>
    )

    return(
        <>
            <Head>
                <img src={seckill} style={{width:80,height:'auto'}} alt=''/>
            </Head>
            <div className='seckill-screen-page' style={{background:'#1F87FF'}}>
            {category.length === 0 ? null :
                <Tabs
                    tabs={category}
                    onChange={tabchange}
                    swipeable={false}
                    tabBarTextStyle={{fontSize:'14px',padding:'0',height:'50px'}}
                    tabBarActiveTextColor='#FFFFFF'
                    tabBarInactiveTextColor='#FFFFFF'
                    tabBarBackgroundColor='none'
                    tabBarUnderlineStyle={{border: '2px #FFFFFF solid',transform:'scaleX(0.3)',bottom:'5px'}}
                    renderTab={tab => <span>{tab.categoryName}</span>}>
                        {renderContent}
                </Tabs>
            }
            </div>

        </>
    )

}

export default withRouter(Seckill)
