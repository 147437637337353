import React ,{useState}from 'react'
import {withRouter} from "react-router-dom"
import { Checkbox, WhiteSpace,InputItem } from 'antd-mobile'

import Head from '../../component/Head'
import Tag from '../../baseui/tag/index'
import Popup from '../../component/Popup/index'

import './adduser.less'

const CheckboxItem = Checkbox.CheckboxItem

function AddUser(props) {
    const [show,setShow]=useState(false)

    const clk=()=>{
        setShow(true)
    }
    return(
        <>
            <Popup full={true} show={show} sethowshow={setShow.bind(null, {})}>
                <div className='pop-adduser-tab'>
                    <span>添加基础病</span>
                    <i className='iconfont icon-ic_close pop-adduser-close'></i>
                </div>
                <div className='pop-adduser-input flexr'>
                    <input type="text"  placeholder="请输入基础病"/>
                </div>
                <div className='pop-adduser-button'>确认添加</div>
            </Popup>
            <Head title='处方信息'></Head>
            <div className='screen userinfo' style={{bottom:50}}>
                    <WhiteSpace></WhiteSpace>
                    <InputItem placeholder="药品使用人姓名">真实姓名</InputItem>
                    <div className='flexr user-sex'>
                        <span>性别</span>
                        <CheckboxItem style={{width:100}}>男</CheckboxItem>
                        <CheckboxItem  style={{width:100}}>女</CheckboxItem>
                    </div>
                    <InputItem placeholder="药品使用人联系电话">手机号</InputItem>
                    <InputItem placeholder="身份证">身份证</InputItem>
                    <WhiteSpace></WhiteSpace>
                    <InputItem placeholder="病史、过敏史、家族病史">健康信息</InputItem>
                    <WhiteSpace></WhiteSpace>
                    <InputItem placeholder="mmol/L">血糖值</InputItem>
                    <WhiteSpace></WhiteSpace>
                    <InputItem placeholder="mmHg">血压值</InputItem>
                    <WhiteSpace></WhiteSpace>
                    <div className='baseill-info flexc'>
                        <span>请选择下列基础病信息</span>
                        <div>
                            <Tag maincolor='#F34848' subcolor='#F4E3E5' text='高血压'/>
                            <Tag maincolor='#F34848' subcolor='#F4E3E5' text='原发高血压性'/>
                            <Tag maincolor='#F34848' subcolor='#F4E3E5' text='+'/>
                        </div>
                    </div>

            </div>
            <div className='bottom-button' onClick={clk}>保存</div>
        </>
    )
}

export default withRouter(AddUser)