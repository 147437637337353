import React, {useRef, useState, useCallback } from 'react';
import { CSSTransition } from 'react-transition-group';

import './style.less'

export default function Popup(props) {
  const [isShow, setIsShow] = useState(false);
  const [canTouch,setCanTouch] = useState(true);
  const [startY, setStartY] = useState(0);
  const [initialed, setInitialed] = useState(0);
  const [distance, setDistance] = useState(0);

  const listWrapperRef = useRef();
  const popBackRef = useRef();

  const {show,sethowshow,full=false}=props

  const handleScroll = (pos) => {
    let state = pos.y === 0;
    setCanTouch(state);
  }

  const handleTouchStart = (e) => {
    if(!canTouch || initialed) return;
    listWrapperRef.current.style["transition"] = "";
    setDistance(0);
    setStartY(e.nativeEvent.touches[0].pageY);
    setInitialed(true);
  };

  const handleTouchMove = (e) => {
    if(!canTouch || !initialed) return;
    let distance = e.nativeEvent.touches[0].pageY - startY;
    if(distance < 0) return;
    setDistance(distance);
    listWrapperRef.current.style["transform"] = `translate3d(0, ${distance}px, 0)`;
  };

  const handleTouchEnd = (e) => {
    setInitialed(false);
    if(distance >= 150) {
      setIsShow(false);
    } else {
      listWrapperRef.current.style["transition"] = "all 0.3s";
      listWrapperRef.current.style["transform"] = `translate3d(0px, 0px, 0px)`;
    }
  };

  const onEnterCB = useCallback(() => {
    setIsShow(true);
    sethowshow(false)
    listWrapperRef.current.style["transform"] = `translate3d(0, 100%, 0)`;
  }, ["transform"]);
 
  const onEnteringCB = useCallback(() => {
    listWrapperRef.current.style["transition"] = "all 0.3s";
    listWrapperRef.current.style["transform"] = `translate3d(0, 0, 0)`;
  }, ["transform"]);

  const onExitCB = useCallback(() => {
    listWrapperRef.current.style["transform"] = `translate3d(0, ${distance}px, 0)`;
  }, [distance,"transform"]);
 
  const onExitingCB = useCallback(() => {
    listWrapperRef.current.style["transition"] = "all 0.3s";
    listWrapperRef.current.style["transform"] = `translate3d(0px, 100%, 0px)`;
  }, ["transform"]);

  const onExitedCB = useCallback(() => {
    setIsShow(false);
    listWrapperRef.current.style["transform"] = `translate3d(0px, 100%, 0px)`;
  }, ["transform"]);

  return(
    <CSSTransition 
      in={show} 
      timeout={300} 
      classNames="list-fade"
      onEnter={onEnterCB}
      onEntering={onEnteringCB}
      onExit={onExitCB}
      onExiting={onExitingCB}
      onExited={onExitedCB}
    >
      <div
       className="wrapper"
       ref={popBackRef} 
       style={isShow === true ? { display: "block" } : { display: "none" }} 
       onClick={() => setIsShow(false)}
      >
        <div
          className="list_wrapper"
          style={full === true ? { left:0,right:0} : null}
          ref={listWrapperRef} 
          onClick={e => e.stopPropagation()}
          onTouchStart={handleTouchStart}
          onTouchMove={handleTouchMove}
          onTouchEnd={handleTouchEnd}
        >
          <div className="zhanwei">
            {props.children}
          </div>
        </div>
      </div>
    </CSSTransition>
  )
}
