import React, { Component } from 'react'
import FH from "../../../static/img/mine/fanhui.png"
import {getuserstoreinfo} from "../../../api/request/user"
import "./style.less"
export default class index extends Component {
    constructor(){
        super()
        this.state={
            userId:localStorage.type == "1" ? localStorage.userId1 : localStorage.type == "2" ? localStorage.userId2 : localStorage.userId3,
            storename:"",
            name:"",
            phone:null,
            address:""
        }
    }
    componentDidMount=async()=>{
        const {userId}=this.state
        //console.log(userId)
        const res=await getuserstoreinfo({
            qualiType:0,
            userId:userId
        })
        this.setState({
            storename:res.data[0][0].companyName,
            name:res.data[0][0].Contacts,
            phone:res.data[0][0].contactPhone,
            address:res.data[0][0].companyLocation+res.data[0][0].companyAddress
        })
    }
    render() {
        const {storename,name,phone,address}=this.state
        return (
            <div>
                <div className="infortop">
                    <img src={FH} alt="" onClick={()=>window.history.back()}/><h2>门店资料</h2>
                </div>
                <div className="infortext">
                    <span>门店名称</span><span>{storename}</span>
                </div>
                <div className="infortext">
                <span>联系人</span><span>{name}   {phone}</span>
                </div>
                <div className="inforbottom">
                    <p>经营地址</p><p style={{marginLeft:"-8px"}}>{address}</p>
                </div>
            </div>
        )
    }
}
