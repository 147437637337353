import React ,{useState}from 'react'

import './style.less'

export default function Select(props) {
    const { onFilter }=props
    const [choose]=useState(false)
    const [recommend, setRecommend] = useState(0)
    const [sales, setSales] = useState(0)
    const [distance, setDistance] = useState(0)
    const [price, setPrice] = useState(0)

    const onRecommend = () => {
        const num = recommend===0?1:recommend===1?2:0
        setRecommend(num)
        setSales(0)
        setDistance(0)
        setPrice(0)
        onFilter('1', num)
    }

    const onSales = () => {
        const num = sales===0?1:sales===1?2:0
        setRecommend(0)
        setSales(num)
        setDistance(0)
        setPrice(0)
        onFilter('2', num)
    }

    const onDistance = () => {
        const num = distance===0?1:distance===1?2:0
        setRecommend(0)
        setSales(0)
        setDistance(num)
        setPrice(0)
        onFilter('3', num)
    }
    
    const onPrice = () => {
        const num = price===0?1:price===1?2:0
        setRecommend(0)
        setSales(0)
        setDistance(0)
        setPrice(num)
        onFilter('4', num)
    }

    return(
        <>
        <div className='select-wrapper'>
            <div className={`select-item ${recommend===0?'':'select-selected'}`}
            onClick={onRecommend}>
                <span>推荐</span>
            {
                recommend===0?<i className='iconfont-s icon-shaixuan'></i>:
                recommend===1?<i className='iconfont-s icon-xiangxia1'></i>:
                <i className='iconfont icon-xiangshang1'></i>
            }
            </div>
            <div className={`select-item ${sales===0?'':'select-selected'}`}
            onClick={onSales}>
                <span>销量</span>
            {
                sales===0?<i className='iconfont-s icon-shaixuan'></i>:
                sales===1?<i className='iconfont-s icon-xiangxia1'></i>:
                <i className='iconfont icon-xiangshang1'></i>
            }
            </div>
            <div className={`select-item ${distance===0?'':'select-selected'}`}
            onClick={onDistance}>
                <span>距离</span>
            {
                distance===0?<i className='iconfont-s icon-shaixuan'></i>:
                distance===1?<i className='iconfont-s icon-xiangxia1'></i>:
                <i className='iconfont icon-xiangshang1'></i>
            }
            </div>
            <div className={`select-item ${price===0?'':'select-selected'}`}
            onClick={onPrice}>
                <span>价格</span>
            {
                price===0?<i className='iconfont-s icon-shaixuan'></i>:
                price===1?<i className='iconfont-s icon-xiangxia1'></i>:
                <i className='iconfont icon-xiangshang1'></i>
            }
            </div>
            {/* <div className='select-item'>
                <span>筛选</span>
                <i className='iconfont-s icon-shaixuan1'></i>
            </div> */}
            {choose?
                <div className='select-tab'>
                    <div className='select-tabline flex-apart-row'>
                        <span>推荐排序</span>
                        <span>✔</span>
                    </div>
                    <div className='select-tabline flex-apart-row'>
                        <span>优惠排序</span>
                    </div>
                </div>
            :null}

        </div>
        
        </>
    )
}