import React from 'react'

import '../../static/css/iconfont.css'
import './style.less'

export default function BaseInput(props){
    // const [value, setvalue] = useState('')
    const{value}=props

    const inputOnFocus=()=>{}
    return (
        <div className='base-search-container'>
            <i className="iconfont icon-suosou"></i>
            <input
                type="text"
                className=""
                onFocus={inputOnFocus}
                placeholder={value}
            />
        </div>
    )
}