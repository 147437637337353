import React,{useState} from 'react'
import {withRouter} from "react-router-dom"

// import Head from '../../../component/Head'
import Tag from '../../../baseui/tag/index'
// import ChooseButton from '../../../baseui/choosebutton/index'
import Popup from '../../../component/Popup/index'

import IMG from'../../../static/img/head.png'
import icon1 from'../../../static/img/icon/way1.png'
import icon2 from'../../../static/img/icon/way2.png'
import './style.less'

// const hospitallist=[
//     {id:'1',name:'成都市锦江区第二人民医院',title:['三级甲等','综合医院'],location:'成都市青羊区青龙街82号',phone:'028-61312288',count:700,num:58},
//     {id:'2',name:'成都市锦江区第二人民医院',title:['三级甲等','综合医院'],location:'成都市青羊区青龙街82号',phone:'028-61312288',count:700,num:58}
// ];

function InquiryDoctor(props) {
    const [show,setShow]=useState(false)


    const clk=()=>{
        setShow(true)
    }
    const backPage=()=>{
        window.history.back()
    }
    return(
        <>
            <Popup show={show} sethowshow={setShow.bind(null, {})}>
                <div className='pop-close'>
                    <i className='iconfont-l icon-ic_close'></i>
                </div>
                <div className='pop-title'>选择问诊方式</div>
                <div className='pop-tab'  onClick={()=>props.history.push('/inquiry/patientinfo')}>
                    <img src={icon1} alt=""/>
                    <span>图文问诊</span>
                    <i className='iconfont-l icon-xiangyou'></i>
                </div>
                <div className='pop-tab' style={{border:'none'}} onClick={()=>props.history.push('/inquiry/list/phone')}>
                    <img src={icon2} alt=""/>
                    <span>电话问诊</span>
                    <i className='iconfont-l icon-xiangyou'></i>
                </div>
            </Popup>
            <div className='inquiry-doctor-groudimg'></div>
            <div className='screen' style={{zIndex:-2}}></div>
            <div onClick={clk} className='bottom-button'>咨询</div>
            <div className='inquiry-doctor-back'>
                <i onClick={backPage} className='iconfont icon-xiangzuo'></i>
            </div>
            <div className='inquiry-doctor-content flexc'>
                <div className='inquiry-doctor-brief'>
                    <div className='item1'>
                        <img src={IMG} alt=""/>
                    </div>
                    <div className='item2'>
                        <div className='flexr'>
                            <p>朱心寒</p>
                            <Tag text='副主任医师' subcolor='#FFFFFF'/>
                            <Tag text='三甲甲等' subcolor='#FFFFFF'/>
                        </div>
                        <div style={{maxWidth:130}}>
                            <Tag text='绵阳市人民医院 内科' subcolor='#FFFFFF'/>
                        </div>  
                    </div>
                    <Tag className='item3' w={54} text='关注' type='radius' active={false}/>
                </div>
                <div className='inquiry-doctor-data flex-row'>
                    <div className='flex-col'>
                        <span>700</span>
                        <span>问诊次数</span>
                    </div>
                    <div className='flex-col'>
                        <span>98%</span>
                        <span>好评率</span>
                    </div>
                </div>
                <div className='inquiry-doctor-card flexc'>
                    <div className='flexr-center'>
                        <div className='inquiry-color-block'></div>
                        <span>专业擅长</span>
                    </div>
                    <div>内科心血管、呼吸内科、长期从事呼吸内科的医疗、教学、科研工作。重点开展哮喘，慢阻肺疾病，呼吸衰竭和呼吸系统常见疾病的规范化诊疗、疑难病、少见病和呼吸危重症监护与救治等方面的研究。</div>
                </div>
                <div className='inquiry-doctor-way flexc'>
                    <div className='flexr-center'>
                        <div className='inquiry-color-block'></div>
                        <span>问诊</span>
                    </div>
                    <div className='inquiry-choose-area'>
                        <div className='inquiry-item1 flex-col'>
                            <img src={icon1} alt=""/>
                            <span>图文咨询</span>
                            <span>￥20/15分钟</span>
                        </div>
                        <div className='inquiry-item2'></div>
                        <div className='inquiry-item3 flex-col'>
                            <img src={icon2} alt=""/>
                            <span>电话咨询</span>
                            <span>￥100/15分钟</span>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default withRouter(InquiryDoctor)