import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import OtherActivity from './otherActivity'
import SecondKill from './secondKill'

import getUrl from '../../component/GetUrlParam'

import './style.less'


class yygActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pharmacyId: localStorage.getItem('pharmacyId'),
            type: getUrl('type', props.location.search)
        }
    }

    render() {
        const { pharmacyId, type } = this.state

        return (
            <>
            {pharmacyId === '0' ?
                <OtherActivity props={this.props} pharmacyId={pharmacyId} type={type} /> :
                <SecondKill props={this.props} pharmacyId={pharmacyId} type={type} />
            }
            </>
        )
    }
}

export default withRouter(yygActivity)