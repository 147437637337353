import React ,{Component}from 'react'

class Home  extends Component{
    render(){
        return(
            <div className="App">404：页面不存在</div>
        )
    }
}

export default Home