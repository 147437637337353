import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../../static/css/iconfont.css";
import icon1 from "../../static/img/icon/user.png";
import "./style.less";
export default function Patientnews(props) {
  const { news } = props;
  let item = news;
  return (
    <div>
      <div className="offaddcf-user-news">
        <p className="offaddcf-news">
          <span className="offaddcf-news-left">
            <img src={icon1} />
            <span>{item.patientName}</span>
          </span>
          <span className="offaddcf-news-right">
            {item.patientSex == 1 ? "男" : "女"}/{item.patient_age}岁{item.patient_age < 7 ? '/' + item.bodyweight+'KG': null}
          </span>
          <span>
            {item.patientSex == 2 ? (
              <>
                <p className="offaddcf-news-ill">
                  怀孕情况：
                  <span className="">
                  {item.pregnancy_status == 1
                    ? "未怀孕"
                    : item.pregnancy_status == 2
                      ? "备孕中"
                      : item.pregnancy_status == 3
                        ? "已怀孕"
                        : item.pregnancy_status == 4
                          ? "哺乳期"
                          : ''}
                  </span>
                </p>
              </>
            ) : null}
          </span>
        </p>
        {/* <p className="xu-line"></p>
        <p className="offaddcf-news-address">
          <span>
            电话：
            {item.patient_phone.substr(0, 3) +
              "****" +
              item.patient_phone.substr(-4)}
          </span>
          <span></span>
        </p> */}
        <p className="xu-line"></p>
        <p className="offaddcf-news-ill">
          疾病史：
          <span className={item.past_history == 0 ? "" : "blue"}>
            {item.past_history_note}
          </span>
        </p>
        <p className="xu-line"></p>
        <p className="offaddcf-news-ill">
          过敏史：
          <span className={item.allergic_history == 0 ? "" : "blue"}>
            {item.allergic_history_note}
          </span>
        </p>
        <p className="xu-line"></p>
        <p className="offaddcf-gan-shen">
          <span>
            肝肾功能：
            <span className={item.abnormal_liver_function == 0 ? "" : "blue"}>
              {item.abnormal_liver_function == 0 ? "无异常" : "有异常"}
            </span>
          </span>
          {/* <span>
            肾功能：
            <span className={item.abnormal_renal_function == 0 ? "" : "blue"}>
              {item.abnormal_renal_function == 0 ? "无异常" : "有异常"}
            </span>
          </span> */}
        </p>
        <p className="xu-line"></p>
        <p className="offaddcf-gan-shen">
          <span>
            体温：
            <span className={item.bodytemperature == 1 && item.bodytemperaturenum && (item.bodytemperaturenum < 36 || item.bodytemperaturenum > 37) ? "redcolor" : "blue1"}>
              {item.bodytemperature == '1' && item.bodytemperaturenum ? item.bodytemperaturenum + '℃' : '正常'}
              {/* {item.bodytemperature == '0' ? "正常" : item.bodytemperaturenum + '℃'} */}
            </span>
          </span>
        </p>
        {/* <p className="xu-line"></p>
        <p className="offaddcf-news-ill">
          家族遗传史：
          <span className={item.family_genetic_history == 0 ? "" : "blue"}>
            {item.family_genetic_history_note}
          </span>
        </p> */}
        {/* {item.patientSex == 2 ? (
          <>
            <p className="xu-line"></p>
            <p className="offaddcf-news-ill">
              怀孕情况：
              <span className="">
                {item.pregnancy_status == 1
                  ? "未怀孕"
                  : item.pregnancy_status == 2
                  ? "备孕中"
                  : item.pregnancy_status == 3
                  ? "已怀孕"
                  : "哺乳期"}
              </span>
            </p>
          </>
        ) : null} */}
      </div>
    </div>
  );
}
