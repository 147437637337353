import React from 'react'
import {withRouter} from "react-router-dom"

import Head from '../../../component/Head'
import StepButon from '../../../baseui/stepbutton/index'
import Tag from '../../../baseui/tag/index'

import IMG from'../../../static/img/head.png'

import './inquirylist1.less'

const text='详细描述您的疾病症状，包括发病诱因、症状、病情加重或环境情况、家族病史、是否怀孕等...'

function PatientInfo(props) {
    return (
        <>
            <Head title='问诊单'></Head>
            <div className='screen'>
                <div>
                    <div className='inquiry-doctor-card flexr'>
                        <img src={IMG} alt=""/>
                        <div className='inquiry-doctor-card-detail flexc'>
                            <div className='inquiry-doctor-name'>
                                <p>朱心寒</p>
                                <div className='inquiry-doctor-tag flexr'>
                                    <Tag text='副主任医师' type='frame'/>
                                    <Tag text='三甲甲等' type='frame'/>
                                </div>
                                <span></span>
                            </div>
                            <div className='inquiry-doctor-belong'>绵阳市人民医院&emsp;内科</div>
                            <div className='inquiry-doctor-data flexr'>
                                好评率&nbsp;<p>98%</p>&emsp;&emsp;问诊次数&nbsp;<p>700</p>&emsp;&emsp;<i className='iconfont icon-xingxing'> 4.8</i>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='inquiry-inputlist'>
                    <div>
                        <span>是否去医院就诊过</span>
                        <div>
                            <i className='iconfont icon-wancheng-copy'></i>&nbsp;是
                            <div className='quanquan'></div>&nbsp;否
                        </div>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '100%',
                        backgroundColor: '#DDDFE5'
                    }}></div>
                    <div>
                        <span>病情描述</span>
                        <textarea className='disease-text' placeholder={text} name="" id="illmark" cols="30" rows="10"></textarea>
                        <p>0/200</p>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '100%',
                        backgroundColor: '#DDDFE5'
                    }}></div>
                    <div>
                        <div>
                            <span>添加图片</span>
                            <span>可添加患处、病历、检查单（6张）</span>
                        </div>
                        <div className='upload-img flex-row'>
                            <span>+</span>
                        </div>
                    </div>
                </div>
            </div>
            <StepButon name='提交问诊' onClick={()=>props.history.push('/inquiry/pay')}>
                <div className='flex-row'>
                    <div className='agreecheck'></div>
                    <span>我已阅读并同意《用户知情同意告知书》</span>
                </div>
            </StepButon>
        </>
    )
}

export default withRouter(PatientInfo)