import React from 'react'
import {withRouter} from "react-router-dom"

import Tag from '../../baseui/tag/index'

import './doctoritem.less'
import '../../static/css/iconfont.css'
import IMG from'../../static/img/head.png'
import icon1 from'../../static/img/icon/way1.png'
import icon2 from'../../static/img/icon/way2.png'

const doctorlist=[
    {id:'1',name:'朱心寒',title:['副主任医师','三甲甲等'],hospital:'绵阳市人民医院',department:'内科',favrate:'98%',count:700,star:4.8,brief:'内科心血管、呼吸内科、长期从事呼吸内科的医疗、教学、科研工作...',onlinefee:20,phonefee:100},
    {id:'2',name:'孟智新',title:['副主任医师','三甲甲等'],hospital:'绵阳市人民医院',department:'内科',favrate:'98%',count:700,star:4.8,brief:'内科心血管、呼吸内科、长期从事呼吸内科的医疗、教学、科研工作...',onlinefee:20,phonefee:100}
];

function DoctorList(props) {
    return(
        <div className='doctor-list flexc'>
            {doctorlist.map((v,index)=>{
                return(
                    <>
                    <div className='doctor-card flexr' key={index}  onClick={()=>props.history.push('/inquiry/doctor')}>
                        <img src={IMG} alt=""/>
                        <div className='doctor-card-detail flexc'>
                            <div className='doctor-name'>
                                <p>{v.name}</p>
                                <div className='doctor-tag flexr'>
                                    {v.title.map((t,i)=>{
                                        return(<Tag key={i} text={t} type='frame'/>)
                                    })}
                                </div>
                                <span>关注</span>
                            </div>
                            <div className='doctor-belong'>{v.hospital}&emsp;{v.department}</div>
                            <div className='doctor-data flexr'>
                                好评率&nbsp;<p>{v.favrate}</p>&emsp;&emsp;问诊次数&nbsp;<p>{v.count}</p>&emsp;&emsp;<i className='iconfont icon-xingxing'> {v.star}</i>
                            </div>
                                <div className='doctor-brief'><span>擅长领域：</span>{v.brief}</div>
                            <div className='doctor-seek flex-apart-row'>
                                <div className='doctor-seek-tab flexr'>
                                    <img src={icon1} alt=""/>
                                    <span>￥{v.onlinefee}</span>
                                    <span>/15分钟</span>
                                </div>
                                <div className='doctor-seek-tab flexr'>
                                    <img src={icon2} alt=""/>
                                    <span>￥{v.onlinefee}</span>
                                    <span>/15分钟</span>
                                </div>
                                <Tag text={'咨询'} type={'radius'} active={true}/>
                            </div>
                            
                        </div>
                    </div>
                    {index!==doctorlist.length-1?
                        <div className='doctorlist-line'></div>
                    :null}
                    </>
                )
            })}
        </div>
    )
}

export default withRouter(DoctorList)