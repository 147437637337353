import React from 'react'
import {withRouter} from "react-router-dom"
import { Checkbox, WhiteSpace } from 'antd-mobile'

import Head from '../../component/Head'

import './userlist.less'

const CheckboxItem = Checkbox.CheckboxItem

function Userlist(props) {

    const userlist=[
        {name:'杨煜',tel:'138 8028 9018',idcard:'51**************87'},
        {name:'杨煜',tel:'138 8028 9018',idcard:'51**************87'},
        {name:'杨煜',tel:'138 8028 9018',idcard:'51**************87'}
    ]
    return(
        <>
            <Head title='用药人列表'></Head>
            <div className='screen' style={{bottom:50}}>
                <WhiteSpace></WhiteSpace>
                <div className='add-user flex-row'><span>+</span><span>新建用药人</span></div>
                <div style={{
                    height: '1px',
                    width: '100%',
                    backgroundColor: '#DDDFE5'
                }}></div>
                <div>
                    {userlist.map((v,index)=>{
                        return(
                            <div className='user-item flex-apart-row' key={index}>
                                <div className='flexc'>
                                    <span>{v.name}</span>
                                    <span>手机号{v.tel}</span>
                                    <span>身份证{v.idcard}</span>
                                </div>
                                <div className="my-radio">
                                    <CheckboxItem />
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className='bottom-button'  onClick={()=>props.history.push('/inquiry/list/imagetext')}>确认</div>
        </>
    )
}

export default withRouter(Userlist)