import React, { Component } from 'react'
import { connect } from 'react-redux'
// import { withRouter } from 'react-router-dom'
import { Button } from 'antd-mobile'

import {resetdirect} from '../../redux/user.redux'
import { actMedListRequest } from '../../api/request/med'

import activeBanner1 from '../../static/img/onekill.png'
import activeBanner2 from '../../static/img/ninekill.png'
import './style.less'


class SecondKill extends Component {
    constructor(props) {
        super(props)
        this.state = {
            props: this.props.props,
            userId: localStorage.userId,
            pharmacyId: props.pharmacyId,
            type: props.type,
            singleTitle: localStorage.singleTitle,
            singleSubtitlea: localStorage.singleSubtitlea,
            recommand: []
        }
    }

    componentDidMount() {
        actMedListRequest({
            type:this.state.type,
            pharmacyId:this.state.pharmacyId
        }).then(res => {
            if(res.code === 1 && res.data[0].length > 0) {
                this.setState({
                    recommand: res.data[0]
                })
            }
        })
    }

    backPage=()=>{
        window.history.back()
    }

    buyNow = (type, item) => {
        const cartArr = [{
            pharmacy: {
                pharmacyId: item.pharmacyId,
                pharmacyName: item.pharmacyName,
                freight: item.freight
            },
            medList: [{
                medId: item.medId,
                medName: item.medName,
                medPhoto: item.medPhoto,
                medSpec: item.medSpec,
                medPacking: item.medPacking,
                medApproval: item.medApproval,
                medCompany: item.medCompany,
                medPrice: type==='1'?100:990,
                cartPrice: type==='1'?100:990,
                medQuantity: 1
            }]
        }]
        if(this.state.userId) {
            this.state.props.history.push({ pathname: '/fillorder', state: cartArr })
        } else {
            this.props.redirectDispatch(`${this.state.props.location.pathname}?type=${this.state.type}`)
            this.state.props.history.push('/login')
        }
    }

    render() {
        const { type, recommand, singleTitle, singleSubtitlea } = this.state

        return (
            <div className='active-container'>
                <div className='second-active-header flexr'>
                    <div className='second-active-goback' onClick={this.backPage}>
                        <i className='iconfont icon-xiangzuo'></i>
                    </div>
                    <div className='second-active-title'>
                        <span>{singleTitle}</span>
                        <span>{singleSubtitlea}</span>
                    </div>
                </div>
                <div className='second-active-banner'>
                {type === '1' ?
                    <img src={activeBanner1} alt=''/> :
                    <img src={activeBanner2} alt=''/>
                }
                </div>
                <div className='second-active-med'>
                    {/* <div className='second-active-descript'>
                        xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
                    </div> */}
                    <div className='second-active-med-title flexc'>
                        <div className='flexc'>
                            <span>以下商品限选一件</span>
                        </div>
                    </div>
                    <div className='second-active-med-list'>
                    {recommand.map((item, index) => (
                        <div key={index} className='second-active-med-item flexr'>
                            <div className='second-active-med-pic flex-center'>
                                <img src={item.medPhoto} alt=''/>
                            </div>
                            <div className='second-active-med-detail flexc'>
                                <div className='second-active-med-info'>
                                    <div>{item.medName}</div>
                                    <div>规格：{item.medSpec}</div>
                                </div>
                                <div className='second-active-med-price flexr'>
                                    <div>￥{item.medPrice/100}</div>
                                    <div>
                                    {type === '1' ?
                                        <Button type='warning'
                                        onClick={()=>this.buyNow('1',item)}>1元领取</Button> :
                                        <Button type='warning'
                                        onClick={()=>this.buyNow('2',item)}>9.9元抢购</Button>
                                    }
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                    </div>
                </div>
            </div>
        )
    }
}

// 映射Redux全局的state到组件的props上
const mapStateToProps = (state) => ({
})


// 映射dispatch到props上
const mapDispatchToProps = (dispatch) => {
    return {
        redirectDispatch(data) {
            dispatch(resetdirect(data))
        },
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SecondKill)