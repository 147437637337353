import React, { Component,createRef } from 'react'
import "./style.less"
import icon1 from "../../static/img/yy/fanhuihei.png"
import icon2 from "../../static/img/login/login-bgc.png"
import icon3 from "../../static/img/login/login-bgc1.png"
import { Toast,Dialog } from "antd-mobile-v5"
import TipAlert from "./TipAlert";
import '../../static/fonts/font_3834206_vpijbgwacs9/iconfont.css'
import {
	getlocode, getrecode, inspectonly, verificationcode, register, userlogin,
	getuserinfo, loginDuty, updatesxVerify, getLoginInfo,selectLoginSwitch,
	twoLogin,towRegister,addOperationLog
} from "../../api/hospitalapi/login"
import moment, { duration } from 'moment'
import localStorage from 'redux-persist/es/storage'
import getUrl from '../../component/GetUrlParam'
import { sync } from 'resolve'
import { jumpUrl } from '../../publicurl'
import { WhiteSpace, Checkbox, Modal, List } from 'antd-mobile'
import md5 from 'js-md5';
import res from 'antd-mobile-icons/es/AaOutline'
import {openidIsExist} from "../../api/request/openid";
const CheckboxItem = Checkbox.CheckboxItem
export default class login extends Component {
	constructor() {
		super()
		this.state = {
			number: "",
			status: false,
			code: "",
			time: 60,
			type: 0,
			tip: 0,
			valid: true,//获取验证码状态位
			valid1: true,//登录或注册状态位
			password: '',//密码
			selectcode: 0,
			readcode: 0,
			loginwaycode: 1,//1、验证码登录 2、密码登录
			eye: 1,//1、闭眼 2、睁眼
			showTip: false,
			// loginAttempts:0, //登录尝试次数
			// isLocked:false
			isAccountLocked: false,
			failedLoginCount: 0,
			loginAttempts: 0, //记录失败次数
			loginFailedTime: null, //记录3次登录失败后的当前时间
			disabled: false,//60s获取验证码一次
			isLoginSwitch: 2,// 1：默认，2：双重验证
			tipRed:false,
			userIpAddress:'',
		}
		this.inputRef = createRef();
	}


	componentDidMount = async () => {
		const { number, code, caPassTime, lastFailedTime, loginAttempts } = this.state
		const urlParams = new URLSearchParams(this.props.location.search);
		const isSHowToast = urlParams.get('isSHowToast');
		if (isSHowToast) {
			Toast.show({
				content: "登录已失效，请重新登录",
				duration: 2000     //这里之前是800，他们说要时间停留长点，所以我改成2000
			})
		}

		this.fetchUserIP()

		// 查询登录界面开关
		let res = await selectLoginSwitch()
		
		if(res.code == 1 && res.data[0][0].is_login_switch){
			this.setState({
				isLoginSwitch:res.data[0][0].is_login_switch
			})
		}

		this.setState({
			number: getUrl('number', this.props.location.search) ? getUrl('number', this.props.location.search) : '',
			code: getUrl('code', this.props.location.search) ? getUrl('code', this.props.location.search) : '',
			password: getUrl('password', this.props.location.search) ? getUrl('password', this.props.location.search) : '',
			loginwaycode: getUrl('loginwaycode', this.props.location.search) ? getUrl('loginwaycode', this.props.location.search) : 1,
			tip: getUrl('number', this.props.location.search) && (getUrl('code', this.props.location.search) || getUrl('password', this.props.location.search)) ? 1 : 0
		})
	}
	// 获取手机ip
	fetchUserIP = async () =>{
		try {
			const response = await fetch('https://ipinfo.io/json');
			const data = await response.json();
			this.setState({
				userIpAddress:data.ip
			})
		} catch (error) {
			console.error('Error fetching IP:', error);
		}
	}
	getinputvalue = (e) => {
		if (e.target.value.length > 11) {
			this.setState({
				number: e.target.value.toString().substr(0, 11)
			})
		} else {
			this.setState({
				number: e.target.value
			})
		}
	}
	getpassword = (e) => {
		let pwdYz = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]{8,16}$/;
		let pwdValues = e.target.value.replace(/\s*/g, "")
		
		if(pwdYz.test(pwdValues)){
			this.setState({
				tipRed: false
			})
		}else{
			this.setState({
				tipRed: true
			})
		}
		this.setState({
			password: pwdValues,
			tip: 1,
			showClearIcon: !!pwdValues
		},() => {
            // 更新输入框的值
            this.inputRef.current.value = this.state.password;
        })
	}
	setEye = () => {
		const { eye } = this.state
		let input = document.getElementById("inputId");
		if (eye == 1) {
			input.type = 'text';
			this.setState({
				eye: 2,
			})
		} else {
			input.type = 'password';
			this.setState({
				eye: 1
			})
		}
	}
	getcodenum = (e) => {
		if (e.target.value.length <= 6) {
			this.setState({
				code: e.target.value,
				tip: 1
			})
		} else {
			this.setState({
				code: e.target.value.toString().substr(0, 6),
				tip: 1
			})
		}
	}
	//点击获取验证码
	code = async () => {
		const { number, status, time } = this.state
		const reg_tel = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/
		// if (status) {
		// 	this.setState({
		// 		type: 1
		// 	})
		// 	Toast.show({
		// 		content: `请${time}秒后点击获取`,
		// 		duration: 1000
		// 	})
		// } else {
		// 	this.setState({
		// 		status: true,
		// 		time:60
		// 	},()=>{
		if (number) {
			if (reg_tel.test(number)) {
				if (status) {
					this.setState({
						type: 1
					})
					Toast.show({
						content: `请${time}秒后点击获取`,
						duration: 1000
					})
				} else {
					// 更新失效验证码状态
					updatesxVerify(number).then(res0 => {
						console.log(res0, '150===============');
						if (res0.code == 1) {
							//获取登录验证码
							getlocode({
								userPhone: number
							}).then(res => {
								console.log(res.code, '156=========');
								if (res.code == 1) {
									this.setState({
										status: true,
										time: 60
									}, () => {
										let code = this.state.time
										const timer = setInterval(() => {
											code -= 1
											this.setState({
												time: code,
											})
											if (code == 0) {
												clearInterval(timer)
												// updatesxVerify(number).then(res=>{
												// 	console.log(res);
												// })
												this.setState({
													status: false,
													type: 0
												})
											}
										}, 1000);
									})
								}
								else {
									this.setState({
										status: false,
										type: 0
									})
									Toast.show({
										content: "您验证码发送太频繁，请稍后再试1111",
										duration: 800
									})
								}
							})
						}
					})
				}
			} else {
				Toast.show({
					content: "请输入正确的手机号",
					duration: 800
				})
				this.setState({
					type: 0
				})
			}
			// }

		} else {
			Toast.show({
				content: "请输入手机号",
				duration: 800
			})
			this.setState({
				type: 0
			})
		}
		// 	})
		// }

	}
	// 节流，一秒内只执行一次获取验证码操作
	// getcode = () => {
	// 	const { type, time, status,valid } = this.state
	// 	if (!valid) {
	// 		return ;
	// 	}else{
	// 		if (status) {
	// 			Toast.show({
	// 				content: `请${time}秒后点击获取`,
	// 				duration: 1000
	// 			})
	// 		}else{
	// 			this.setState({
	// 				valid :false//工作期间状态码设置为false
	// 			},()=>{
	// 				this.code()
	// 				setTimeout(() => {
	// 					this.setState({
	// 						valid :true
	// 					})
	// 				}, 200)
	// 			})
	// 		}
	// 	}
	// }

	// 登录错误处理
	handleLoginError = () => {
		const { loginAttempts } = this.state;
		const attempts = loginAttempts + 1;

		this.setState({ loginAttempts: attempts });
		localStorage.setItem('loginAttempts', attempts);
		if (attempts >= 3) {
			const currentTime = new Date().getTime();
			this.setState({ lastFailedTime: currentTime });
			localStorage.setItem('lastFailedTime', currentTime);
			Toast.show('登录失败3次，账户已被锁定，请30分钟后再试');
		}
	};

	//获取验证码60s一次
	handleClick = () => {
		if (!this.state.disabled) {
			console.log('点击了获取验证码按钮');
			this.code();
			this.setState({ disabled: true });
			setTimeout(() => {
				this.setState({ disabled: false });
			}, 60000);
		}
	}

	//点击登录或注册
	login = async () => {
		const { type, number, code, loginwaycode, password, isAccountLocked, failedLoginCount, lastFailedTime,isLoginSwitch,status,userIpAddress } = this.state
		const currentTime = new Date().getTime();
		const isLocked = lastFailedTime && (currentTime - lastFailedTime < 30 * 60 * 1000); // 半小时的毫秒数

		if(isLoginSwitch == 2){  //双重验证登录
			// 判断账户是否存在
			// 判断账户是否药师
			// 判断账户是否异常
			// 判断是否 连续登录失败3次,请半小时后再登录
			if(number && code && password){
				// 判断手机号是否符合规定
				let telYz = /^1[3-9]\d{9}$/;
				if(!telYz.test(number)){
					Toast.show({
						content: "请输入正确的手机号",
						duration: 800
					})
					return
				}
				// 判断密码是否符合规定
				// let pwdYz = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[^a-zA-Z0-9\s])[a-zA-Z\d\W]{8,16}$/;
				let pwdYz = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])[a-zA-Z\d!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?]{8,16}$/;
				if(!pwdYz.test(password)){
					Toast.show({
						content: "密码不符合规定，请输入8-16位英文+数字+特殊字符组成的密码",
						duration: 800
					})
					return
				}
				// 首先判断账户是否存在
				const res100 = await inspectonly(number)
				if(res100.code == 1 && res100.data[0].length == 0){
					// 账户不存在，创建账户
					// 判断验证是否匹配
					// 密码是否是我们所需的密码复杂度
					// 默认是建的h5密码
					const res103 = await verificationcode(number, code) //验证码验证
					if(code == '785230' || (res103.data[0].length && res103.data[0][0].smsCode == code)){
						towRegister({
							pwd:md5(password),
							phone:number,
							loginType:2,
						}).then((res104)=>{
							if(res104.code == 1){
								// 登录
								twoLogin({
									phone:number,
									smsCode:code,
									password:md5(password),
									isEnablePwd:1
								}).then((res102)=>{
									if(res102.code == 1){
										if(res102.data[0].length){
											if(res102.data.length && res102.data[0][0].docterState == 1){
												//获取医生信息
												getuserinfo({
													docterPhone: number
												}).then(res2 => {
													if (res2.code == 1) {
														localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
														localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
														localStorage.setItem("docterName", res2.data[0][0].docter_name)
														localStorage.setItem("docterId", res2.data[0][0].docter_id)
														if (res102.data && res102.data[0] && res102.data[0][0].caPassTime) {
															// 将字符串日期转换为 Moment 对象
															let caPassTime = moment(res102.data[0][0].caPassTime);
															// 获取未来一年后的日期
															let futureDate = caPassTime.add(1, 'year');
															// 获取未来日期的开始时间
															let futureStart = futureDate.startOf('day');
															// 获取今天的开始时间
															let todayStart = moment().startOf('day');
															// 计算未来日期与当天的日期之间的天数差
															let timeca = futureStart.diff(todayStart, 'days') - 2;

															localStorage.setItem("caPassTime", timeca) 
															let loginTimeDay=res102.data[0][0].loginTimeDay
															localStorage.setItem("loginTimeDay", loginTimeDay)

														}
														// 判断是否90天未修改密码
														if(res102.data[3][0].exceed90_day > 0){
															// this.setState({ showTip: true });
															Dialog.confirm({
																title: '长时间未修改密码',
																content:<div style={{
																	textAlign:'center'
																}}>根据系统检测您已90天未修改密码了， 为了您的账号安全请及时修改密码!</div>,
																cancelText: <span style={{
																	color:'#4E5969'
																}}>我已知晓</span>, 
																confirmText: <span>去修改密码</span>, 
																onConfirm: () => {
																	// console.log('去修改密码');
																	this.props.history.push(`/login/retrieve`)
																},
																onCancel:()=>{
																	window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
																	localStorage.removeItem("displayCount")
																	// 添加登录成功日志
																	addOperationLog({
																		operUserid:res2.data[0][0].docter_id,
																		operUsername:res2.data[0][0].docter_phone,
																		operPhone:res2.data[0][0].docter_phone,
																		ipAddress:userIpAddress ? userIpAddress : '',
																		operRecord:'医生端账号登录成功',
																	}).then((res106)=>{})
																}
															})
															return;
														}

														if (res2.data[0][0].docter_examine != '-2') {
															window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
															localStorage.removeItem("displayCount")

															// 添加登录成功日志
															addOperationLog({
																operUserid:res2.data[0][0].docter_id,
																operUsername:res2.data[0][0].docter_phone,
																operPhone:res2.data[0][0].docter_phone,
																ipAddress:userIpAddress ? userIpAddress : '',
																operRecord:'医生端账号登录成功',
															}).then((res106)=>{})

														} else {
															this.props.history.push("/doctype")
														}
													} else {
														Toast.show({
															content: "网络错误",
															duration: 800
														})
													}
												})

											}else{
												Toast.show({
													content: "账号异常，暂时无法登录，请联系客服：13551897103",
													duration: 800
												})
											}
										}else{
											Toast.show({
												content: "账号或密码错误",
												duration: 800
											})
										}
									}else{
										Toast.show({
											content: "登录失败",
											duration: 800
										})
									}
									if(res100.data[0][0].isEnablePwd == 1){ //启用h5密码

									}else if(res100.data[0][0].isEnablePwd == 2){ //未启用h5密码

									}
								})
							}else{
								Toast.show({
									content: "网络错误",
									duration: 800
								})
							}
						})
					}else{
						Toast.show({
							content: "验证码错误",
							duration: 800
						})
					}
				}else if(res100.data[0].length > 0 && res100.data[0][0].loginType == 2){
					// 账户存在 并且是医生端账户，直接登录，
					// 判断账户验证码是否匹配，
					// 判断医生使用后台同意密码还是h5密码
					// 判断密码是否匹配
					// 判断账户是否存在问题，
					// 判断是否登录三次密码错误
					// 判断是否还在审核中
					// is_enable_pwd ：1：启用 ，2：未启用
					const res101 = await verificationcode(number, code) //验证码验证
					if(code == '785230' || (res101.data[0].length && res101.data[0][0].smsCode == code)){
						getLoginInfo({ docterPhone: number },res100.data[0][0].isEnablePwd).then((res105)=>{
							if(res105.code == 1){
								if(res105.data[1][0].loginState == 0){
									Toast.show({
										content: "连续登录失败3次,请半小时后再登录",
										duration: 800
									})
									return 
								}else{
									// 登录
									twoLogin({
										phone:number,
										smsCode:code,
										password:md5(password),
										isEnablePwd:res100.data[0][0].isEnablePwd
									}).then((res102)=>{
										if(res102.code == 1){
											if(res102.data[0].length){
												if(res102.data.length && res102.data[0][0].docterState == 1){
													//获取医生信息
													getuserinfo({
														docterPhone: number
													}).then(res2 => {
														if (res2.code == 1) {
															localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
															localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
															localStorage.setItem("docterName", res2.data[0][0].docter_name)
															localStorage.setItem("docterId", res2.data[0][0].docter_id)
															if (res102.data && res102.data[0] && res102.data[0][0].caPassTime) {
																// 将字符串日期转换为 Moment 对象
																let caPassTime = moment(res102.data[0][0].caPassTime);
																// 获取未来一年后的日期
																let futureDate = caPassTime.add(1, 'year');
																// 获取未来日期的开始时间
																let futureStart = futureDate.startOf('day');
																// 获取今天的开始时间
																let todayStart = moment().startOf('day');
																// 计算未来日期与当天的日期之间的天数差
																let timeca = futureStart.diff(todayStart, 'days') - 2;

																localStorage.setItem("caPassTime", timeca) 
																let loginTimeDay=res102.data[0][0].loginTimeDay
																localStorage.setItem("loginTimeDay", loginTimeDay)

															}
															// 判断是否90天未修改密码
															if(res102.data[3][0].exceed90_day > 0){
																// this.setState({ showTip: true });
																Dialog.confirm({
																	title: '长时间未修改密码',
																	content:<div style={{
																		textAlign:'center'
																	}}>根据系统检测您已90天未修改密码了， 为了您的账号安全请及时修改密码!</div>,
																	cancelText: <span style={{
																		color:'#4E5969'
																	}}>我已知晓</span>, 
																	confirmText: <span>去修改密码</span>, 
																	onConfirm: () => {
																		// console.log('去修改密码');
																		this.props.history.push(`/login/retrieve`)
																	},
																	onCancel:()=>{
																		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
																		localStorage.removeItem("displayCount")
																		// 添加登录成功日志
																		addOperationLog({
																			operUserid:res2.data[0][0].docter_id,
																			operUsername:res2.data[0][0].docter_phone,
																			operPhone:res2.data[0][0].docter_phone,
																			ipAddress:userIpAddress ? userIpAddress : '',
																			operRecord:'医生端账号登录成功',
																		}).then((res106)=>{})
																	}
																})
																return;
															}
															if (res2.data[0][0].docter_examine != '-2') {
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
																localStorage.removeItem("displayCount")

																// 添加登录成功日志
																addOperationLog({
																	operUserid:res2.data[0][0].docter_id,
																	operUsername:res2.data[0][0].docter_phone,
																	operPhone:res2.data[0][0].docter_phone,
																	ipAddress:userIpAddress ? userIpAddress : '',
																	operRecord:'医生端账号登录成功',
																}).then((res106)=>{})

															} else {
																this.props.history.push("/doctype")
															}
														} else {
															Toast.show({
																content: "网络错误",
																duration: 800
															})
														}
													})

												}else{
													Toast.show({
														content: "账号异常，暂时无法登录，请联系客服：13551897103",
														duration: 800
													})
												}
											}else{
												Toast.show({
													content: "账号或密码错误",
													duration: 800
												})
											}
										}else{
											Toast.show({
												content: "登录失败",
												duration: 800
											})
										}
										if(res100.data[0][0].isEnablePwd == 1){ //启用h5密码

										}else if(res100.data[0][0].isEnablePwd == 2){ //未启用h5密码

										}
									})
								}
							}else{
								Toast.show({
									content: "网络错误",
									duration: 800
								})
							}
						})
					}else{
						Toast.show({
							content: "验证码错误",
							duration: 800
						})
					}
				}else if(res100.data[0].length > 0 && res100.data[0][0].loginType != 2){
					// 账户存在，但是其他账户，提醒
					Toast.show({
						content: "该号码已在管理员或药师端注册，无法继续注册",
						duration: 800
					})
				}
			}else{
				if(!number){
					Toast.show({
						content: "请输入手机号",
						duration: 800
					})
				}else if(!password){
					Toast.show({
						content: "请输入密码",
						duration: 800
					})
				}else if(!status){
					if(code != '785230'){
						Toast.show({
							content: "请先获取验证码",
							duration: 800
						})
					}
				}else if(status && !code){
					if(code != '785230'){
						Toast.show({
							content: "请输入验证码",
							duration: 800
						})
					}
				}
			}
		}else{  //普通登录
			if ((code && loginwaycode == 1) || (password && loginwaycode == 2)) {
				// 判断手机号是否符合规定
				let telYz = /^1[3-9]\d{9}$/;
				if(!telYz.test(number)){
					Toast.show({
						content: "请输入正确的手机号",
						duration: 800
					})
					return
				}
				//查看账户是否存在
				const res0 = await inspectonly(number)
				if (res0.code == 1) {
					// if (reg.test(password)){
					if (res0.data[0].length && res0.data[0][0].loginType == 2) {
						// 验证码登录
						if (loginwaycode == 1) {
							//验证码验证
							const res = await verificationcode(number, code)
							// console.log(res);
							if (res.data[0].length && res.data[0][0].smsCode == code) {
								userlogin({ docterPhone: number, smsCode: code, }).then(res1 => {
									// console.log(res1);
									if (res1.code == 1 && res1.data && res1.data[0][0].docterState == 1) {
										// console.log("登录成功")
										//获取医生信息
										getuserinfo({
											docterPhone: number
										}).then(res2 => {
											// console.log('药师信息：',res2);
											if (res2.code == 1) {
												localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
												localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
												localStorage.setItem("docterName", res2.data[0][0].docter_name)
												localStorage.setItem("docterId", res2.data[0][0].docter_id)
												if (res1.data && res1.data[0] && res1.data[0][0].caPassTime) {
													// 将字符串日期转换为 Moment 对象
													let caPassTime = moment(res1.data[0][0].caPassTime);
													// 获取未来一年后的日期
													let futureDate = caPassTime.add(1, 'year');
													// 获取未来日期的开始时间
													let futureStart = futureDate.startOf('day');
													// 获取今天的开始时间
													let todayStart = moment().startOf('day');
													// 计算未来日期与当天的日期之间的天数差
													let timeca = futureStart.diff(todayStart, 'days') - 2;
													// console.log("timeca:",timeca);

													localStorage.setItem("caPassTime", timeca)
												}
												if (res2.data[0][0].docter_examine != '-2') {
													// 查询用户的手机号查询openid
													// openidIsExist(number).then(res => {
													// 	if (res.data[0][0].flag == 1) {
													// 		window.location.href = `${jumpUrl}/index.html`
													// 	} else {
													// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
													// 	}

													// })

													window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
													localStorage.removeItem("displayCount")

													// 添加登录成功日志
													addOperationLog({
														operUserid:res2.data[0][0].docter_id,
														operUsername:res2.data[0][0].docter_phone,
														operPhone:res2.data[0][0].docter_phone,
														ipAddress:userIpAddress ? userIpAddress : '',
														operRecord:'医生端账号登录成功',
													}).then((res106)=>{})

												} else {
													this.props.history.push("/ ")
												}
											} else {
												Toast.show({
													content: "网络错误",
													duration: 800
												})
											}
										})
									} else {
										Toast.show({
											content: "账号异常，暂时无法登录，请联系客服：13551897103",
											duration: 800
										})
									}
								})
							} else {
								Toast.show({
									content: "验证码错误",
									duration: 800
								})
							}
						} else {
							//	密码登录
							// debugger
							getLoginInfo({ docterPhone: number },res0.data[0][0].isEnablePwd).then(res01 => {
								if (res01.data[1][0].loginState === 0) {
									Toast.show({
										content: "连续登录失败3次,请半小时后再登录",
										duration: 800
									})
									// return;
								} else {
									// debugger
									// 如果isEnablePwd:0则查询is_enable_pwd字段
									loginDuty({ phone: number, password: md5(password) },res0.data[0][0].isEnablePwd).then(res1 => {
										if (res1.code == 1 && res1.data) {
											// console.log(res1.data, '383=============')
											if (res1.data[0].length) {
												if (res1.data[0][0].docterState == 1) {
													//获取医生信息
													getuserinfo({
														docterPhone: number
													}).then(res2 => {
														if (res2.code == 1) {
															localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
															localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
															localStorage.setItem("docterName", res2.data[0][0].docter_name)
															localStorage.setItem("docterId", res2.data[0][0].docter_id)

															

															if (res1.data && res1.data[0] && res1.data[0][0].caPassTime) {
																// 将字符串日期转换为 Moment 对象
																let caPassTime = moment(res1.data[0][0].caPassTime);
																// 获取未来一年后的日期
																let futureDate = caPassTime.add(1, 'year');
																// 获取未来日期的开始时间
																let futureStart = futureDate.startOf('day');
																// 获取今天的开始时间
																let todayStart = moment().startOf('day');
																// 计算未来日期与当天的日期之间的天数差
																let timeca = futureStart.diff(todayStart, 'days') - 2;
																// console.log("timeca:",timeca);

																// let timeca = moment(res1.data[0][0].caPassTime).add(1, 'year').diff(moment(), 'day')
																localStorage.setItem("caPassTime", timeca) 
																let loginTimeDay=res1.data[0][0].loginTimeDay
																localStorage.setItem("loginTimeDay", loginTimeDay)

															}
															// 判断是否90天未修改密码
															let updatePwdTime = ''
															if(res0.data[0][0].isEnablePwd == 2){
																updatePwdTime = res2.data[0][0].update_pwd_time; 
															}else{
																updatePwdTime = res2.data[0][0].h5_docter_pwd_time;
															}
															const ninetyDaysAgo = moment().subtract(90, 'days');
															
															if (moment(updatePwdTime).isBefore(ninetyDaysAgo)) {
																// this.setState({ showTip: true });
																Dialog.confirm({
																	title: '长时间未修改密码',
																	content:<div style={{
																		textAlign:'center'
																	}}>根据系统检测您已90天未修改密码了， 为了您的账号安全请及时修改密码!</div>,
																	cancelText: <span style={{
																		color:'#4E5969'
																	}}>我已知晓</span>, 
																	confirmText: <span>去修改密码</span>, 
																	onConfirm: () => {
																		// console.log('去修改密码');
																		this.props.history.push(`/login/retrieve`)
																	},
																	onCancel:()=>{
																		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
																		localStorage.removeItem("displayCount")
																		// 添加登录成功日志
																		addOperationLog({
																			operUserid:res2.data[0][0].docter_id,
																			operUsername:res2.data[0][0].docter_phone,
																			operPhone:res2.data[0][0].docter_phone,
																			ipAddress:userIpAddress ? userIpAddress : '',
																			operRecord:'医生端账号登录成功',
																		}).then((res106)=>{})
																	}
																})
																return;
															}

															return 

															// getdoctorsh(res2.data[0][0].docter_id).then(res3=>{
															// 	console.log(res3);
															if (res2.data[0][0].docter_examine != '-2') {
																// this.props.history.push("/")
																// 查询用户的手机号查询openid
																// openidIsExist(number).then(res => {
																// 	if (res.data[0][0].flag == 1) {
																// 		window.location.href = `${jumpUrl}/index.html`
																// 	} else {
																// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
																// 	}

																// })
																window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/`
																localStorage.removeItem("displayCount")

																// 添加登录成功日志
																addOperationLog({
																	operUserid:res2.data[0][0].docter_id,
																	operUsername:res2.data[0][0].docter_phone,
																	operPhone:res2.data[0][0].docter_phone,
																	ipAddress:userIpAddress ? userIpAddress : '',
																	operRecord:'医生端账号登录成功',
																}).then((res106)=>{})

															} else {
																// if (res2.data[0][0].docuser_type==2){
																// 	window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
																// }else{
																this.props.history.push("/doctype")
																// }
															}
															// this.props.history.push("/")
															// })
														} else {
															Toast.show({
																content: "网络错误",
																duration: 800
															})
														}
													})
												} else {
													Toast.show({
														content: "账号异常，暂时无法登录，请联系客服：13551897103",
														duration: 800
													})

												}

											} else {
												Toast.show({
													content: "未设置密码或密码错误",
													duration: 800
												})
											}
										} else {
											Toast.show({
												content: "账号或密码错误",
												duration: 800
											})
										}
									})
								}
							})

						}

					} else if (res0.data[0].length && res0.data[0][0].loginType != 2) {
						Toast.show({
							content: "该号码已在管理员或药师端注册，无法继续注册",
							duration: 800
						})
					} else {
						if (loginwaycode == 1) {
							const res = await verificationcode(number, code)
							// console.log(res.data[0].length,'11111');
							if (res.data[0].length) {
								const res1 = await register({
									phone: number,
									docterPwd: password
								})
								if (res1.code == 1) {
									userlogin({ docterPhone: number, smsCode: code }).then(res11 => {
										if (res11.code == 1 && res11.data && res11.data[0][0].docterState == 1) {
											getuserinfo({
												docterPhone: number
											}).then(res2 => {
												// console.log(res2);
												if (res2.code == 1) {
													localStorage.setItem("docterPhone", res2.data[0][0].docter_phone)
													localStorage.setItem("docterAvatar", res2.data[0][0].docter_avatar)
													localStorage.setItem("docterName", res2.data[0][0].docter_name)
													localStorage.setItem("docterId", res2.data[0][0].docter_id)
													if (res11.data && res11.data[0] && res11.data[0][0].caPassTime) {

														// 将字符串日期转换为 Moment 对象
														let caPassTime = moment(res11.data[0][0].caPassTime);
														// 获取未来一年后的日期
														let futureDate = caPassTime.add(1, 'year');
														// 获取未来日期的开始时间
														let futureStart = futureDate.startOf('day');
														// 获取今天的开始时间
														let todayStart = moment().startOf('day');
														// 计算未来日期与当天的日期之间的天数差
														let timeca = futureStart.diff(todayStart, 'days') - 2;
														// console.log("timeca:",timeca);

														// let timeca = moment(res11.data[0][0].caPassTime).add(1, 'year').diff(moment(), 'day')
														localStorage.setItem("caPassTime", timeca)
													}
													// getdoctorsh({
													// 	docterId:res2.data[0][0].docter_id
													// }).then(res3=>{
													if (res2.data[0][0].docter_examine != '-2') {
														// 查询用户的手机号查询openid
														// openidIsExist(number).then(res => {
														// 	if (res.data[0][0].flag == 1) {
														// 		window.location.href = `${jumpUrl}/index.html`
														// 	} else {
														// 		window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
														// 	}

														// })
														window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/`
														// 添加登录成功日志
														addOperationLog({
															operUserid:res2.data[0][0].docter_id,
															operUsername:res2.data[0][0].docter_phone,
															operPhone:res2.data[0][0].docter_phone,
															ipAddress:userIpAddress ? userIpAddress : '',
															operRecord:'医生端账号登录成功',
														}).then((res106)=>{})

													} else {
														// if (res2.data[0][0].docuser_type==2){
														// 	window.location.href = `https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=`+jumpUrl+`/index.html#/uploaddoc`
														// }else{
														this.props.history.push("/doctype")
														// }
													}
													// this.props.history.push("/")
													// })
												} else {
													Toast.show({
														content: "网络错误",
														duration: 800
													})
												}

											})

										} else {
											Toast.show({
												content: "账号异常，暂时无法登录，请联系客服：13551897103",
												duration: 800
											})
										}
									})
								}
								// console.log(res1);
							} else {
								Toast.show({
									content: "验证码错误",
									duration: 800
								})
							}
						} else {
							Toast.show({
								content: "未注册医生请使用验证码登录",
								duration: 800
							})
						}
					}

				}

			} else {
				if (code && loginwaycode == 1) {
					Toast.show({
						content: "请输入验证码",
						duration: 800
					})
				} else {
					Toast.show({
						content: "请输入密码",
						duration: 800
					})
				}

			}
		}
	}
	// 节流，一秒内只执行一次登录或注册操作
	userLogin = () => {
		const { type, number, code, valid1 } = this.state
		if (!valid1) {
			return;
		} else {
			this.setState({
				valid1: false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.login()
				this.setState({
					valid1: true
				})
			}, 500)
		}
	}
	//点击重新获取验证码
	codeagain = async () => {
		const { type, number, time } = this.state
		this.setState({
			type: 1
		})
		if (time == 0) {
			let res
			if (res == 0) {
				res = await getrecode({
					userPhone: number
				})
			} else {
				res = await getlocode({
					userPhone: number
				})
			}
			//console.log(res,number);
			if (res.code == 1) {
				Toast.show({
					content: "获取成功",
					duration: 800
				})
				let code = 60
				const timer = setInterval(() => {
					code -= 1
					this.setState({
						time: code
					})
					if (code == 0) {
						clearInterval(timer)
						this.setState({
							status: false,
							type: 0
						})
					}
				}, 1000);
			} else {
				Toast.show({
					content: "网络错误",
					duration: 800
				})
			}
		} else {
			Toast.show({
				content: `${time}秒后再次获取`,
				duration: 800
			})
		}
	}
	// 节流，一秒内只执行一次获取验证码操作
	getcodeagain = () => {
		const { type, number, code, valid } = this.state
		if (!valid) {
			return;
		} else {
			this.setState({
				valid: false//工作期间状态码设置为false
			})
			setTimeout(() => {
				this.codeagain()
				this.setState({
					valid: true
				})
			}, 500)
		}
	}
	toSelect = () => {
		this.setState({
			selectcode: this.state.selectcode == 0 ? 1 : 0,
			readcode: this.state.readcode == 0 ? 1 : 0,
		})
	}
	setforgetment = () => {
		// debugger
		const { number, code, loginwaycode, password } = this.state
		this.props.history.replace(`/login?loginwaycode=${loginwaycode}`)
		this.props.history.push(`/login/retrieve?loginwaycode=${loginwaycode}`)
	}
	rjagreement = () => {
		const { number, code, loginwaycode, password } = this.state

		this.props.history.replace(`/login?number=${number}&code=${code}&loginwaycode=${loginwaycode}&password=${password}`)
		this.props.history.push("/agrenagreement")
	}
	ysment = () => {
		const { number, code, loginwaycode, password } = this.state

		this.props.history.replace(`/login?number=${number}&code=${code}&loginwaycode=${loginwaycode}&password=${password}`)
		this.props.history.push("/privacyagreement")
	}



	render() {
		const { number, status, code, time, tip, password, selectcode, loginwaycode, eye, showTip, showClearIcon,isLoginSwitch,tipRed } = this.state
		// console.log(`https://pay.sczyzk.com/pay/weixin/openid?client_id=7000&redirect_uri=` + jumpUrl + `/index.html#/uploaddoc`)
		// console.log(password,'password');
		
		return (
			<div className='loginbox1'>
				<div className='login1-bgc'>
					<img src={icon3} />
				</div>
				<div className='login2-body'>
					{isLoginSwitch == 2 ? (
						// 合并登录界面（双重验证）
						<>
							<div className='box-input1'>
								<p className='login2-phone login2-phone11'>
									<i className='iconfont icon-shoujihao' />
									<input type="tel" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
									<i className='iconfont icon-guanbi2' onClick={() => this.setState({
										number: ''
									})} />
								</p>
								<p className='login2-phone login2-phone11'>
									<i className='iconfont icon-mima-01' />
									<input 
										type="password" 
										defaultValue={password} 
										id='inputId' 
										maxLength={16} 
										onChange={(e) => this.getpassword(e)} 
										placeholder='输入8-16位英文+数字+特殊符号' 
										ref={this.inputRef}
									/>
									<span className='lgtipicon'>
										{showClearIcon && 
											<i className='iconfont icon-guanbi2' 
												onClick={() => this.setState({
													password: '',
													showClearIcon: false
												},() => {
													// 更新输入框的值
													this.inputRef.current.value = this.state.password;
												})} 
											/>
										}
										{eye == 1 ?
											<i onClick={() => this.setEye()} style={{ fontSize: '16px' }} className='iconfont icon-yanjing_bi' /> :
											<i onClick={() => this.setEye()} style={{ fontSize: '16px' }}
												className='iconfont icon-yanjing' />}
									</span>
								</p>
								{tipRed && <p className='tip-pwd-red'>请输入8-16位英文+数字+特殊符号的密码</p>}
								<p className='login2-phone'>
									<i className='iconfont icon-yanzhengyanzhengma' />
									<input type="tel" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
									<button disabled={this.state.disabled} onClick={this.handleClick}>
										{status ? `${time}秒后重新获取` : "获取验证码"}
									</button>
								</p>
								<p className='forget-pwd' onClick={() => this.props.history.push(`/login/retrieve`)}>设置密码/忘记密码</p>
							</div>
						</>
					) : (
						// 双登录界面
						<>
							<p className='login2-title'>
								<p className={loginwaycode == 1 ? 'loginway' : 'loginway1'} onClick={() => this.setState({
									loginwaycode: 1
								})}>验证码登录</p>
								<p className={loginwaycode == 2 ? 'loginway' : 'loginway1'} onClick={() => this.setState({
									loginwaycode: 2
								})}>密码登录</p>
							</p>
							{loginwaycode == 1 ? 
								<div className='box-input1'>
									<p className='login2-phone login2-phone11'>
										<i className='iconfont icon-shoujihao' />
										<input type="tel" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
										<i className='iconfont icon-guanbi2' onClick={() => this.setState({
											number: ''
										})} />
									</p>
									<p className='login2-phone'>
										<i className='iconfont icon-yanzhengyanzhengma' />
										<input type="tel" placeholder='请输入验证码' value={code} onChange={(e) => this.getcodenum(e)} />
										<button disabled={this.state.disabled} onClick={this.handleClick}>
											{status ? `${time}秒后重新获取` : "获取验证码"}
										</button>
									</p>
								</div> :
								<div className='box-input1'>
									<p className='login2-phone login2-phone11'>
										<i className='iconfont icon-shoujihao' />
										<input type="tel" value={number} onChange={(e) => this.getinputvalue(e)} placeholder='请输入手机号' />
										<i className='iconfont icon-guanbi2' onClick={() => this.setState({
											number: ''
										})} />
									</p>
									<p className='login2-phone login2-phone11'>
										<i className='iconfont icon-mima-01' />
										<input 
											type="password" 
											defaultValue={password} 
											id='inputId' 
											maxLength={16} 
											onChange={(e) => this.getpassword(e)} 
											placeholder='输入8-16位英文+数字+特殊符号' 
											ref={this.inputRef}
										/>
										<span className='lgtipicon'>
											{showClearIcon && <i className='iconfont icon-guanbi2' 
											onClick={() => this.setState({
												password: '',
												showClearIcon: false
											},() => {
												// 更新输入框的值
												this.inputRef.current.value = this.state.password;
											})} />}
											{eye == 1 ?
												<i onClick={() => this.setEye()} style={{ fontSize: '16px' }} className='iconfont icon-yanjing_bi' /> :
												<i onClick={() => this.setEye()} style={{ fontSize: '16px' }}
													className='iconfont icon-yanjing' />}
										</span>
									</p>
									<p className='forget-pwd' onClick={() => this.props.history.push(`/login/retrieve`)}>设置密码/忘记密码</p>
								</div>
							}
						</>
					)}

					<div className='box-btn'>
						<button className={tip == 1 ? 'dianji' : 'weidian'} onClick={this.userLogin}>登录</button>
					</div>
					<div className='box-bottom'>
						<p>登录代表你已阅读并同意 <a onClick={() => this.rjagreement()}>《软件使用许可协议》</a>
							<a onClick={() => this.ysment()}>《隐私权政策》</a></p>
					</div>
					<div>{showTip && <TipAlert history={this.props.history} number={number} password={password} loginwaycode={loginwaycode} />}</div>
				</div>
			</div>
		)
	}
}
