import React from 'react'

import './style.less'
import Select from '../../component/Select/index'
import Shopmedlist from '../medicine/meditem'

import IMG from '../../static/img/store/activity.png'
import Logo from '../../static/img/store/2.png'

export default function ShopMed(props) {
    const backPage=()=>{
        window.history.back()
    }

    return(
        <div style={{background:'#FFFFFF'}}>
            <div className='shop-content'>
                <div className='shop-header'>
                    <div className='shop-header-zhanwei flex-row' onClick={backPage}>
                        <i style={{color:'#666666'}} className='iconfont icon-xiangzuo'></i>
                    </div>
                    <div className='shop-search shopmed-input flexr'
                    style={{border: '1px solid #414141'}}>
                        <i style={{color:'#666666'}} className='iconfont icon-suosou'></i>
                        <input type='text' placeholder='999感冒灵'/>
                    </div>
                    <div className='shop-header-zhanwei'></div>
                </div>
                <div className='shop-introduce'>
                    <div className='shop-brank flexr'>
                        <div className='shop-logo' style={{backgroundImage:`url(${Logo})`}}></div>
                        <div style={{color:'#666666'}} className='shop-title flexc'>
                            <p>老百姓大药房</p>
                            <span>10.4w人关注</span>
                        </div>
                        <div className='into-shop'><span>进入店铺</span><i className='iconfont icon-xiangyou'></i></div>
                    </div>
                </div>
            </div>
            <div className='shop-activity flex-row'>
                <img src={IMG} alt=''/>
            </div>
            <Select></Select>
            <Shopmedlist></Shopmedlist>
        </div>

    )
}