import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../../static/css/iconfont.css";
import icon1 from "../../static/img/icon/user.png";
import "./style.less";

// 医生开方病史信息
export default function Historyinfo(props) {
  const { 
    news,
    setvalue
   } = props;
  
  let item = news;
  // console.log(news,'疾病史');

  return (
    <div className="history-box">
      {/* 有无疾病史 */}
      <div className="medical-history-box">
        <p className="left-tit-chose">
          <p className='left-tit'>有无疾病史？</p>
          <p className="chose-btn">
            <span
              className="btn-have"
              className={item.past_history == 1 ? "btn-have" : "btn-no"}
              onClick={(e) => {setvalue("疾病史有",e)}}
            >
              有
            </span>
            <span
              className={item.past_history == 0 ? "btn-have" : "btn-no"}
              onClick={(e) => {setvalue("疾病史无",e)}}
            >
              无
            </span>
          </p>
        </p>
        {item.past_history == 1 ? (
          <p className="medical-history-textarea">
            <textarea
              placeholder="请输入疾病史"
              maxLength={100}
              defaultValue={item.past_history_note}
              onChange={(e) => setvalue("疾病史内容",e)}
            ></textarea>
          </p>
        ) : null}
      </div>
      {item.past_history == 0 ? <div className="diver-line"></div> : null}

      {/* 有无过敏史 */}
      <div className="medical-history-box">
        <p className="left-tit-chose">
          <p className='left-tit'>有无过敏史？</p>
          <p className="chose-btn">
            <span
              className="btn-have"
              className={item.allergic_history == 1 ? "btn-have" : "btn-no"}
              onClick={(e) => {setvalue("过敏史有",e)}}
            >
              有
            </span>
            <span
              className={item.allergic_history == 0 ? "btn-have" : "btn-no"}
              onClick={(e) => {setvalue("过敏史无",e)}}
            >
              无
            </span>
          </p>
        </p>
        {item.allergic_history == 1 ? (
          <p className="medical-history-textarea">
            <textarea
              placeholder="请输入过敏史"
              maxLength={100}
              onChange={(e) => setvalue("过敏史内容",e)}
              defaultValue={item.allergic_history_note}
            ></textarea>
          </p>
        ) : null}
      </div>
      {item.allergic_history == 0 ? <div className="diver-line"></div> : null}

      {/* 有无肝肾功能异常 */}
      <div className="medical-history-box">
        <p className="left-tit-chose">
          <p className='left-tit'>有无肝肾功能异常？</p>
          <p className="chose-btn">
            <span
              className="btn-have"
              className={item.abnormal_liver_function == 1 ? "btn-have" : "btn-no"}
              onClick={(e) => setvalue("肝肾功能有异常",e)}
            >
              有
            </span>
            <span
              className={item.abnormal_liver_function == 0 ? "btn-have" : "btn-no"}
              onClick={(e) => setvalue("肝肾功能无异常",e)}
            >
              无
            </span>
          </p>
        </p>
      </div>
      <div className="diver-line"></div>

      {/* 有无体温异常 */}
      <div className="medical-history-box">
        <p className="left-tit-chose">
          <p className='left-tit'>有无体温异常？</p>
          <p className="chose-btn">
            <span
              className="btn-have"
              className={item.bodytemperature == 1 ? "btn-have" : "btn-no"}
              onClick={(e) => setvalue("体温有异常",e)}
            >
              有
            </span>
            <span
              className={item.bodytemperature == 0 ? "btn-have" : "btn-no"}
              onClick={(e) => setvalue("体温无异常",e)}
            >
              无
            </span>
          </p>
        </p>
        {item.bodytemperature == 1 ? (
          <p className="medical-history-temperature">
            <input
              type="text"
              placeholder="请输入体温"
              maxLength='4'
              defaultValue={item.bodytemperaturenum}
              style={{
                border:'none',
                background: '#EEEEEE',
                width:'100%',
                padding:'12px',
                borderRadius: '8px',
                fontSize:'14px'
              }}
              onChange={(e) => setvalue("体温内容",e)}
            />
            <span className="danwei-temperature">℃</span>
          </p>
        ) : null}
      </div>

    </div>
  );
}
