import React, { useState } from 'react'
import { withRouter } from 'react-router-dom'

import { likeRequest } from '../../api/request/health'

import './wechatitem.less'
import '../../static/css/iconfont.css'
import IMG from'../../static/img/head.png'

function WechatList(props) {
    const [postList, setPostList] = useState(props.postList)
    const userId = localStorage.userId

    const postTop = (postId, index) => {
        let postListTop = postList
        likeRequest(postId).then(res => {
            if(res.code === 1) {
                postListTop[index].postTop = postListTop[index].postTop + 1
            }
            setPostList(postListTop)
        })
    }

    return(
        <div className='wechat-list flexc'>
            {postList.map((v,index)=>{
                return(
                    <div key={index}>
                        <div className='wechat-card flexc'>
                            <div className='wechat-card-detail flexc'>
                                <div className='wechat-name'>
                                    <img className='wechat-head' src={IMG} alt=''/>
                                    <div className='wechat-person'>
                                        <p>{v.userName?v.userName:v.userAcount}</p>
                                        <span>{v.createTime}</span>
                                    </div>
                                    {parseInt(userId)===v.userId?null:
                                    <div className='wechat-follow'>关注</div>}
                                </div>
                                <div className='wechat-brief'>{v.postContent}</div>
                                {v.keywordList?
                                    <div className='wechat-tag'>
                                        {v.keywordList.map((i,index)=>{
                                            return(
                                                <span key={index}>#{i}#&nbsp;</span>
                                            )
                                        })}
                                    </div>
                                : v.topicId===0 ? 
                                    <div className='wechat-tag'>
                                        <span key={index}>#健康圈友聚集地#&nbsp;</span>
                                    </div> : null}
                                {v.postPicList?
                                    <div className='wechat-img flex-row'>
                                        {v.postPicList.map((i,index)=>{
                                            return(
                                                <img src={i} key={index} alt=''/>
                                            )
                                        })}
                                    </div>
                                :null}
                            </div>
                            <div className='wechatlist-line'></div>
                            <div className='wechat-icon flex-apart-row'>
                                <i className='iconfont icon-pinglun'>&nbsp;{0}</i>
                                <i className='iconfont icon-dianzan'
                                onClick={()=>postTop(v.postId, index)}>&nbsp;{v.postTop}</i>
                            </div>
                        </div>
                        <div className='wechatlist-line' style={{height:6}}></div>              
                    </div>
                )
            })}
        </div>
    )
}


export default withRouter(WechatList)