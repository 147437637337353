import React from 'react'
import {withRouter} from "react-router-dom"

import Head from '../../../component/Head'

import './patientinfo.less'

function PatientInfo(props) {
    return (
        <>
            <Head title='患者信息' ability='管理'></Head>
            <div className='screen'>
                <div className='patientinfo-card'>
                    <div>
                        <span className='patient-name'>李煜</span>
                        <span>13884525962</span>
                    </div>
                    <div>
                        <i className='iconfont icon-wancheng-copy'></i>
                    </div>
                    <span>男丨26岁丨55kg</span>
                </div>
                <div className='patientinfo-card'>
                    <div>
                        <span className='patient-name'>李煜</span>
                        <span>13884525962</span>
                    </div>
                    <div>
                        <div className='quanquan'></div>
                    </div>
                    <span>男丨26岁丨55kg</span>
                </div>
                <div className='patientinfo-add flex-row'>
                    <i className='iconfont-l icon-tianjia'></i>
                    <span>添加患者</span>
                </div>
            </div>
            <div className='bottom-button' onClick={()=>props.history.push('/inquiry/list/imagetext')}>下一步</div>
        </>
    )
}

export default withRouter(PatientInfo)