import React from 'react'

import './style.less'

export default function StepButton(props) {
    const { name }=props
    const { onclick } = props
    
    return(
        <div className='stepbutton-row'>

            {props.children}
            <button className='step-maincolor-button'
            onClick={onclick}>{name}</button>
            
        </div>
    )
}