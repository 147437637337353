import React, { Component } from "react";
import "./style.less";
import { Toast, ImagePicker, Modal } from "antd-mobile";
import {
  Divider,
  Dialog,
  ImageViewer,
  Popup,
  SpinLoading,
  Radio,Space
} from "antd-mobile-v5";
import icon1 from "../../static/img/chat/add.png";
import icon2 from "../../static/img/chat/doc-img.png";
import icon3 from "../../static/img/icon/search.png";
import icon4 from "../../static/img/chat/wznews.png";
import icon5 from "../../static/img/chat/user-img.png";
import icon6 from "../../static/img/icon/photo.png";
import icon7 from "../../static/img/icon/lczd.png";
import icon8 from "../../static/img/icon/meds.png";
import icon9 from "../../static/img/icon/end.png";
import icon10 from "../../static/img/icon/wrong.png";
import icon11 from "../../static/img/icon/del.png";
import icon12 from "../../static/img/chat/hascf.png";
import icon13 from "../../static/img/yy/zj-zhang.png";
import icon14 from "../../static/img/yy/pt.png";
import xiala from "../../static/img/xiala.png";
import gou from "../../static/img/gou.png";
import "../../static/fonts/font_3834206_3hf2budjfc2/iconfont.css";
// import vConsole from '../../../src/api/vconsole'
import {
  getonenews,
  getstatus,
  changestatus,
  refusestatus,
  addnews,
  docAvater,
  prescribingDetail,
  updateDoctype,
  getStatus,
  refundApply,
  getIcdCode,
  saveIcd,
  allIcd,
  getnewslist,
  allMedicinal,
  caWrite,
  addPrescribing,
  updateOrdernumber,
  isChineseMeds,
  selectExcessNumber,
  chineseMethod,
  belongStore,
  selectPharmaIdStaff,
  selectPharmaIdPha,
  selectSignaturePic,
  chineseMethodDoc,
  updateOrderinfo,
  isSumbitPrescribing,
  selectConState,
  selectPrescPhaSign,
  updateChineseDocSubmit,
  chineseMedsTaboo,
  updatePatientHistoryInfo
} from "../../api/hospitalapi/news";
import { zipImg } from "../../component/zipImage";
import {
  uploadpic,
  getToken,
  addToken,
  tokenSupervision,
  uploadFurtherConsult,
  videoSetting,
  videoRecording,//开始视频录制
  endVedioRecording,//结束视频录制
  updateConsultation,
  caAuthentication,
  caPasstime
} from "../../api/hospitalapi/upload";
import {
  allmedsNews,
  sureReceive,
  docLine,
  allOrders,
  prescribingPrice,
  allIllsnews,
  docUpdate,
  getSamemeds,
  prescriptionSignature,
  prescriptionSignatureCha,
  isCapwd,
  savevideoTime,
  addvideoMessage,
  nowtimeDoc,
  submitPrescribing,
  isSquareroot,
  noSquare,
  selectBecomeDue,
  selectFiltering,
  selectIsPhaReject,
  docterDetail,
  updatePrice,
  recordUsage,
  dialogueTemplate
} from "../../api/hospitalapi/offline";
import { callWenzhen } from "../../api/hospitalapi/videowz";
import {
  getBase64,
  supervise_data,
  getBase64Image,
  dealParams,
} from "../../api/config";
import { parseAsync } from "@babel/core";
import moment from "moment";
import { axiosInstance } from "../../api/config";
import { logDOM } from "@testing-library/react";
import "../../static/fonts/font_3834206_gu10pbxiec/iconfont.css";
import VideoForReact from "trtc-simple-react";
import { postSupervise } from "../../api/config3";
import Nodata from "../../component/Nodata";
import getUrl from "../../component/GetUrlParam";
import Patientnews from "../../component/offline";
import Icdlistsnews from "../../component/offline/icdlists";
import Offsomegoods from "../../component/offline/Offsomegoods";
import Historyinfo from "../../component/offline/historyinfo";
import { getuserinfo } from "../../api/hospitalapi/login";
import { baseUrl, caUrl, jumpUrl } from "../../publicurl";
import res from "antd-mobile-icons/es/AaOutline";

const prompt = Modal.prompt;
const alert = Modal.alert;
export default class index extends Component {
  constructor() {
    super();
    this.state = {
      bottomcode: false,
      bottomcode1: 1,
      visible: false,
      overcode: false,
      visible2: false,
      visible3: false,
      visible4: false,
      visible5: false,
      visible6: false,
      visible7: false,
      visible8: false,
      visible9: false,
      visible10: false,
      visible11: false, //视频显示患者信息
      visible12: false,
      visible00: false,
      visible01: false,
      visible02: false,
      visible04: false,
      visible07: false,
      icdList: [],
      list: [],
      list3: [],
      list1: [],
      list2: [],
      item: JSON.parse(localStorage.getItem("item")),
      reason: "",
      docterId: localStorage.getItem("docterId"),
      // docterName:localStorage.getItem("docterName"),
      consultationId: "2",
      userId: "1",
      userpic: "http://images.yyj-health.com/202202/1644215398065.gif",
      detailContent: "",
      detailContent0: "",
      fileList: [],
      patientName: "",
      patientSex: "",
      patientBirthday: "",
      consultationDetail: "",
      timer: 0,
      docterAvatar: "",
      memberId: "",
      details: [],
      b: [],
      clickFd: 0,
      index1: -1,
      index01: 0,
      chatLength: 0,
      tip: false,
      patientDetails: "",
      consultationFiles: "",
      image: {
        url: "",
        visible: false,
      },
      page: 1,
      size: 20,
      page1: 1,
      size1: 20,
      icdone: "",
      icdlists: [],
      token: "",
      formData: [],
      upload: [],
      medsNews: [],
      originalPrescribing: "",
      docterAutograph: "",
      docterAutograph1: "",
      detail: "",
      useTime: "0小时0分钟",
      pharmacistPhone: "",
      video: null,
      doctorData: {
        sdkAppId: "",
        userSig: "",
      },
      videocode: 1, //视频最小，默认1,2 视频最大化
      doctortext: {},
      docterPhone: localStorage.getItem("docterPhone"),
      docterProposal: localStorage.getItem("docterProposal")
        ? localStorage.getItem("docterProposal")
        : "",
      docterProposal1:
        "请严格按原处方和药品说明书使用，严禁超量超范围使用；如用药过程中出现病情变化或其它不适症状，请立即停药并及时就医。",
      recipeType: localStorage.getItem("recipeType")
        ? localStorage.getItem("recipeType")
        : 2,
      icdhave: 0,
      icdValue: localStorage.getItem("icdValue"),
      visible1: false,
      day: ["每天", "隔日", "每周", "每月"],
      danwei: [
        "mg",
        "g",
        "ml",
        "片",
        "丸",
        "粒",
        "袋",
        "枚",
        "喷",
        "支",
        "ug",
        "滴",
        "帖",
        "瓶",
        "张",
        "剂",
      ],
      count: ["1次", "2次", "3次", "4次", "5次", "6次"],
      useway: [
        "口服",
        "舌下含化",
        "吸入",
        "外敷",
        "直肠给药",
        "皮下注射",
        "肌肉注射",
        "静脉注射",
        "外用",
        "涂抹",
        "雾化",
        "灌肠",
        "滴眼",
      ],
      day0: "",
      danwei0: "",
      count0: "",
      medId: "",
      i: 0,
      j: 0,
      k: 0,
      m: 0,
      supervisionType: "",
      ills: [
        { name: "流行性感冒", state: 1 },
        { name: "季节性感冒", state: 1 },
        { name: "病毒性感冒", state: 1 },
      ],
      subcode: 1,
      subcode2: 0,
      priceArray: 0,
      updateId: "",
      knowcode: false,
      ca_id: 0,
      password: "",
      passwordcode: 1,
      videoTime: "",
      videofig: 0,
      qxcode: 1,
      duration: 0, //视频时间 秒
      retirement: 0,
      codestate: 1, //2有驳回问诊单
      ischinese:[],//是否是中药问诊单
      chinesemethod:[],//用法用量
      staff_img:'',
      pharmacist_img:'',
      becomeduestate:'',
      pharmacist_img1:'',
      filteringArr:[],
      visible13:false,
      isPhaExamine: false, //是否需要药店药师审核
      isPhaBohui: false,//是否药店药师驳回
      visibledocter2:false,
      filingExpirationDate:'',//执业证书备案时间
      FilingDaysTime:'',//执业药师到期天数
      caPassTime:localStorage.getItem("caPassTime") ? localStorage.getItem("caPassTime") : '',
      somegoods:[],
      chamedsmethod:[],//中药用药方法
      pickerVisible: false,
      meidayss:["一日",'两日','三日','四日','五日','六日','七日'],
      unit: ['mg', 'g', 'ml', '片', '丸', '粒', '袋', '枚', '喷', '支', 'ug', '滴', '帖', '瓶', '张', '剂', 'IU'],//药品单位
      visibleunit:false,//自填用药单位弹窗
      unitss:'',
      tipcode:0,//1表示点了确定，0表示未点

      chinesemethodtwo:[],//中药用药方法
      vedioTaskId:'',//视频录制任务开始id

      visibleExcess:false,//超量弹窗
      excessArr:[],//超量JSON数组
      docPriceArr: 0,//医生点击开方按钮时医生对应的处方价格
      visibleCancel: false,//取消本单弹窗
      // cancelReason: "",//取消本单原因
      isSumbit:false,//是否提交处方

      somegoodssystem:'',//系统推荐的用法用量
      isJump:1,
      phaStaffIdStoreImg:'', //核对签名
      phaStaffIdStore:'',//如果为null,那么就是自动，存在就是手动
      historyInfo:{},//疾病基本信息
      radioList:[
        {value:'药品超量',label:'药品超量'},
        {value:'药品用法用量超量',label:'药品用法用量超量'},
        {value:'重复用药',label:'重复用药'},
        {value:'疾病错误',label:'疾病错误'},
        {value:'患者年龄不符合',label:'患者年龄不符合'},
        {value:'患者信息错误',label:'患者信息错误'},
        {value:'其他',label:'其他'},
      ],
      otherReasonVis: false, //其他原因输入框
    };
    this.inputRef = React.createRef();
    this.initDoctor = (element) => {
      this.video = element;
    };
    this.button = true;
  }

  componentDidMount = async () => {
    // this.video.handleJoin();//初始化视频
    //默认信息触底
    const { item, docterAvatar, docterId,visibleExcess,excessArr,isSumbit,isJump } = this.state;

    if(docterId){
      // 查看医生详细信息
      let res=await docterDetail({docterId:docterId})
      if(res.code==1 && res.data[0] && res.data[0].length){
        this.setState({
          isJump:res.data[0][0].isJump
        })
        if(res.data[0][0].isOurHospital == 2){
          if(res.data[0][0].docterExamine == 1 ){
            let feTime = new Date(res.data[0][0].filingExpirationDate)
            let nowDate = new Date()
            let timeDiff = feTime.getTime() - nowDate.getTime();
            let dasyDiff = Math.ceil(timeDiff/(1000*60*60*24))
            if(dasyDiff < 0){
              this.setState({
                visibledocter2:true
              })
            }
            this.setState({
              filingExpirationDate:res.data[0][0].filingExpirationDate,
              FilingDaysTime:dasyDiff
            })
          }
        }        
      }
    }

    if(item){
      this.setState({
        historyInfo:item
      })
    }

    // 查询问诊医生是否提交处方
    let isSumbitRes = await isSumbitPrescribing({ consultation_id: item.consultation_id })
    if(isSumbitRes && isSumbitRes.data && isSumbitRes.data[0] && isSumbitRes.data[0][0].total>0){
        this.setState({
            isSumbit: true
        })
    }else{
        this.setState({
            isSumbit: false
        })
    }

    // 查询该处方单上超量的药品
    let resExcess = await selectExcessNumber({ consultation_id: item.consultation_id })
    if(resExcess.code= 1 && resExcess.data && resExcess.data[0] && resExcess.data[0][0].excess_med_number != null){
      const checkIds = [109, 110, 114, 118, 111,113,116];
      let excessArrClassify = JSON.parse(resExcess.data[0][0].excess_med_number)
      excessArrClassify.forEach(itemA => {
          if(checkIds.includes(itemA.classifyId)){
              this.setState({
                  visibleExcess:true,
                  excessArr:JSON.parse(resExcess.data[0][0].excess_med_number)
              })
          }
      })
    }

    let res04 = await isChineseMeds({consultation_id: item.consultation_id})
    let res05 = await chineseMethodDoc({consultation_id: item.consultation_id})
    this.setState({
      ischinese:res04.data[0],
      chinesemethodtwo:res05.data[0]
    })

    let resdue = await selectBecomeDue({docterId:docterId})
    if(resdue.data && resdue.data[0]){
        this.setState({
            becomeduestate:resdue.data[0][0].is_become_due
        })
    }      



    if (getUrl("retirement", this.props.location.search)) {
      if (getUrl("retirement", this.props.location.search) == 2) {
        let res = await sureReceive({
          consultationId: getUrl("consultationId", this.props.location.search),
          docterId,
        });
        if (res && res.data && res.data[2]) {
          localStorage.setItem("item", JSON.stringify(res.data[2][0]));
          this.setState(
            {
              item: res.data[2][0],
            },
            () => {
              this.allNews1();
            }
          );
        }
      } else {
        this.allNews1();
      }
      this.setState({
        retirement: getUrl("retirement", this.props.location.search),
      });
    } else {
      this.allNews1();
    }
  };
  allNews1 = async () => {
    const { item, docterPhone, docterId } = this.state;
    let res = await docAvater({ docterId });
    if (res && res.data && res.data[0]) {
      this.setState({
        docterAvatar: res.data[0][0].docterAvatar,
      });
    }
    if (getUrl("prescription", this.props.location.search)) {
      const res0 = await getuserinfo({
        docterPhone,
      });
      if (
        res0 &&
        res0.data &&
        res0.data[0] &&
        res0.data[0].length &&
        res0.data[0][0].is_push == 1
      ) {
        // let res01=await nowtimeDoc()
        // console.log(res01)
        // if (res01&&res01.data&&res01.data[0]&&res01.data[0].length){
        //     let count=0
        //     res01.data[0].map(item=>{
        //         if (item.docterId==docterId){
        //             count=1
        //         }
        //     })
        //     if (count==1){
        let res02 = await allOrders(
          localStorage.getItem("jwtTokendoc"),
          docterId
        );
        if (res02 && res02.data && res02.data.length) {
          alert("接诊提示", "有新的患者等待接诊，请医生快去接诊。", [
            {
              text: "知道了",
              onPress: () => {
                this.props.history.replace("/offlineDoctorchat");
              },
            },
            {
              text: "去接诊",
              onPress: () => {
                this.props.history.push("/");
              },
            },
          ]);
        }
        //     }
        // }
      }
    }

    if (item != null) {
      this.setState(
        {
          consultationId: item.consultation_id,
          userpic: item.patient_avatar
            ? item.patient_avatar
            : "http://images.yyj-health.com/202202/1644215398065.gif",
          username: item.patientName,
          userId: item.user_id,
        },
        () => {
          this.getAllmeds();
          this.getnewsstatus();
          this.getnews();
          const newTimer = window.setTimeout(() => {
            this.scrollToBottom();
            window.clearTimeout(newTimer);
          }, 200);
        }
      );
      let res0 = await allIcd(item.consultation_id);
      if (res0 && res0.data && res0.data[0].length) {
        this.setState({
          icdlists: JSON.parse(res0.data[0][0].electronic_icd),
        });
      }
    }
  };
  componentWillUnmount = () => {
    const { item, timer, videofig,vedioTaskId } = this.state;

    if (item.consultation_type == "3" && videofig == 1) {
      savevideoTime({ consultationId: item.consultation_id }).then((res) => {
        // console.log(res);
      });
      window.clearTimeout(timer);
      this.video.handleLeave(); //关闭视频流

      // 结束视频录制
      endVedioRecording({
        taskId:vedioTaskId
      }).then((data)=>{
        console.log('结束视频录制');
      })
      Toast.info("已结束视频");
    }
  };
  handleWatchStatus = (status) => {
    const { item,vedioTaskId } = this.state;
    // console.log(status);
    if (status) {
      if (status.type == "remote" && status.msg == "unpublish") {
        this.video.handleLeave(); //关闭视频流

        // 结束视频录制
        endVedioRecording({
          taskId:vedioTaskId
        }).then((data)=>{
          console.log('结束视频录制');
        })

        savevideoTime({ consultationId: item.consultation_id }).then(
          (res) => { }
        );
        addvideoMessage({ consultation_id: item.consultation_id }).then(
          (res1) => {
            // console.log(res1);
          }
        );
        // const res = await getonenews({
        //     consultationId
        // })

        this.setState({
          videocode: 1,
          videofig: 0,
          videoTime: "",
        });
      }
    }
  };
  handleWatchLocalStatus = (status) => {
    const { item } = this.state;
    // console.log(status);
    if (status.msg == "localStream stop and remove") {
      Toast.info("已结束视频");
    }
  };
  handleWatchClientStatus = (status) => {
    const { item } = this.state;
    // console.log(status);
    // if (status){
    //     Toast.info(status.msg)
    // }
  };
  handleWatchOtherStatus = (status) => {
    const { item } = this.state;
    // console.log(status);
    // if (status){
    //     Toast.info(status.msg)
    // }
  };
  doctor = () => {
    const { docterId, doctorData,item,doctortext } = this.state;
    videoRecording(
      {
        doctorId: docterId.toString(),
        patientId: item.user_id.toString(),
        robotId: item.consultation_id.toString(),
        roomId: item.consultation_id.toString(),
        doctorName:item.docter_name.toString(),
        patientName:item.patientName.toString()
        // doctorId: '369',
        // patientId: '472',
        // robotId: '30936',
        // roomId: '30936'
      }
    ).then((data)=>{
      this.setState({
        vedioTaskId:data.Response.TaskId
      })
    }).catch((err)=>{
      // console.log(err);
    })

    videoSetting(
      // { institutionCode: "ZJYYJ", userId: "doc" + docterId },
      // data.data.token
      docterId
    ).then((res) => {
      this.setState(
        {
          doctorData: {
            sdkAppId: parseInt(res.body.appId),
            userSig: res.body.userSig,
          },
        },
        () => {
          // console.log(doctorData);
          this.video.handleJoin(); //初始化视频
        }
      );
    });
    // callWenzhen({}).then((data) => {
    //   if (data.data.token) {
        
    //   }
    // });
  };

  getAllmeds = async () => {
    const { timer, item, consultationId, details, b, chatLength } = this.state;
    let res = await allmedsNews({ consultationId: item.consultation_id });

    let res06 = await isChineseMeds({consultation_id: item.consultation_id})

    let a = [];
    if (res && res.data && res.data[1] && res.data[1].length) {
      res.data[1].map((item) => {
        // a.push(item.med_com_name + item.med_spec + " X" + item.med_quantity);
        let superType = "";
        if(res06.data[0].length){
          a.push({
            namespace: `${item.med_com_name} ${item.med_quantity}g`,
            superType: superType
          });
        }else{
          if (item.supervision_type === 1) {
            superType = "中药";
          } else if (item.supervision_type === 2) {
            superType = "西药";
          } else if (item.supervision_type === 3) {
            superType = "成药";
          }
          a.push({
            namespace: `${item.med_com_name}${item.med_spec} X${item.med_quantity}`,
            superType: superType
          });
        }
        
      });
      this.setState({
        medsNews: a,
      });
    }
  };
  // 获取聊天信息 
  getnews = async () => {
    const { timer, item, consultationId, videofig, b, chatLength,docterId,visible13,isJump } = this.state;
    window.clearTimeout(timer);

    if (consultationId) {
      let res = await docLine({
        consultationId,
        token1: localStorage.getItem("jwtTokendoc"),
      });

      // 查询店铺权限
      const res12 = await selectFiltering({pharmacyId:res.data[0][0].pharmacy_id})
      if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
        this.setState({
            filteringArr:res12.data[0]
        })
      }
      if(this.state.filteringArr.some(item => item.label === '药店药师审核' && item.label_type == 4)){
        this.setState({
          isPhaExamine: true
        })
      }

      // 查询是否药店药师驳回isPhaBohui
      const res13 = await selectIsPhaReject({consultationId : res.data[0][0].consultation_id})
      if(res13 && res13.data && res13.data[0] && res13.data[0][0]){
        if(res13.data[0][0].prescring_pha_state == 2 && res13.data[0][0].pha_refu_reson){
          this.setState({
            isPhaBohui: true 
          })
        }
      }

      

      if(res && res.data && res.data[0] && res.data[0].length){
        if(res.data[0][0].consultation_stu == '-1'){
          this.setState({
            visible13:true, //弹窗
          })
        }
      }

      if (res && res.data && res.data[2]) {
        let code = 1;
        let codestate = 1;
        res.data[2].map((item) => {
          if (item.detailType == "3") {
            code = 2;
            if (item.detail && item.detail.prescribing_state == -1) {
              codestate = 2;
            } else {
              codestate = 1;
            }
          }
        });
        this.setState({
          chatLength: res.data[2].length,
          qxcode: code == 2 ? 0 : 1,
          codestate,
        });
        if (
          chatLength != 0 &&
          chatLength < res.data[2].length &&
          res.data[2][res.data[2].length - 1].Identity == "1"
        ) {
          // this.setState({
          //   tip:true
          // })
          Toast.info("您有新消息");
        } else {
          // this.setState({
          //   tip:false
          // })
        }
      }
      if (res && res.data && res.data[0] && res.data[0].length) {
        if (res.data[0][0].consultation_stu != item.consultation_stu) {
          item.consultation_stu = res.data[0][0].consultation_stu;
          localStorage.setItem("item", JSON.stringify(item));
        }
        localStorage.setItem("memberId", res.data[0][0].memberId);
        if (res.data[0][0].receptionTime) {
          let h =
            Math.floor(
              (new Date() - new Date(res.data[0][0].receptionTime)) /
              1000 /
              60 /
              60
            ) < 10
              ? "0" +
              Math.floor(
                (new Date() - new Date(res.data[0][0].receptionTime)) /
                1000 /
                60 /
                60
              )
              : Math.floor(
                (new Date() - new Date(res.data[0][0].receptionTime)) /
                1000 /
                60 /
                60
              );
          let m =
            Math.floor(
              ((new Date() - new Date(res.data[0][0].receptionTime)) /
                1000 /
                60) %
              60
            ) < 10
              ? "0" +
              Math.floor(
                ((new Date() - new Date(res.data[0][0].receptionTime)) /
                  1000 /
                  60) %
                60
              )
              : Math.floor(
                ((new Date() - new Date(res.data[0][0].receptionTime)) /
                  1000 /
                  60) %
                60
              );
          let s =
            Math.floor(
              (((new Date() - new Date(res.data[0][0].receptionTime)) / 1000) %
                60) %
              60
            ) < 10
              ? "0" +
              Math.floor(
                (((new Date() - new Date(res.data[0][0].receptionTime)) /
                  1000) %
                  60) %
                60
              )
              : Math.floor(
                (((new Date() - new Date(res.data[0][0].receptionTime)) /
                  1000) %
                  60) %
                60
              );

          
              const receptionTime = new Date(res.data[0][0].reception_time);
              const currentTime = new Date();
              
              let hours = Math.floor((currentTime - receptionTime) / 1000 / 60 / 60);
              let minutes = Math.floor(((currentTime - receptionTime) / 1000 / 60) % 60);
              
              if (hours < 0 || (hours === 0 && minutes < 0)) {
                hours = 0;
                minutes = 0;
              }

          this.setState(
            {
              useTime: hours + "小时" + minutes + "分钟",
              // useTime:
              //   Math.floor(
              //     (new Date() - new Date(res.data[0][0].receptionTime)) /
              //     1000 /
              //     60 /
              //     60
              //   ) +
              //   "小时" +
              //   Math.floor(
              //     ((new Date() - new Date(res.data[0][0].receptionTime)) /
              //       1000 /
              //       60) %
              //     60
              //   ) +
              //   "分钟",
              videoTime: videofig != 1 ? "" : h + ":" + m + ":" + s,
              duration:
                item.consultation_type == "3" && res.data[0][0].duration
                  ? (res.data[0][0].duration / 60 >= 0 &&
                    res.data[0][0].duration / 60 < 10
                    ? "0" + Math.floor(res.data[0][0].duration / 60)
                    : Math.floor(res.data[0][0].duration / 60)) +
                  ":" +
                  (res.data[0][0].duration % 60 >= 0 &&
                    res.data[0][0].duration % 60 < 10
                    ? "0" + (res.data[0][0].duration % 60)
                    : (res.data[0][0].duration % 60) % 60)
                  : 0,
            },
            () => {
              if (
                item.consultation_type == "3" &&
                !videofig &&
                !res.data[0][0].duration
              ) {
                this.doctor();
                this.setState({
                  videofig: 1,
                });
                
              }
            }
          );
        }

        this.setState(
          {
            patientDetails: res.data[1][0], //患者详情
            consultationDetail: res.data[0][0].consultationDetail, //病情描述
            consultationFiles: res.data[0][0].consultationFiles,
            patientName: res.data[1][0].patient_name, //患者问诊信息
            patientSex: res.data[1][0].patient_sex,
            patientBirthday:
              new Date().getFullYear() -
              new Date(res.data[1][0].patient_birthday).getFullYear(),
            memberId: res.data[0][0].memberId,
            list: res.data[2], //聊天内容,
            item,
          },
          () => {
            if (item.consultation_stu == "-1" || item.consultation_stu == "0") {
              const newTimer = window.setTimeout(() => {
                this.getnews();
              }, 5000);
              // localStorage.setItem('timer',newTimer)

              // 查询问诊状态  将问诊状态是0的、问诊类型是图文、未点击开方按钮的  直接提交
              if(item.if_square_root == 2){
                selectConState({consultationId:item.consultation_id}).then((resState)=>{
                  if(resState && resState.data && resState.data[0]){
                    let stateDate = resState.data[0][0]
                    if( isJump==1 && stateDate.if_square_root == 2 && stateDate.consultation_type == 1 && stateDate.consultation_stu == 0){
                      this.prescribingCf();

                      item.if_square_root = 1
                      localStorage.setItem("item", JSON.stringify(item));
                    }
                  }
                })
              }

              this.setState({
                timer: newTimer,
              });
            }
          }
        );
      }
    }
  };
  // 获取当前里聊天状态
  getnewsstatus = () => {
    const { docterId, userId, item } = this.state;
    getStatus({
      docterId: parseInt(docterId),
      userId,
      consultationId: item.consultation_id,
      // orderNumber:item.order_number
    }).then((res) => {
      this.setState({
        bottomcode1: 2,
        bottomcode:
          res.data[0] &&
            res.data[0].length &&
            res.data[0][0].consultationStu == "0"
            ? true
            : false,
      });
    });
  };

  //聊天界面默认显示到最底部
  scrollToBottom = () => {
    if (this.messagesEnd) {
      const scrollHeight = this.messagesEnd.scrollHeight; //里面div的实际高度
      const height = this.messagesEnd.clientHeight; //网页可见高度
      const maxScrollTop = scrollHeight - height;
      this.messagesEnd.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
      //如果实际高度大于可见高度，说明是有滚动条的，则直接把网页被卷去的高度设置为两个div的高度差，实际效果就是滚动到底部了。
    }
  };
  closebox = async (code) => {
    const { consultationId, videofig, item, docterId, docterPhone,vedioTaskId } =
      this.state;
    if (code == 2) {
      const res = await changestatus({
        consultationStu: 1,
        consultationId,
      });
      if (res.code == 1 && item.order_number) {
        updateDoctype({ docterId, docterType: 1 }).then((res0) => {
          // console.log(res0);
        });
      }
      let a = item;
      a.consultation_stu = "1";
      localStorage.setItem("item", JSON.stringify(a));
      if (videofig == 1) {
        let res1 = await savevideoTime({
          consultationId: item.consultation_id,
        });
        this.video.handleLeave(); //关闭视频流

        // 结束视频录制
        endVedioRecording({
          taskId:vedioTaskId
        }).then((data)=>{
          console.log('结束视频录制');
        })

        this.setState(
          {
            videofig: 0,
            videocode: 1,
          },
          () => { }
        );
      }
      this.getnewsstatus();
      const res0 = await getuserinfo({
        docterPhone,
      });
      if (
        res0 &&
        res0.data &&
        res0.data[0] &&
        res0.data[0].length &&
        res0.data[0][0].is_push == 1
      ) {
        // let res01=await nowtimeDoc()
        // console.log(res01)
        // if (res01&&res01.data&&res01.data[0]&&res01.data[0].length){
        //     let count=0
        //     res01.data[0].map(item=>{
        //         if (item.docterId==docterId){
        //             count=1
        //         }
        //     })
        //     if (count==1){
        let res02 = await allOrders(
          localStorage.getItem("jwtTokendoc"),
          docterId
        );

        if (res02 && res02.data && res02.data.length) {
          alert("接诊提示", "有新的患者等待接诊，请医生快去接诊。", [
            {
              text: "知道了",
              onPress: () => {
                this.props.history.replace("/offlineDoctorchat");
              },
            },
            {
              text: "去接诊",
              onPress: () => {
                this.props.history.push("/");
              },
            },
          ]);
        }
        //     }
        // }
      }
      //上传监管平台
      // this.getToken()
      this.setState({
        visible: false,
        overcode: true,
        item: a,
      });
    } else if (code == 1) {
      this.setState({
        visible: false,
      });
    } else if (code == 3) {
      this.setState({
        visible3: false,
      });
    } else if (code == 4) {
      this.setState({
        visible3: false,
      });
      const { consultationId, item } = this.state;
      if (item.order_number) {
        updateDoctype({ docterId, docterType: 2 }).then((res0) => {
          // console.log(res0);
        });
      }
      const res = await changestatus({
        consultationStu: 0,
        consultationId,
      });
      // console.log(res);
      let a = item;
      a.consultation_stu = "0";
      localStorage.setItem("item", JSON.stringify(a));
      this.setState({
        bottomcode1: 2,
        bottomcode: true,
        item: a,
      });
    }
  };
  //获取监管平台token
  getToken = async () => {
    // this.uploadData()
    this.storageToken();
  };
  /**
   * @description 获取token
   */
  storageToken = () => {
    const { token, upload, item } = this.state;
    tokenSupervision({})
      .then((data) => {
        if (data.data[0][0]) {
          this.setState(
            {
              token: data.data[0][0].token,
            },
            () => {
              this.getImage();
            }
          );
        } else {
          getToken()
            .then((data) => {
              let value = JSON.parse(data);
              addToken({
                token: value.accessToken,
                expiresIn: value.expiresIn,
              }).then((data) => {
                this.setState(
                  {
                    token: value.accessToken,
                  },
                  () => {
                    this.getImage();
                  }
                );
                Toast.success("成功");
              });
            })
            .catch((e) => console.log(e?.message || "失败"));
        }
      })
      .catch((e) => Toast.fail("请求失败"));
  };
  /**
   * @description 获取图片64
   * @params item
   */
  getImage = async () => {
    const { token, upload, item } = this.state;
    let res = await getnewslist({
      consultationId: item.consultation_id,
    });
    if (res && res.data && res.data[0]) {
      getBase64(item.consultation_files, callback);

      let type = item.consultation_files?.split(".").pop().toLowerCase();

      let furtherConsultDiagnosis = [];
      let furtherConsultDiagnosisNo = [];
      JSON.parse(res.data[0][0].electronic_icd).forEach((item) => {
        furtherConsultDiagnosisNo.push(item.icdValue);
        furtherConsultDiagnosis.push(item.icdName);
      });

      function callback(value) {
        upload.push({
          accessToken: token,
          clientId: supervise_data.clientId,
          thirdUniqueid: item.consultation_id,
          orgName: supervise_data.orgName,
          orgCode: supervise_data.orgCode,
          channelName: supervise_data.channelName,
          section: item.dep_name,
          sectionCode: item.dep_num,
          docName: item.docter_name,
          certificateNum: item.certificateNum,
          patientName: item.patientName,
          patientAge: item.patient_age,
          patientSex: item.patientSex,
          patientIdcardType: 1,
          patientIdcardNum: item.patientIdcardNum,
          furtherConsultNo: item.consultation_number,
          furtherConsulType: parseInt(item.consultation_type),
          medicalHistory: item.consultation_detail,
          consultDiagnosisType: type === "jpg" ? 2 : type === "png" ? 3 : 4,
          consultDiagnosis: value,
          consultOrg: item.consultOrg,
          furtherConsultApplyTime: moment(item.pay_time).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          furtherConsulStartTime: moment(item.reception_time).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          furtherConsulEndTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          furtherConsulIsReply: 1,
          feeType: 1,
          furtherConsultDiagnosis: furtherConsultDiagnosis
            .toString()
            .replace(",", "|"),
          furtherConsultDiagnosisNo: furtherConsultDiagnosisNo
            .toString()
            .replace(",", "|"),
          furtherConsultPrice: item.consultation_amount / 100,
          patientEvaluate: 1,
          isPrescription: 1,
          uploadTime: moment().format("YYYY-MM-DD HH:mm:ss"),
          cityId: item.city_id,
          isMark: 1,
          isRiskWarn: 1,
          isPatientSign: 1,
          disposeResult: "无",
          complainInfo: "无",
          consultTime: moment(item.consultTime).format("YYYY-MM-DD HH:mm:ss"),
        });

        if (item.patient_age < 6) {
          upload.guardianName = item.guardianName;
          upload.guardianIdcardNum = item.guardianIdcardNum;
        }
        postSupervise("/wjw/upload/uploadFurtherConsult", upload)
          .then((data) => {
            if (data.data.status != -1) {
              updateConsultation({ id: item.consultation_id }).then((data1) => {
                // console.log(data1);
              });
            }
          })
          .catch((e) => {
            // console.log(e)
            // Toast.fail('上传监管平台失败')
          });
      }
    }
  };

  gobank = () => {
    // window.history.back()
    const { timer } = this.state;
    window.clearTimeout(timer);
    this.props.history.push("/news");
  };
  getreason = (e) => {
    this.setState({
      reason: e.target.value.replace(/\s*/g, ""),
    });
  };
  // 确认退诊
  cancelchat = async () => {
    const { reason, consultationId, item, docterId } = this.state;
    if (reason) {
      const res = await changestatus({
        consultationStu: 3,
        consultationId,
        consultationReason: reason,
      });
      if (res.code == 1 && item.order_number) {
        updateDoctype({ docterId, docterType: 1 }).then((res0) => {
          // console.log(res0);
        });
      }
      if (res && res.code == 1) {
        let params = {
          client_id: 5000,
          client_trade_no: item.consultation_number,
          total_fee: item.consultation_amount,
          timestamp: moment().format("YYYYMMDDHHmmss"),
        };
        refundApply(params).then((res1) => {
          if (res1 && res1.code == 1) {
            Toast.info("退款成功");
          }
        });
      }
      let a = item;
      a.consultation_stu = "3";
      localStorage.setItem("item", JSON.stringify(a));
      this.getnewsstatus();
      this.setState({
        visible2: false,
        item: a,
      });
    } else {
      Toast.fail("请选择缘由", 1);
    }
  };
  changevisible2 = () => {
    this.setState({
      visible2: true,
    });
  };
  //更改聊天状态
  changestatus = async () => {
    // const { consultationId,item } = this.state
    this.setState({
      visible3: true,
    });
    // const res = await changestatus({
    //   consultationStu: 0,
    //   consultationId
    // })
    // console.log(res);
    // let a=item
    // a.consultation_stu='0'
    // localStorage.setItem("item", JSON.stringify(a))
    // this.getnewsstatus()
    // console.log(res);
  };
  //获取聊天框内容
  changevalue = (e) => {
    const scrollHeight = this.messagesEnd.scrollHeight; //里面div的实际高度
    const height = this.messagesEnd.clientHeight; //
    // if (scrollHeight-height>50){
    //      document.getElementById('inputword').focus()
    // }
    this.setState({
      detailContent: e.target.value,
    });
  };
  //发送聊天信息
  sendout = async () => {
    const { consultationId, detailContent,FilingDaysTime,caPassTime } = this.state;

    if(FilingDaysTime<0){
      this.setState({
        visibledocter2:true
      })
      return
    }
    if(caPassTime<=0){
      Toast.show('您的身份信息已过期，请联系客服解决后使用')
      return
    }
    
    if (detailContent) {
      this.inputRef.current.focus();
      const res = await addnews({
        consultationId,
        Identity: 2,
        detailType: 1,
        detailContent,
      });

      this.setState(
        {
          detailContent: "",
        },
        () => {
          const newTimer = window.setTimeout(() => {
            window.clearTimeout(newTimer);
            this.getnews();
            this.scrollToBottom();
          }, 200);
        }
      );
    } else {
      Toast.fail("请先输入内容", 1);
    }
  };
  //图片上传发送
  imgChange = async (files, type, index) => {
    const { consultationId, compressThreshold = 1 } = this.state;
    let imgList = [];
    let pictureQuality = 0.5;
    if (type == "add") {
      let file = files[files.length - 1].file;
      let fileSize = file.size / 1024 / 1024;
      if (
        file.type == "image/png" ||
        file.type == "image/jpeg" ||
        file.type == "image/jpg"
      ) {
        if (fileSize >= compressThreshold) {
          if (fileSize * 0.9 <= 1) {
            pictureQuality = 0.9;
          } else if (fileSize * 0.8 <= 1) {
            pictureQuality = 0.8;
          } else if (fileSize * 0.7 <= 1) {
            pictureQuality = 0.7;
          } else if (fileSize * 0.6 <= 1) {
            pictureQuality = 0.6;
          } else if (fileSize * 0.5 <= 1) {
            pictureQuality = 0.5;
          } else if (fileSize * 0.4 <= 1) {
            pictureQuality = 0.4;
          } else if (fileSize * 0.3 <= 1) {
            pictureQuality = 0.3;
          } else if (fileSize * 0.2 <= 1) {
            pictureQuality = 0.2;
          } else if (fileSize * 0.1 <= 1) {
            pictureQuality = 0.1;
          } else {
            pictureQuality = 0.5;
          }
          //判断浏览器内核是否支持base64图片压缩
          if (typeof FileReader === "undefined") {
            return file;
          } else {
            try {
              return new Promise((resolve) => {
                //压缩图片再上传
                let a = getBase64Image(file, pictureQuality, callback1);
                let that = this;

                function callback1(val) {
                  //base64转png图片
                  zipImg(val).then((res1) => {
                    let formData = new FormData();
                    formData.append("file", res1);
                    uploadpic(formData)
                      .then(async (res2) => {
                        if (res2.code === 2) {
                          const res3 = await addnews({
                            consultationId: consultationId,
                            Identity: 2,
                            detailType: 2,
                            detailContent: res2.data,
                          });
                          // this.scrollToBottom()
                          if (res3.code == 1) {
                            const newTimer = window.setTimeout(() => {
                              window.clearTimeout(newTimer);
                              this.scrollToBottom();
                              this.getnews();
                            }, 200);
                          }
                        } else {
                          Toast.info("上传失败", 1);
                        }
                      })
                      .catch((err) => {
                        // Toast.hide()
                      });
                  });
                }
              })
                .then((res) => { })
                .catch(() => {
                  this.setState({
                    spinLoading: false,
                  });
                  return file;
                });
            } catch (e) {
              //压缩出错,直接返回原file对象
              return file;
            }
          }
        } else {
          let formData = new FormData();
          const res = await zipImg(file);
          formData.append("file", res);
          const res2 = await uploadpic(formData);
          const res3 = await addnews({
            consultationId: consultationId,
            Identity: 2,
            detailType: 2,
            detailContent: res2.data,
          });
          if (res3.code == 1) {
            const newTimer = window.setTimeout(() => {
              window.clearTimeout(newTimer);
              this.scrollToBottom();
              this.getnews();
            }, 200);
          }
        }
      } else {
        Toast.show("请上传png/jpg格式的图片", 1);
      }
    } else {
      this.setState({
        fileList: [],
      });
    }
  };
  // 查看详情
  toinfo = async (detailContent, docEdit) => {
    const { consultationDetail, patientName, patientSex, item } = this.state;
    let res = await allMedicinal({ prescribingId: detailContent });
    if (res.code == 1 && res.data[0]) {
      let res1 = await prescribingDetail({
        prescribingId: res.data[0][0].prescribing_id,
      });
      let time1 = new Date(res1.data[0][0].create_time).getTime();
      if (res1 && res1.data && res1.data[0]) {
        if (res1.data[0][0].original_prescribing) {
          this.setState(
            {
              list3: res.data[0],
              detail: res1.data[0][0],
              originalPrescribing: res1.data[0][0].original_prescribing
                ? res1.data[0][0].original_prescribing
                : "",
              year: res1.data[0][0].year,
              month: res1.data[0][0].month,
              day: res1.data[0][0].day,
              visible7: res1.data[0][0].original_prescribing ? true : false,
            },
            () => {
              this.getnews();
            }
          );
          if (res1.data[2]) {
            this.setState({
              docterAutograph: res1.data[2][0].docterAutograph,
            });
          }
          if (res1.data[0][0].pharmacist_id) {
            caWrite({ docterId: res1.data[0][0].pharmacist_id }).then(
              (res2) => {
                if (res2 && res2.data && res2.data[0]) {
                  this.setState({
                    docterAutograph1: res2.data[0][0].docterAutograph,
                  });
                }
              }
            );
          }

          if(res1.data[0][0].pha_pharmacist_id_store){
            // 查询药店药师对应
            selectPrescPhaSign({ 
                docter_id:res1.data[0][0].pha_pharmacist_id_store
            }).then((resPhas)=>{
                if(resPhas && resPhas.data && resPhas.data[0] && resPhas.data[0][0].docterAutograph3){
                    this.setState({
                        docterAutograph3:resPhas.data[0][0].docterAutograph3
                    })
                }
            })
          }

        } else {
          if (item.consultation_type == "1") {
            if (docEdit == 1) {
              Toast.info("无效处方单");
            } else {
              Toast.info("处方未上传成功，重新上传");
              this.props.history.push(
                `/prescribing/prescription?prescribingId=${res.data[0][0].prescribing_id
                }&offcode=${1}`
              );
            }
          } else {
            Toast.info("处方未上传成功，请点击修改处方重新上传");
          }
        }
      }
    }

    let res06 = await belongStore({prescribingId:res.data[0][0].prescribing_id})
    if(res06.data[0][0].pharmacy_id != null){
        const res12 = await selectFiltering({pharmacyId:res06.data[0][0].pharmacy_id})
        if(res12.code==1 && res12.data && res12.data[0] && res12.data[0].length){
            this.setState({
                filteringArr:res12.data[0]
            })
        }

        // 查询处方单店员、药店药师签名图
        let res88 = await selectSignaturePic({prescribingId:res.data[0][0].prescribing_id})
        if(res88.data && res88.data[0] && res88.data[0][0].phaStaffIdStore){
          this.setState({
              phaStaffIdStoreImg:res88.data[1][0].phaStaffIdStoreImg,
              phaStaffIdStore:res88.data[0][0].phaStaffIdStore
          })
        }else{
            this.setState({
                phaStaffIdStoreImg:''
            })
        }

        if (this.state.filteringArr.some(item => item.label === "核对/发药")) {
            if(res88.data[0][0].phyStaffImg){
              this.setState({
                  staff_img:res88.data[0][0].phyStaffImg
              })
            } 
        }else{
            this.setState({
              staff_img:'',
            })
        }

        if (this.state.filteringArr.some(item => item.label === "药店药师审核" && item.label_type == 3)) {
          if(res88.data[0][0].phyPharmacistImg){
              this.setState({
                  pharmacist_img:res88.data[0][0].phyPharmacistImg
              })
          }
        }else{
            this.setState({
              pharmacist_img:"",
            })
        }
        
        if (this.state.filteringArr.some(item => item.label === "调配")) {
          if(res88.data[0][0].phyPharmacistsImg){
              this.setState({
                  pharmacist_img1:res88.data[0][0].phyPharmacistsImg
              })
          }
        }else{
          this.setState({
            pharmacist_img1:"",
          })
        }
    }   


  };

  // 开处方
  prescribingCf = async (detailContent) => {
    const { timer, consultationId, list, item, docterId, somegoods,visible13,FilingDaysTime,caPassTime,chasomegoods,visibleExcess,somegoodssystem } =
      this.state;

      if(FilingDaysTime<0){
        this.setState({
          visibledocter2:true
        })
        return
      }
      if(caPassTime<=0){
        Toast.show('您的身份信息已过期，请联系客服解决后使用')
        return
      }


    // 更新是否点击开方按钮
    let res10= await isSquareroot({consultationId:consultationId})

    if(item.consultation_stu == '-1'){
      this.setState({
        visible13:true
      })
      return
    }else{

      if (item.consultation_type == "1") {
        if (detailContent) {
          window.clearTimeout(timer);
          this.props.history.push(
            `/prescribing/offaddcf?detailContent=${detailContent}`
          );
        } else {
          let code0 = 0;
          list.forEach((item) => {
            if (item.detailType == "3") {
              code0 = 1;
            }
          });
          // if (this.button) {
            // if ( !code0 && list[list.length - 1].detailContent != "查看了您的复诊信息，您已明确在线下医院确诊过此疾病并使用过该药品，且病情稳定无相关禁忌和不良反应，正在根据您提交的复诊信息开方，请稍等" ) {
          //     this.button = false;
          //     const res = await addnews({
          //       consultationId,
          //       Identity: 2,
          //       detailType: 1,
          //       detailContent:
          //         "查看了您的复诊信息，您已明确在线下医院确诊过此疾病并使用过该药品，且病情稳定无相关禁忌和不良反应，正在根据您提交的复诊信息开方，请稍等",
          //     });
          //   }
          // }
          window.clearTimeout(timer);
          this.props.history.push({
            pathname: "/prescribing/offaddcf",
          });
        }
      } else {
        this.setState(
          {
            visible12: true,
            detailContent0: detailContent,
            visibleExcess:true,
          },
          async () => {
            let code0 = 0;
            list.forEach((item) => {
              if (item.detailType == "3") {
                code0 = 1;
              }
            });
            let list1 = [];
            if (detailContent) {
              let res = await allMedicinal({ prescribingId: detailContent });
              if (res && res.data && res.data[0] && res.data[0].length) {
                list1 = res.data[0];
              }
            }
  
            let res0 = await prescribingPrice({ docterId });
            if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
              res0.data[0].forEach((i) => {
                i.start_time = i.start_time.replace(/:/g, "");
                i.end_time = i.end_time.replace(/:/g, "");
              });
              this.setState({
                priceArray: res0.data[0][0].price,
              });
            }

            let chamedsmethod = []
            let nowarr = [];
            let newUsageArr=[]
            let sumPrice = 0;

            if (item) {
              if (detailContent) {
                list1.forEach((item) => {
                  let obj = {};

                  obj.medAllCount = item.med_all_count
                  obj.medLargeUsage = item.med_large_usage
                  obj.medLargeDay = item.med_large_day
                  obj.medFrequencyLarge = item.med_frequency_large
                  obj.medDayCountSys = item.medicinal_day ? item.medicinal_day : ''

                  obj.medId = item.med_id;
                  obj.isOtc = item.is_otc;
                  obj.libraryId = item.library_id ? item.library_id : "0";
                  obj.contacts = item.contacts;
                  obj.medQrcode = item.med_qrcode;
                  obj.thirdPartyId = item.third_party_id;
                  obj.medComName = item.med_com_name;
                  obj.doseUnit = item.doseUnit;
                  obj.medicinalName = item.medicinal_name;
                  obj.medicinalSpec = item.medicinal_spec;
                  obj.medicinalType = item.medicinal_type;
                  obj.medicinalDay = item.medicinal_day;
                  obj.medicinalDosage_form = item.medicinal_dosage_form;
                  obj.medicinalPacking = item.medicinal_packing;
                  obj.medicinalApplySymptoms = item.medicinal_apply_symptoms;
                  obj.medicinalQuantity = item.medicinal_quantity;
                  obj.medicinalFrequency = item.medicinal_frequency;
                  obj.medicinalUsage = item.medicinal_usage;
                  obj.medicinalMethod = item.medicinal_method;
                  obj.medicinalSupplementary = item.medicinal_supplementary;
                  obj.medicinalPrice = item.medicinal_price;
                  obj.medApproval = item.med_approval;
                  obj.medCompany = item.med_company;
                  obj.useQuantity = item.medicinal_quantity;
                  obj.packingUnit = item.packing_unit;
                  obj.useSpec = "";
                  obj.useDay = item.medicinal_day;
                  obj.usePl = item.doseUnit;
                  obj.useCount = "1次";
                  obj.canchange = 1;
                  obj.useWay = item.medicinal_method;
                  obj.supervisionType = item.supervision_type;
                  sumPrice += item.medicinal_price * item.medicinal_quantity;
                  nowarr.push(obj);

                  newUsageArr.push({...obj})

                });

                this.setState({
                  somegoodssystem:newUsageArr,
                  somegoods: nowarr,
                  supervisionType: list1[0].supervision_type,
                  docterProposal:
                    list1[0].docterProposal ==
                      "请严格按原处方和药品说明书使用，严禁超量超范围使用；如用药过程中出现病情变化或其它不适症状，请立即停药并及时就医。"
                      ? ""
                      : list[0].docterProposal,
                });
              } else {
                let res = await allIllsnews({
                  consultationId: item.consultation_id,
                });
                if (res && res.data && res.data[1] && res.data[1].length) {
                  let arr = [];
                  let arr1 = [];
                  let params = {};
  
                  // localStorage.setItem("somegoods", JSON.stringify(res.data[1]))
                  res.data[1].map(async (item) => {
                    let obj = {};
                    obj.medAllCount = item.med_all_count ? item.med_all_count : ''
                    obj.medLargeUsage = item.med_large_usage ? item.med_large_usage : ''
                    obj.medLargeDay = item.med_large_day ? item.med_large_day : ''
                    obj.medFrequencyLarge = item.med_frequency_large ? item.med_frequency_large : ''
                    obj.medDayCountSys = item.med_day ? item.med_day : ''

                    obj.medId = item.med_id;
                    obj.isOtc = item.is_otc;
                    obj.libraryId = item.library_id ? item.library_id : "0";
                    obj.contacts = item.contacts;
                    obj.medQrcode = item.med_qrcode ? item.med_qrcode : "";
                    obj.thirdPartyId = item.third_party_id
                      ? item.third_party_id
                      : "";
                    obj.medComName = item.med_com_name ? item.med_com_name : "";
                    obj.doseUnit = item.doseUnit ? item.doseUnit : "";
                    obj.medicinalName = item.med_name;
                    obj.medicinalSpec = item.med_spec;
                    obj.medicinalType = item.med_type ? item.med_type : "西药";
                    obj.medicinalDay = item.med_day ? item.med_day : 1;
                    obj.medicinalDosage_form = item.med_dosage_form
                      ? item.med_dosage_form
                      : "";
                    obj.medicinalPacking = item.med_packing;
                    obj.medicinalApplySymptoms = item.med_apply_symptoms
                      ? item.med_apply_symptoms
                      : "";
                    obj.medicinalQuantity = item.med_quantity;
                    obj.medicinalFrequency = item.med_frequency
                      ? item.med_frequency
                      : item.medicinalFrequency
                        ? item.medicinalFrequency
                        : "每天1次";
                    obj.medicinalUsage = item.med_usage
                      ? item.med_usage
                      : item.medicinalUsage
                        ? item.medicinalUsage
                        : "";
                    obj.medicinalMethod = item.med_method
                      ? item.med_method
                      : item.medicinalMethod
                        ? item.medicinalMethod
                        : "口服";
                    obj.medicinalSupplementary = item.medicinalSupplementary
                      ? item.medicinalSupplementary
                      : "";
                    obj.medicinalPrice = item.med_price;
                    obj.medApproval = item.med_approval;
                    obj.medCompany = item.med_company;
                    obj.packingUnit = item.packing_unit;
                    obj.useQuantity = 1;
                    obj.useSpec = "";
                    obj.useDay = item.med_day ? item.med_day : 1;
                    obj.usePl = "每天";
                    obj.useCount = "1次";
                    obj.canchange = item.med_frequency || item.med_usage ? 1 : 2;
                    obj.useWay = item.med_method ? item.med_method : "口服";
                    obj.supervisionType = item.supervision_type;
                    obj.doctor_advice = item.doctor_advice;//12月15日新增
                    sumPrice += item.med_price * item.med_quantity;
                    nowarr.push(obj);

                    newUsageArr.push({...obj})

                  });
                  this.setState({
                    somegoodssystem:newUsageArr,
                    somegoods: nowarr,
                    supervisionType: res.data[0][0].supervision_type,
                  });

                  let res11 = await chineseMethodDoc({ consultation_id: item.consultation_id })
                  this.setState({
                      mtharr: res11.data[0][0]
                  })

                  if (res11.data[0].length) {
                      res11.data[0].map((i) => {
                          let obj2 = {}
                          obj2.co_demand = i.co_demand
                          obj2.meiday = i.meiday
                          obj2.daily = i.daily
                          obj2.each_dose = i.each_dose
                          obj2.med_method = i.med_method
                          obj2.med_time = i.med_time
                          obj2.at_time = i.at_time
                          obj2.unit = i.unit
                          chamedsmethod.push(obj2)
                      })
                      this.setState({
                          chamedsmethod: chamedsmethod
                      })
                  }

                }
              }
              this.setState({
                // somegoods: nowarr,
                medAmount: sumPrice,
              });
              this.getdoctor();
              this.toIcd();
            }
          }
        );
      }

    }

    
  };

  //获取医生信息
  getdoctor = async () => {
    const { docterPhone } = this.state;
    const res = await getuserinfo({
      docterPhone,
    });
    this.setState({
      doctortext: res.data[0][0],
    });
  };
  toIcd = async () => {
    const { item, icdlists } = this.state;
    // if (!icdList.length){
    let res = await allIcd(item.consultation_id);
    if (res && res.data && res.data[0]) {
      if (res.data[0].length) {
        // console.log(JSON.parse(res.data[0][0].electronic_icd));
        let arr = JSON.parse(res.data[0][0].electronic_icd);
        let arr2 = [];
        let arr3 = [];
        arr.map((item) => {
          item.state = 1;
          arr2.push(item.icdName);
          arr3.push(item.icdValue);
        });
        this.setState({
          icdlists: arr,
          // visible3: true,
          docterDiagnosis: arr2.toString().replace(/\,/g, "; "),
          icdValue: arr3.toString().replace(/\,/g, "|"),
        });
      } else {
        Toast.info("请先选择诊断结果");
      }
    }
  };
  delIcd = (e, item) => {
    const { page, size, icdList, icdone, icdlists } = this.state;
    e.stopPropagation();
    alert("确认删除此病历？", item.icdName, [
      {
        text: "取消",
        onPress: () => {
          console.log("取消");
        },
      },
      {
        text: "确认",
        onPress: () => {
          let arr = [];
          let arr1 = [];
          let arr2 = [];
          icdlists.map((j) => {
            if (j.icdValue != item.icdValue) {
              arr2.push(j);
            }
          });
          this.setState({
            icdlists: arr2,
          });
        },
      },
    ]);
  };
  setIcd = (e, item) => {
    const { icdList, icdlists } = this.state;
    e.stopPropagation();
    // if (item.state==1) {
    //     item.state = 2
    // } else {
    item.state = 1;
    // }
    if (icdlists.length) {
      let code = 0;
      icdlists.forEach((i) => {
        if (i.icdValue == item.icdValue) {
          code = 1;
          Toast.info("此病例已添加，请不要重复添加");
          return;
        }
      });
      if (!code) {
        this.setState({
          icdlists: [...icdlists, item],
          visible5: false,
        });
      }
    } else {
      this.setState({
        icdlists: [...icdlists, item],
        visible5: false,
      });
    }
  };
  changeState = (item) => {
    const { icdList, icdlists } = this.state;
    if (item.state == 1) {
      item.state = 2;
    } else {
      item.state = 1;
    }
    this.setState({
      icdlists: [...icdlists],
    });
  };
  //确认病历
  onSure = () => {
    const { icdList } = this.state;
    let arr = [];
    let arr1 = [];
    let arr2 = [];
    icdList.map((item) => {
      if (item.state == 2) {
        arr.push(item);
        arr1.push(item.icdName);
        arr2.push(item.icdValue);
      }
    });

    if (arr1.length) {
      this.setState({
        visible03: false,
        docterDiagnosis: arr1.length ? arr1.toString().replace(/\,/g, "|") : "",
        icdValue: arr2.length ? arr2.toString().replace(/\,/g, "|") : "",
      });
      localStorage.setItem(
        "docterDiagnosis",
        arr1.toString().replace(/\,/g, "|")
      );
      localStorage.setItem("icdValue", arr2.toString().replace(/\,/g, "|"));
    } else {
      Toast.info("请先选择诊断结果");
    }
  };
  //搜索病历
  onSearch = async () => {
    const { page1, size1, icdone, icdList, icdlists } = this.state;
    const params = {
      content: icdone,
      page: 1,
      size: size1,
    };
    let res = await getIcdCode(params);
    if (res && res.data && res.data[0]) {
      res.data[0].forEach((i) => {
        icdlists.forEach((j) => {
          if (i.id == j.id) {
            i.state = 2;
          }
        });
      });
      this.setState({
        icdList: res.data[0],
        page1: 1,
        visible5: true,
      });
    }
  };
  // 获取单位
  dianji1 = (index) => {
    const { danwei, i } = this.state;
    this.setState({
      i: index,
    });
  };
  // 获取频率
  dianji = (index) => {
    const { day } = this.state;
    this.setState({
      j: index,
    });
  };
  // 隐藏频率
  closeDay = () => {
    const { day, j, medId, somegoods } = this.state;
    somegoods.map((item) => {
      if (item.medId == medId) {
        item.usePl = day[j];
        item.medicinalFrequency = day[j] + item.useCount;
        this.setState({
          somegoods,
          visible00: false,
          j: 0,
        });
        // localStorage.setItem("somegoods", somegoods)
      }
    });
  };
  // 获取次数
  dianji2 = (index) => {
    const { day } = this.state;
    this.setState({
      k: index,
    });
  };
  // 隐藏次数
  closeCount = () => {
    const { count, k, medId, somegoods } = this.state;
    somegoods.map((item) => {
      if (item.medId == medId) {
        item.useCount = count[k];
        item.medicinalFrequency = item.usePl + count[k];
        this.setState({
          somegoods,
          visible02: false,
          k: 0,
        });
        // localStorage.setItem("somegoods", somegoods)
      }
    });
  };
  // 获取次数
  dianji4 = (index) => {
    const { day } = this.state;
    this.setState({
      m: index,
    });
  };
  // 隐藏次数
  closeway = () => {
    const { useway, m, medId, somegoods } = this.state;
    somegoods.map((item) => {
      if (item.medId == medId) {
        item.useWay = useway[m];
        item.medicinalMethod = useway[m];
        this.setState({
          somegoods,
          visible04: false,
          m: 0,
        });
        // localStorage.setItem("somegoods", somegoods)
      }
    });
  };
  // 隐藏单位
  closeDanwei = () => {
    const { danwei, i, medId, somegoods } = this.state;
    somegoods.map((item) => {
      if (item.medId == medId) {
        item.useSpec = danwei[i];
        item.doseUnit = danwei[i];
        item.medicinalUsage = item.useQuantity + danwei[i];
        this.setState({
          somegoods,
          visible01: false,
          i: 0,
        });
        // localStorage.setItem("somegoods", somegoods)
      }
    });
  };
  getvalue = async (e, index, text) => {
    const { somegoods } = this.state;

    switch (text) {
      case "way": //用药方法
        somegoods[index].useWay = e.target.value.replace(/\s*/g, "");
        somegoods[index].medicinalMethod = e.target.value.replace(/\s*/g, "");



        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "medicinalSupplementary": //备注
        somegoods[index].medicinalSupplementary = e.target.value.replace(
          /\s*/g,
          ""
        );
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "quantity":
        somegoods[index].useQuantity = e.target.value.replace(/\s*/g, "");
        somegoods[index].medicinalUsage =
          e.target.value.replace(/\s*/g, "") + somegoods[index].useSpec;
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "quantity2":
        if (e.target.value) {
          // somegoods[index].useQuantity = e.target.value.match(/\d+(\.\d+)?/g)[0]
          somegoods[index].medicinalUsage = e.target.value.replace(/\s*/g, "");
        } else {
          // somegoods[index].useQuantity = ''
          somegoods[index].medicinalUsage = "";
        }
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "medfrequency":
        if (e.target.value) {
          somegoods[index].medicinalFrequency = e.target.value.replace(/\s*/g,"");
        } else {
          somegoods[index].medicinalFrequency = "";
        }
        this.setState({
          somegoods,
        });
        break;

      case 'chineseQuantity':
        if(e.target.value){
            somegoods[index].medicinalQuantity = e.target.value.replace(/\s*/g, "")
        }else{
            somegoods[index].medicinalQuantity = ''
        }
        this.setState({
            somegoods
        })
        break;
    }
  };
  changenum2 = (text, index) => {
    const { somegoods } = this.state;
    let list = somegoods[index];
    switch (text) {
      case "quantityreduce": //单次用量
        if (list.useQuantity > 1) {
          list.useQuantity = parseInt(list.useQuantity) - 1;
          list.medicinalUsage = parseInt(list.useQuantity) + list.useSpec;
        } else {
          list.useQuantity = 1;
          list.medicinalUsage = 1 + list.useSpec;
        }
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "quantityadd":
        list.useQuantity = parseInt(list.useQuantity) + 1;
        list.medicinalUsage = parseInt(list.useQuantity) + list.useSpec;
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "dayreduce": //用药天数
        if (list.useDay > 1) {
          list.useDay = parseInt(list.useDay) - 1;
          list.medicinalDay = parseInt(list.useDay);
        } else {
          list.useDay = 1;
          list.medicinalDay = 1;
        }
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
      case "dayadd":
        list.useDay = parseInt(list.useDay) + 1;
        list.medicinalDay = parseInt(list.useDay);
        this.setState({
          somegoods,
        });
        // localStorage.setItem("somegoods", somegoods)
        break;
    }
  };
  //分页搜索病历
  pageMedlist1 = async () => {
    const { page1, size1, icdList, icdone } = this.state;
    const params = {
      content: icdone,
      page: page1 + 1,
      size: size1,
    };
    let res = await getIcdCode(params);
    if (res && res.data && res.data[0]) {
      this.setState({
        icdList: [...icdList, ...res.data[0]],
        page1: page1 + 1,
      });
    }
  };
  onscroll1 = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      10
    ) {
      this.pageMedlist1();
    }
  };
  showUseways = (index1) => {
    this.setState(
      {
        visible06: true,
        index01: index1,
      },
      () => {
        let timer = setTimeout(() => {
          this.setState(
            {
              visible06: false,
            },
            () => {
              window.clearTimeout(timer);
            }
          );
        }, 8000);
      }
    );
  };

  // 十八反十九畏提醒
  handleSubmitTip = async ()=>{
    const {
        somegoods,
        item, 
        chamedsmethod,
        ischinese,
        somegoodssystem
    } = this.state

    let arraymed = []
    if(ischinese.length > 0){
        somegoods.forEach((item,key) => {
            arraymed.push({
                medId: item.medId,
                medQuantity: item.medicinalQuantity ? item.medicinalQuantity : 0,
                medComName: item.medComName ? item.medComName : item.medicinalName,
            })
        })
        let restaboo = await chineseMedsTaboo({ array1:arraymed }) 

        if(restaboo.data.twoBind && restaboo.data.twoBind.length > 0 && ischinese.length>0){
            let combinedStrings = restaboo.data.twoBind.map(item => {
              return `${item.medComNameb}与${item.med_com_name}；`;
            })
            alert('中药配伍禁忌',
              <div>
                <div style={{ textAlign:'left',fontSize:'14px'}}>
                  根据中药配伍禁忌十八反十九畏，以下药品有冲突：
                </div>
                <div style={{ color:'red',textAlign:'left',fontSize:'14px'}}>
                  {combinedStrings}
                </div>
              </div>,[
              {
                text: "取消",
                onPress: () => {},
              },
              {
                text: "继续提交",
                onPress: () => {
                    // 修改医生继续提交十八反十九畏
                    updateChineseDocSubmit({ consultationId:item.consultation_id}).then((res)=>{
                        console.log('修改成功');
                    })
                  this.submit()
                },
              },
            ])
        }else{
            this.submit()
        }
    }else{
        this.submit()
    }
    
  }

  //点击提交
  submit = async () => {
    const {
      caPassTime,
      doctortext,
      icdValue,
      updateId,
      icdlists,
      somegoods,
      medAmount,
      item,
      subcode,
      docterId,
      patientAge,
      patientSex,
      docterDiagnosis,
      detailContent0,
      subcode2,
      ischinese,
      chamedsmethod,
      visibleunit,
      tipcode,
      chinesemethodtwo,
      historyInfo
    } = this.state;

    let arr = [];
    let arr1 = [];
    if (subcode == 1) {

      let resChat = await docLine({
        consultationId:item.consultation_id,
        token1: localStorage.getItem("jwtTokendoc"),
      });
      let dataChat = resChat.data[2]
      let lastDataChat = dataChat[dataChat.length - 1].detailContent;

      let lastDataChatTime = dataChat[dataChat.length - 1].createTime;
      let lastChatTime =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
      let delayTime = moment(lastChatTime).add( 1 , 'seconds')
      let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

      let reDialogDataThree = await dialogueTemplate({dialogSequence:3})
      let isInResData = reDialogDataThree.data[0].some(item => item.template_content == lastDataChat); //查看是否存在是否补充

      if(isInResData){
          const resChatOne = await addnews({
              consultationId: item.consultation_id,
              Identity: 1,
              detailType: 1,
              detailContent: `没有补充`,
              createTime:chatTime
          });
      }

      if (icdlists.length) {
        icdlists.forEach((i) => {
          let params0 = {};
          if (i.state == 1) {
            params0.icdName = i.icdName;
            params0.icdValue = i.icdValue;
            arr.push(i.icdName);
            arr1.push(params0);
          }
        });
      } else {
        Toast.info("请先添加疾病诊断信息");
      }
      let array = [];
      for (let i = 0; i < somegoods.length; i++) {
        let params = {};
        params.medname = somegoods[i].medicinalName;
        params.medspec = somegoods[i].medicinalSpec;
        array.push(params);
        if ((!somegoods[i].medicinalFrequency || !somegoods[i].medicinalUsage) && ischinese.length == 0) {
          Toast.info("药品用法用量有误，请修改后再提交");
          return;
        }
        if(somegoods[i].medicinalUsage){
          let strnum = /^\d+$/.test(somegoods[i].medicinalUsage)
          if(strnum){
              Toast.info('药品用法用量有误，请修改后再提交')
              return;
          }
        }
      }

      if(ischinese.length){
        if(chinesemethodtwo[0].co_demand=="" || chinesemethodtwo[0].daily == "" || chinesemethodtwo[0].at_time== "" || chinesemethodtwo[0].each_dose == "" || chinesemethodtwo[0].unit ==""){
            Toast.info('请正确填写药品用法用量')
            return;
        }
        if(chinesemethodtwo[0].med_method == ""){
            Toast.info('请正确填写药品用药方式')
            return;
        }
        if(chinesemethodtwo[0].unit != 'ml' && tipcode == 0){
          this.setState({
            visibleunit:true
          })
          return
        }
      }

      // 判断病史信息是否完整
      if(historyInfo.past_history == 1 && !historyInfo.past_history_note){
        Toast.info('有无疾病史信息存在问题，请检查')
        return
      }else if(historyInfo.allergic_history == 1 && !historyInfo.allergic_history_note){
          Toast.info('有无过敏史信息存在问题，请检查')
          return
      }else if(historyInfo.bodytemperature == 1 && !historyInfo.bodytemperaturenum){
          Toast.info('体温信息存在问题，请检查')
          return
      }

      if (arr.length == 0) {
        Toast.info("请选择疾病诊断");
      } else {
        let res0 = await isCapwd({ docterId });
        if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
          if (res0.data[0][0].doc_ca_pwd) {
            this.setState({
              password: res0.data[0][0].doc_ca_pwd,
              passwordcode: 2,
            });
          }
        }
        if (detailContent0) {
          if (updateId) {
            alert(
              "确认修改处方？",
              "修改后原处方单失效，失效处方单将不计入单量统计中",
              [
                {
                  text: "取消",
                  onPress: () => {
                    this.setState({
                      updateId: 0,
                    });
                  },
                },
                {
                  text: "确认",
                  onPress: () => {
                    this.setState(
                      {
                        subcode: 2,
                      },
                      async () => {
                        let res = await docUpdate({
                          prescribingId: detailContent0,
                        });
                        if (
                          res &&
                          res.data &&
                          res.data[0] &&
                          res.data[0].length
                        ) {
                          if (res.data[0][0].prescribingState == 0) {
                            this.submitContinue();
                          } else {
                            Toast.info("原处方单已被药师审核，无法继续修改");
                            this.getnews();
                            this.scrollToBottom();
                          }
                        }
                      }
                    );
                  },
                },
              ]
            );
          } else {
            this.setState(
              {
                subcode: 2,
              },
              () => {
                this.submitContinue();
              }
            );
          }
        } else {
          let res000 = await getSamemeds({
            consultationId: item.consultation_id,
            array,
          });
          if (res000 && res000.data && res000.data.length > 0) {
            Toast.info("有相同药品的处方单不能重复提交");
          } else {
            this.setState(
              {
                subcode: 2,
              },
              () => {
                this.submitContinue();
              }
            );
          }
        }
      }
    } else {
      Toast.info("请勿重复提交");
    }
  };

  submitContinue = async () => {
    const {
      caPassTime,
      doctortext,
      icdValue,
      supervisionType,
      icdlists,
      somegoods,
      medAmount,
      item,
      subcode,
      patientName,
      patientAge,
      patientSex,
      priceArray,
      docterDiagnosis,
      detailContent0,
      docterProposal,
      docterProposal1,
      ischinese,
      chamedsmethod,
      chinesemethodtwo,
      somegoodssystem,
      historyInfo
    } = this.state;
    let arr = [];
    let arr1 = [];
    icdlists.forEach((i) => {
      let params0 = {};
      if (i.state == 1) {
        params0.icdName = i.icdName;
        params0.icdValue = i.icdValue;
        arr.push(i.icdName);
        arr1.push(params0);
      }
    });

    if(ischinese.length){
      // 修改订单信息
      for (let i = 0; i < somegoods.length; i++) {
          updateOrderinfo({
              medQuantity:typeof(somegoods[i].medicinalQuantity) == 'string' ? parseInt(somegoods[i].medicinalQuantity.slice(0, -1)) : somegoods[i].medicinalQuantity,
              medId:somegoods[i].medId,
              consultationId:item.consultation_id
          }).then((res)=>{
          }) 
      }
    }
    
    let medicationSafety = [];
    if (ischinese.length) {
      chinesemethodtwo.map((i) => {
          let atTime;
          if (i.med_time == 1) {
              atTime = '饭前用药';
          } else if (i.med_time == 2) {
              atTime = '饭后用药';
          } else if (i.med_time == 3) {
              atTime = '睡前用药';
          } else if (i.med_time == 4) {
              atTime = '晨起用药';
          }

          medicationSafety.push('用法用量：' + '共' + i.co_demand + '剂，' + i.meiday + i.daily + '剂，每剂分' + i.each_dose + '次使用，每次' + i.at_time + i.unit +'，' + i.med_method + '，' + atTime);
      })
    }else{
      somegoods.forEach((item, index) => {
        medicationSafety.push(
          index +
          1 +
          "、" +
          item.medComName +
          "(" +
          item.medicinalSpec +
          ")" +
          item.medicinalMethod +
          "，每次" +
          item.medicinalUsage +
          "，" +
          item.medicinalFrequency +
          " "
        );
      });

      // console.log('somegoodssystem',somegoodssystem);
      somegoodssystem.forEach((item) => {
        const matchingItem = somegoods.find((el) => el.medId == item.medId);
        if (matchingItem) {
          matchingItem.sysMedMethod = (item.canchange == 1) ? item.medicinalMethod : item.medicinalMethod;
          matchingItem.sysMedUsage = (item.canchange == 1) ? item.medicinalUsage : item.useQuantity;
          matchingItem.sysMedDay = (item.canchange == 1) ? item.medDayCountSys : item.useDay;
          matchingItem.sysMedFrequency = (item.canchange == 1) ? item.medicinalFrequency : item.usePl + item.useCount;
        } else {
          somegoods.push({ ...item, medId: item.medId });
        }
      });

    }    

    let price = priceArray;
    let res0 = await allIcd(item.consultation_id);
    if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
      let res = await saveIcd({
        consultationId: item.consultation_id,
        electronicIcd: JSON.stringify(arr1),
        type: 2,
      });
    } else {
      let res = await saveIcd({
        consultationId: item.consultation_id,
        electronicIcd: JSON.stringify(arr1),
        type: 1,
      });
    }
    let nArr1 = somegoods.filter((currentValue, currentIndex, selfArr) => {
      return (
        selfArr.findIndex((x) => x.medId === currentValue.medId) ===
        currentIndex
      );
    });
    let obj;
    if (ischinese.length){
      obj = {
        docterId: doctortext.docter_id,
        prescribingPrice: price,
        userId: item.user_id,
        patientName: item.patientName,
        medicalnsuranceCoordination: item.medical_nsurance_coordination,
        patientAge: item.patient_age,
        patientSex: item.patientSex,
        docterDepartment: doctortext.depName,
        patientIllness: item.consultation_detail,
        docterDiagnosis: arr.toString().replace(/\,/g, "|"),
        docterProposal: docterProposal ? docterProposal : docterProposal1,
        docterName: doctortext.docter_name,
        pharmacistName: "",
        originalPrescribing: "",
        eSeal: "",
        fatherId: detailContent0,
        pharmacyId: item.pharmacy_id,
        pharmacyName: item.pharmacy_name,
        consultationId: item.consultation_id,
        array: nArr1,
        contacts: nArr1[0].contacts,
        medAmount: medAmount,
        isRetail: 1,
        recipeType: supervisionType,
        icdValue,
        is_chinese_meds: 1,
        medicationSafety:medicationSafety.toString() +
          "。考虑用药安全:1、请您再次确认用过该药品且无不良反应。如未用过，请到实体医疗机构就诊;2、应严格按原处方和《药品说明书》使用(严格对照用法用量、不良反应、禁忌、注意事项和药物相互作用)。3、如病情发生变化，或用药期间如有不适，请立即停药并及时到当地医院就诊。",
        ischinese:1,
        co_demand : chinesemethodtwo[0].co_demand,
        at_time : chinesemethodtwo[0].at_time,
        daily : chinesemethodtwo[0].daily,
        each_dose : chinesemethodtwo[0].each_dose,
        med_method : chinesemethodtwo[0].med_method,
        med_time : chinesemethodtwo[0].med_time,
        meiday : chinesemethodtwo[0].meiday,
        unit : chinesemethodtwo[0].unit,
      };
    }else{
      obj = {
        docterId: doctortext.docter_id,
        prescribingPrice: price,
        userId: item.user_id,
        patientName: item.patientName,
        medicalnsuranceCoordination: item.medical_nsurance_coordination,
        patientAge: item.patient_age,
        patientSex: item.patientSex,
        docterDepartment: doctortext.depName,
        patientIllness: item.consultation_detail,
        docterDiagnosis: arr.toString().replace(/\,/g, "|"),
        docterProposal: docterProposal ? docterProposal : docterProposal1,
        docterName: doctortext.docter_name,
        pharmacistName: "",
        originalPrescribing: "",
        eSeal: "",
        fatherId: detailContent0,
        pharmacyId: item.pharmacy_id,
        pharmacyName: item.pharmacy_name,
        consultationId: item.consultation_id,
        array: nArr1,
        contacts: nArr1[0].contacts,
        medAmount: medAmount,
        isRetail: 1,
        recipeType: supervisionType,
        icdValue,
        medicationSafety:
        medicationSafety.toString() +
          "。考虑用药安全:1、请您再次确认用过该药品且无不良反应。如未用过，请到实体医疗机构就诊;2、应严格按原处方和《药品说明书》使用(严格对照用法用量、不良反应、禁忌、注意事项和药物相互作用)。3、如病情发生变化，或用药期间如有不适，请立即停药并及时到当地医院就诊。",
      }
    }

    // 医生修改患者病史信息
    let updateResHis = await updatePatientHistoryInfo({
      consultationId: item.consultation_id,
      past_history: historyInfo.past_history,  //是否存在疾病史
      past_history_note: historyInfo.past_history == '0' ? '' : historyInfo.past_history_note,    //疾病史内容
      allergic_history: historyInfo.allergic_history,   //是否存在过敏史
      allergic_history_note: historyInfo.allergic_history == '0' ? '' : historyInfo.allergic_history_note,   //过敏史内容
      abnormal_liver_function: historyInfo.abnormal_liver_function,   //肝肾功能是否异常
      bodytemperature: historyInfo.bodytemperature,   //体温是否正常
      bodytemperaturenum: historyInfo.bodytemperature == '0' ? '' : historyInfo.bodytemperaturenum,   //温度
    })

    if(updateResHis && updateResHis.data && updateResHis.data[1]){
        localStorage.setItem("item", JSON.stringify(updateResHis.data[1][0]))
    }

    let test = ischinese.length == 0 ? 1: 2

    let res = await submitPrescribing({
      consultationId: item.consultation_id,
      detailContent: arr.toString(),
      obj: obj,
    });

    if(typeof(res.data) == 'string'){
      let str = res.data.substring(0, 9)
      // console.log(str,'str');
      if(str == '[API执行引擎]'){
          Toast.info('当前药品信息有误，请联系技术人员处理后再提交')
          return 
      }
    }else if(res && res.code == 1) {
      localStorage.removeItem("docterDiagnosis");
      localStorage.removeItem("icdValue");
      localStorage.removeItem("docterProposal");
      localStorage.removeItem("somegoods");
      let prescribingId = "";
      if (res.data[1][0].daynum == 1) {
        prescribingId = res.data[4][0].prescribingId
      } else {
        prescribingId = res.data[1][0].prescribingId
      }
      if( test == 0 ){
        let resRecord = await recordUsage({
            consultationId: item.consultation_id,
            prescribingId:prescribingId,
            obj: obj
        })
      }
      this.click(prescribingId);
    }

    // }
  };
  //确定
  click = (prescribingId) => {
    const { passwordcode } = this.state;
    if (passwordcode == 1) {
      this.setState({
        visible002: true,
        updateId: 0,
        detailContent0: prescribingId,
      });
    } else {
      this.setState({
        detailContent0: prescribingId,
      });
      this.passwordSure();
    }
  };
  passwordValue = (e) => {
    this.setState({
      password: e.target.value.replace(/\s*/g, ""),
    });
  };
  //确认密码
  passwordSure = () => {
    const {
      docterId,
      detailContent0,
      item,
      password,
      knowcode,
      timer,
      consultationType,
      type,
      offcode,
      ischinese
    } = this.state;
    if (password) {
      this.setState(
        {
          visible002: false,
          flog: 2,
        },
        async () => {
          let params = {
            prescribingId: detailContent0,
            password: password,
            consultationId: item.consultation_id,
            docterId: docterId,
            knowcode: knowcode ? 1 : 0,
          };

            let res = await prescriptionSignature(params);
            if (res && res.data && res.data.result_msg) {
              if (res.data.result_msg == "云证书获取失败[PIN校验失败]") {
                this.setState(
                  {
                    flog: 1,
                  },
                  () => {
                    this.failPassword();
                  }
                );
              } else {
                Toast.info("签章失败，请重新签名后再进行审方");
              }
            } else {
              this.setState(
                {
                  visible12: false,
                  flog: 1,
                  subcode: 1,
                },
                () => {
                  this.props.history.push(`/offlineDoctorchat?prescription=${1}`);
                  this.allNews1();
                  this.getnews();
                  this.scrollToBottom();
                }
              );
            }

        }
      );
    } else {
      Toast.info("请先输入密码");
    }
  };
  //密码错误，ca验证失败
  failPassword = async () => {
    const { isRetail, docterId, prescribingId, phone, allmeds, ca_id } =
      this.state;
    alert("密码错误", "忘记密码可修改密码", [
      {
        text: "忘记密码",
        onPress: () => {
          let data0 = {
            entityId: ca_id && docterId != ca_id ? ca_id : docterId,
            busiType: "5",
            redirectUrl: baseUrl + "/faas/hosp/scca/callback",
            redirectPageUrl:
              jumpUrl +
              `/index.html#/prescribing/prescription?prescribingId=${prescribingId}&type=${1}`,
            province: "四川省",
            locality: "德阳市",
            orgName: "云益健互联网医院",
          };

          let { toSign, params } = dealParams(data0);
          // console.log(toSign, params);
          caAuthentication({
            url: caUrl,
            toSign,
            FormData: params,
          }).then((data) => {
            if (data && data.data && data.data.success == false) {
              Toast.info(data.data.result_msg);
            } else if (data && data.data) {
              window.location.href = data.data.body;
            }
          });
        },
      },
      {
        text: "重新输入",
        onPress: () => {
          this.setState({
            visible002: true,
            flog: 1,
          });
        },
      },
    ]);
  };

  imgFd = (index) => {
    const { clickFd } = this.state;
    if (clickFd == 0) {
      this.setState({
        clickFd: 1,
        index1: index,
      });
    } else {
      this.setState({
        clickFd: 0,
        index1: -1,
      });
    }
  };
  sxImg = () => {
    const { clickFd } = this.state;
    if (clickFd == 1) {
      this.setState({
        clickFd: 0,
        index1: -1,
      });
    }
  };
  // 结束问诊
  overwz = () => {
    alert("提示弹窗", "确定结束该患者的问诊吗？", [
      {
        text: "取消",
        onPress: () => {
          console.log("取消");
        },
      },
      {
        text: "确认",
        onPress: async () => {
          this.closebox(2);
        },
      },
    ]);
  };
  //取消问诊
  refusewz = async () => {
    const {FilingDaysTime,caPassTime,visibleCancel} = this.state
    if(FilingDaysTime<0){
      this.setState({
        visibledocter2:true
      })
      return
    }
    if(caPassTime<=0){
      Toast.show('您的身份信息已过期，请联系客服解决后使用')
      return
    }

    this.setState({
      visible10: true,
      // visibleCancel:true
    });
  };
  //视频问诊显示患者信息
  showNews = async () => {
    this.setState({
      visible11: true,
    });
  };
  sureRefuse = async () => {
    const { reason, consultationId, item, docterId, videofig,vedioTaskId,visibleCancel } = this.state;

    // 超量的问诊中药品的用法用量
    let cancelArrItems=localStorage.getItem("cancelArrItem")

    if (reason) {
      const res = await refusestatus({
        consultationStu: 3,
        consultationId,
        consultationReason: reason,
        cancelArrItems: cancelArrItems ? cancelArrItems : null
      });
      if (res && res.code == 1) {
        this.setState({
          visible10: false,
          visibleCancel:false,
          visible12:false
        });
        if (videofig == 1) {
          Toast.info("已结束视频");
          this.video.handleLeave(); //关闭视频流
          // 结束视频录制
          endVedioRecording({
            taskId:vedioTaskId
          }).then((data)=>{
            console.log('结束视频录制');
          })
          
          addvideoMessage({ consultation_id: item.consultation_id }).then(
            (res1) => {
              if (res1.code == 1) {
                this.getnews();
              }
            }
          );

          Toast.info("已结束视频");
          this.video.handleLeave(); //关闭视频流

          // 结束视频录制
          endVedioRecording({
            taskId:vedioTaskId
          }).then((data)=>{
          })

          let res = await savevideoTime({
            consultationId: item.consultation_id,
          });
          this.setState({
            videofig: 0,
            videocode: 1,
          });
        }
        let a = item;
        a.consultation_stu = "3";
        localStorage.setItem("item", JSON.stringify(a));
        Toast.info("取消成功");
        this.getnews();
        this.getnewsstatus();
      }
    } else {
      this.setState({
        visibleCancel:false
      })
      Toast.info("请选择取消问诊理由或者输入取消问诊理由");
    }
  };

  //输入病历，模糊查询
  changeuserinfo = (e, text) => {
    switch (text) {
      case 4:
        this.setState({
          // docterDiagnosis: e.target.value
          icdone: e.target.value,
        });

        break;
      case 5:
        if (
          e.target.value
            .replace(/\s*/g, "")
            .replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "")
            .length <= 60
        ) {
          this.setState(
            {
              docterProposal: e.target.value
                .replace(/\s*/g, "")
                .replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, ""),
            },
            () => {
              localStorage.setItem("docterProposal", this.state.docterProposal);
            }
          );
        } else {
          Toast.info("医生建议内容不能超过60字");
        }

        break;
    }
  };
  //搜索病历
  onSearch = async () => {
    const { page, size, icdone, icdList, icdlists } = this.state;
    const params = {
      content: icdone,
      page: 1,
      size: size,
    };
    let res = await getIcdCode(params);
    if (res && res.data && res.data[0]) {
      res.data[0].forEach((i) => {
        icdlists.forEach((j) => {
          if (i.id == j.id) {
            i.state = 1;
          }
        });
      });
      this.setState({
        icdList: res.data[0],
        page: 1,
        visible5: true,
      });
    }
  };
  // 开电子病历
  prescribingBl = async () => {
    // let res = await getIcdCode({})
    // if (res && res.data && res.data[0]) {
    //   this.setState({
    //     icdList: res.data[0]
    //   })
    // }
    this.setState({
      visible4: true,
    });
  };
  //分页搜索病历
  pageMedlist = async () => {
    const { page, size, icdList, icdone } = this.state;
    const params = {
      content: icdone,
      page: page + 1,
      size: size,
    };
    let res = await getIcdCode(params);
    if (res && res.data && res.data[0]) {
      this.setState({
        icdList: [...icdList, ...res.data[0]],
        page: page + 1,
      });
    }
  };
  onscroll = (e) => {
    if (
      e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight <
      10
    ) {
      this.pageMedlist();
    }
  };

  //确认接诊
  sureReceive = async () => {
    const { docterId, item } = this.state;
   
    alert("提示弹窗", "确定接诊？", [
      {
        text: "取消",
        onPress: () => {
          console.log("取消");
        },
      },
      {
        text: "确认",
        onPress: async () => {
          let res = await sureReceive({
            consultationId: item.consultation_id,
            docterId,
          });
          if (res && res.data && res.data[2]) {
            localStorage.setItem("item", JSON.stringify(res.data[2][0]));
            this.setState({ item: res.data[2][0] });
            this.getnewsstatus();
            this.getnews();
          } else {
            Toast.info("已被其他医生接诊，下次速度要快点哦");
            this.props.history.push("/");
          }
        },
      },
    ]);
  };
  tocall = (e, pharmacistPhone) => {
    e.stopPropagation();
    // window.location.href = `tel:${pharmacistPhone}`
    // // e.stopPropagation();
    // document.body.style.overflow = 'auto'
    this.setState({
      pharmacistPhone:
        pharmacistPhone == "15828530228" ? "13880769277" : pharmacistPhone,
      visible9: true,
    });
  };
  //修改处方
  updateInfo = async (detailContent) => {
    const { timer, item, list, becomeduestate,caPassTime,FilingDaysTime,ischinese,chamedsmethod } = this.state;

    if(FilingDaysTime<0){
      this.setState({
        visibledocter2:true
      })
      return
    }
    if(caPassTime<=0){
      Toast.show('您的身份信息已过期，请联系客服解决后使用')
      return
    }

    // if(ischinese.length){
    //   let res11 = await chineseMethod({ consultation_id: item.consultation_id })
    //   console.log(res11,'res11');
    //   if(res11.code == 1 && res11.data[0]){
    //       this.setState({
    //         chamedsmethod: res11.data[0][0]
    //       })
    //   }    
    // }

    if (detailContent) {
      window.clearTimeout(timer);
      if (item.consultation_type == "1") {
        this.props.history.push(
          `/prescribing/offaddcf?detailContent=${detailContent}&updateId=${1}`
        );
      } else {
        this.setState(
          {
            detailContent0: detailContent,
            updateId: 1,
            subcode: 1,
          },
          () => {
            this.prescribingCf(detailContent);
          }
        );
      }
    }
  };
  copyDanhao = () => {
    const spanText = document.getElementById("dingdanhao").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
    this.setState({ visible9: false });
  };
  closev5 = (e) => {
    e.stopPropagation();
    this.setState({
      visible5: false,
    });
  };
  showV4 = (medId) => {
    this.setState({ visible04: true, medId: medId });
  };
  showV1 = (medId) => {
    this.setState({ visible01: true, medId: medId });
  };
  showV01 = (medId) => {
    this.setState({ visible00: true, medId: medId });
  };
  showV2 = (medId) => {
    this.setState({ visible02: true, medId: medId });
  };
  modifyingDrugs = () => {
    const { somegoods, updatesomegoods } = this.state;
    let array = [];
    somegoods.forEach((item) => {
      let params = {};
      params.medId = item.medId;
      params.medicinalQuantity = item.medicinalQuantity;
      array.push(params);
    });
    this.setState({ visible07: true, updatesomegoods: array });
    localStorage.setItem("stagingsomegoods", JSON.stringify(somegoods));
  };
  toDelete = (medId) => {
    const { somegoods, updatesomegoods } = this.state;
    let array = [];
    if (somegoods.length > 1) {
      alert("弹窗提示", "删除药品后不能恢复，请谨慎修改", [
        {
          text: "取消",
          onPress: () => {
            console.log("取消");
          },
        },
        {
          text: "确认",
          onPress: () => {
            somegoods.forEach((item) => {
              if (item.medId != medId) {
                array.push(item);
              }
            });
            this.setState({
              somegoods: array,
            });
          },
        },
      ]);
    } else {
      Toast.info("药品无法继续删除");
    }
  };
  reduceCount = (medId, count) => {
    const { somegoods, updatesomegoods } = this.state;
    if (count == 1) {
      let array = [];
      if (somegoods.length > 1) {
        alert("弹窗提示", "删除药品后不能恢复，请谨慎修改", [
          {
            text: "取消",
            onPress: () => {
              console.log("取消");
            },
          },
          {
            text: "确认",
            onPress: () => {
              somegoods.forEach((item) => {
                if (item.medId != medId) {
                  array.push(item);
                }
              });
              this.setState({
                somegoods: array,
              });
            },
          },
        ]);
      } else {
        Toast.info("药品无法继续减少");
      }
    } else {
      somegoods.forEach((item) => {
        if (item.medId == medId) {
          item.medicinalQuantity = count - 1;
        }
      });
      this.setState({
        somegoods: somegoods,
      });
    }
  };
  addCount = (medId, count) => {
    const { somegoods } = this.state;
    somegoods.forEach((item) => {
      if (item.medId == medId) {
        item.medicinalQuantity = count + 1;
      }
    });
    this.setState({
      somegoods,
    });
  };
  toRefuse = () => {
    const { somegoods, updatesomegoods } = this.state;
    this.setState({
      visible07: false,
      somegoods: JSON.parse(localStorage.getItem("stagingsomegoods")),
    });
  };
  toSureupdate = () => {
    const { updatesomegoods } = this.state;
    this.setState({
      visible07: false,
    });
  };
  // 复制客服电话
  copykefuhone = () => {
    const spanText = document.getElementById("docterPhone").innerText;
    const oInput = document.createElement("input");
    oInput.value = spanText;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    document.body.removeChild(oInput);
    Toast.info("复制成功");
  };
  hanldSure = () =>{
    const { visible13 } = this.state
    this.setState({
      visible13:false
    })
    this.props.history.replace('/')
  }
  // 执业到期提醒关闭
  handleCloseAlert2=()=>{
    // 获取当前时间的年月日
    let currentDate = new Date().toLocaleDateString();
    this.setState({
      visibledocter2:false,
    })
  }
  handleInputChange = async (e,text) => { 
    const {chamedsmethod,chinesemethodtwo} = this.state
    switch(text){
        case 'allarr':
            if (e.target.value) {
              chinesemethodtwo[0].co_demand = e.target.value.replace(/\s*/g, "")
            } else {
              chinesemethodtwo[0].co_demand = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;
        case 'dayarr':
            if (e.target.value) {
              chinesemethodtwo[0].daily = e.target.value.replace(/\s*/g, "")
            } else {
              chinesemethodtwo[0].daily = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;
        case 'mlarr1':
            if (e.target.value) {
              chinesemethodtwo[0].each_dose = e.target.value.replace(/\s*/g, "")
            } else {
              chinesemethodtwo[0].each_dose = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;
        case 'mlarr2':
            if (e.target.value) {
              chinesemethodtwo[0].at_time = e.target.value.replace(/\s*/g, "")
            } else {
              chinesemethodtwo[0].at_time = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;
        case 'units':
            if (e) {
              chinesemethodtwo[0].unit = e.replace(/\s*/g, "")
            } else {
              chinesemethodtwo[0].unit = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;
        case 'meidays':
            if (e) {
              chinesemethodtwo[0].meiday = e.replace(/\s*/g, "")
            } else {
              chinesemethodtwo[0].meiday = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;
        case 'radio':
            if (e) {
              chinesemethodtwo[0].med_time = e
            } else {
              chinesemethodtwo[0].med_time = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;   
        case 'methodarr':
            if (e) {
              chinesemethodtwo[0].med_method = e.target.value
            } else {
              chinesemethodtwo[0].med_method = ''
            }
            this.setState({
              chinesemethodtwo
            })
            break;          
    }
  }

  clickMethod = async (e)=> {
      const {chamedsmethod,chinesemethodtwo} = this.state
      if (e && e !=3) {
        chinesemethodtwo[0].med_method = e
          
      } else {
        chinesemethodtwo[0].med_method = ''
      }
      this.setState({
        chinesemethodtwo
      })
  }

  /**
   * 用户中药用药单位自定义
   * 用药单位自填
   * @param {String} title 输入框标题
   * @param {String} stateName 修改的状态名称
   * @param {Array} stateData 修改状态的数据
   */
  useMedicineAndUnitcha = (title, stateName, stateData) => {
      const { chamedsmethod,unitss } = this.state
      this.setState(
      {
          chamedsmethod
      },
      () => {
          prompt(title, '请输入用药单位', [
          { text: '取消' },
          {
              text: '确认',
              onPress: value => {
                  if (value.replace(/\s*/g, "") == '') {
                      Toast.show('请输入正确的单位');
                      return;
                  }
                  if(value != "ml"){
                      this.setState({
                          unitss:value,
                          visibleunit:true
                      })
                  }
                  let newData = stateData;
                  newData.unshift(value);
                  this.setState(
                      {
                          [stateName]: newData,
                          selectedUnit: value.replace(/\s/g, "") // 将输入的值设置为 selectedUnit
                      },() => {
                              this.inputRef.value = this.state.selectedUnit; // 设置 input 框的值
                              this.getvalue(this.state.selectedUnit, index, 'units'); // 触发一次 onChange 事件更新 selectedUnit 的值
                          }
                  );
                  if(value){
                      chamedsmethod[0].unit = value
                  }
                  this.setState({
                      chamedsmethod
                  })
              }
          }
          ]);
      }
      );
  };

  // 确定用法用量弹窗
  sureUnits=()=>{
      this.setState({
        visibleunit:false,
        tipcode:1,//1表示点了确定，0表示未点
      })
  }
  // 关闭自填用药弹窗
  handleSure=(e)=>{
      const { units,unitss } = this.state
      if(e !== 'ml'){
          this.setState({
              unitss:e,
              visibleunit:true,
          })
      }
      this.setState({
          visible10:false
      })
  }

  // 关闭超量提示弹窗
  handleExcessSure =()=>{
    const { visibleExcess } = this.state
    this.setState({
        visibleExcess:false
    })
  }

  // 视频取消本单原因
  // getCancelReason = (e) => {
  //   this.setState({
  //     cancelReason: e.target.value.replace(/\s*/g, ""),
  //   });
  // };
  // sureCancelRefuse = async () => {
  //     const { cancelReason, item, docterId, videofig,vedioTaskId } = this.state;
  //     if (cancelReason) {
  //       const res = await refusestatus({
  //         consultationStu: 3,
  //         consultationId:item.consultation_id,
  //         consultationReason: cancelReason,
  //       });
  //       if (res && res.code == 1) {
  //         this.setState({
  //           visibleCancel: false,
  //         });
  //         Toast.info("取消成功");
  //         this.props.history.replace("/");
  //       }
  //     } else {
  //       Toast.info("请输入取消问诊理由");
  //     }
  // };

  // 修改病史信息
  setvalue = (flag, e) =>{
    const { historyInfo } = this.state
    let updatedHistoryInfo = {...historyInfo}
    switch (flag) {
        // 疾病史
        case "疾病史有":
            updatedHistoryInfo.past_history = 1
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "疾病史无":
            updatedHistoryInfo.past_history = 0
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "疾病史内容":
            if (e.target.value.replace(/\s*/g, "")) {
                updatedHistoryInfo.past_history_note = e.target.value.replace(/\s*/g, "")
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
            }else{
                updatedHistoryInfo.past_history_note = ''
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
            }
            break;
        
        // 过敏史
        case "过敏史有":
            updatedHistoryInfo.allergic_history = 1
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "过敏史无":
            updatedHistoryInfo.allergic_history = 0
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "过敏史内容":
            if (e.target.value.replace(/\s*/g, "")) {
                updatedHistoryInfo.allergic_history_note = e.target.value.replace(/\s*/g, "")
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
            }else{
                updatedHistoryInfo.allergic_history_note = ''
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
            }
            break;

        // 肝肾功能
        case "肝肾功能有异常":
            updatedHistoryInfo.abnormal_liver_function = 1
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "肝肾功能无异常":
            updatedHistoryInfo.abnormal_liver_function = 0
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        
        // 体温
        case "体温有异常":
            updatedHistoryInfo.bodytemperature = 1
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "体温无异常":
            updatedHistoryInfo.bodytemperature = 0
            this.setState({
                historyInfo: updatedHistoryInfo
            });
            break;
        case "体温内容":
            // 去除空格
            let bodytempnum = e.target.value.replace(/\s*/g, "");
            // 只能输入数字和小数点
            const bodytempnumSaniti = bodytempnum.replace(/[^0-9.]/g, '')
            if (bodytempnum.replace(/\s*/g, "")) {
                updatedHistoryInfo.bodytemperaturenum = bodytempnumSaniti
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
            }else{
                updatedHistoryInfo.bodytemperaturenum = ""
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
            }
            break;
    }
  }

  // 取消本单选择的值
  handleChangeRadio = (e) =>{
    const { otherReasonVis,reason } = this.state
    if(e != '其他'){
        this.setState({
            reason: e,
            otherReasonVis:false
        });
    }else{
        // 否则就需要弹出取消输入文本框
        this.setState({
            otherReasonVis: true,
            reason:''
        })
    }  
  } 


  render() {
    const {
      medsNews,
      visible6,
      visible06,
      visible9,
      flog,
      image,
      knowcode,
      password,
      icdone,
      reason,
      details,
      pharmacistPhone,
      visible00,
      visible01,
      danwei,
      i,
      visible02,
      count,
      k,
      visible04,
      useway,
      m,
      j,
      visible7,
      visible07,
      originalPrescribing,
      visible,
      docterId,
      visible03,
      visible12,
      supervisionType,
      index1,
      somegoods,
      docterProposal1,
      docterProposal,
      index01,
      useTime,
      videoTime,
      videofig,
      item,
      videocode,
      duration,
      docterAutograph,
      docterAutograph1,
      detail,
      list,
      day,
      list3,
      year,
      month,
      visible2,
      visible002,
      visible4,
      visible5,
      qxcode,
      icdList,
      visible8,
      fileList,
      visible10,
      visible11,
      detailContent,
      doctorData,
      codestate,
      icdlists,
      retirement,
      ischinese,
      chinesemethod,
      pharmacist_img,
      pharmacist_img1,
      staff_img,
      visible13,
      isPhaExamine,
      isPhaBohui,
      visibledocter2,
      FilingDaysTime,
      caPassTime,
      chamedsmethod,
      pickerVisible,
      meidayss,
      unit,
      visibleunit,
      unitss,
      chinesemethodtwo,
      vedioTaskId,
      excessArr,
      visibleExcess,
      visibleCancel,
      isSumbit,
      docterAutograph3,
      phaStaffIdStoreImg,
      phaStaffIdStore,
      historyInfo,
      radioList,
      otherReasonVis
    } = this.state;

    if(somegoods){
      somegoods.forEach(itemA => {
          excessArr.forEach(itemExcess => {
              if (itemA.medComName == itemExcess.medComName) {
                  itemExcess.medicinalQuantity = itemA.medicinalQuantity
              }
          });
      });
    }
    
    const isExcessArrList = excessArr.map(item => `${item.medComName}最多${item.maxDataLargeSplit}${item.packingUnit}，当前${ item.medicinalQuantity? item.medicinalQuantity : ''}${item.packingUnit}`).join('、');
// console.log(visibleExcess,visible12,isExcessArrList);
    return (
      <>

        {item != null ? (
          <div
            className={
              visible6 ? "offlineDocbox offlineDocbox1" : "offlineDocbox"
            }
            onClick={this.sxImg}
          >
            {item.consultation_stu == "0" ? (
              <div className="top-time-over">
                <p className="top-time-over-left">已问诊：{useTime}</p>
                {codestate == 2 ? (
                  <p
                    onClick={() => this.overwz()}
                    className="top-time-over-right"
                  >
                    {/*<img className='four' src={icon9}/>*/}
                    <i className="iconfont icon-jieshu1" />
                    <span>结束问诊</span>
                  </p>
                ) : null}
              </div>
            ) : (
              <div className="top-time-over">
                <p className="top-time-over-left">已问诊：{useTime}</p>
                <p></p>
              </div>
            )}

            {item.consultation_type == "3" && duration == 0 && videofig ? (
              <div
                className="video-inner"
                onClick={() =>
                  this.setState({
                    videocode: videocode == 1 && videofig == 1 ? 2 : 1,
                  })
                }
              >
                {videocode == 2 ? (
                  <div
                    className="over-video"
                    onClick={(e) => {
                      e.stopPropagation();
                      this.video.handleLeave(); //关闭视频流
                      endVedioRecording({
                        taskId:vedioTaskId
                      }).then((data)=>{
                        console.log('结束视频录制');
                      })
                      this.setState(
                        {
                          videofig: 0,
                          videocode: 1,
                        },
                        async () => {
                          let res = await savevideoTime({
                            consultationId: item.consultation_id,
                          });
                          // 结束视频录制
                          endVedioRecording({
                            taskId:vedioTaskId
                          }).then((data)=>{
                            console.log('结束视频录制');
                          })

                          Toast.info("已结束视频");
                          this.getnewsstatus();
                        }
                      );
                    }}
                  >
                    <span>{videoTime}</span>
                    <i className="iconfont icon-guaduandianhua" />
                  </div>
                ) : null}
                <VideoForReact
                  ref={this.initDoctor}
                  config={{
                    roomId: item.consultation_id, //房间号，两端相同(问诊单号）
                    // userId: "doc" + docterId, //userid 两端不能相同(医生id)
                    userId: docterId, //userid 两端不能相同(医生id)
                    ...doctorData,
                    showLocal: true, //是否显示自己视频
                    showRemote: true, //是否显示对方视频
                    showDevice: true, //是否显示设备切换按钮
                    showCamera: true, //是否显示摄像头切换按钮 ，showDevice为true时有效
                    showMic: false, //是否显示麦克风切换按钮，showDevice为true时有效
                  }}
                  customStyle={{
                    width: "100vw", //整个video的宽 单位px 默认300px
                    height: videocode == 1 ? "150px" : "100vh", //整个video的高单位px 默认300px
                    localStreamWidth: videocode == 1 ? "50vw" : "120px", //本地流宽度px 默认80px
                    localStreamHeight: "150px", //本地流高度px 默认80px
                    remoteStreamWidth: videocode == 1 ? "50vw" : "100vw", //远端流宽度px 默认300px
                    remoteStreamHeight: videocode == 1 ? "150px" : "100vh", //远端流高度px 默认300px
                    mode: videocode == 1 ? "symmetry-R" : "around", ////around----常规   symmetry-横向， vertical-上下 vertical和symmetry时，不能展示设备切换按钮 默认aroud
                  }}
                  remoteStatus={(status) => this.handleWatchStatus(status)}
                  localStatus={(status) => this.handleWatchLocalStatus(status)}
                  clientStatus={(status) =>
                    this.handleWatchClientStatus(status)
                  }
                  otherStatus={(status) => this.handleWatchOtherStatus(status)}
                ></VideoForReact>
              </div>
            ) : item.consultation_type == "3" && duration ? (
              <p className="video-duration">
                <i className="iconfont icon-shipinbofang" />
                <span>视频通话已结束，通话时长 {duration}</span>
              </p>
            ) : null}
            <div
              className={
                item.consultation_type == "1" ||
                  (item.consultation_type == "3" && !videofig)
                  ? "offlineDoc-container"
                  : "offlineDoc-container offlineDoc-container1"
              }
              ref={(el) => {
                this.messagesEnd = el;
              }}
            >
              {item ? (
                <div className="offlineDoc-container-dan">
                  <p className="offlineDoc-top">
                    <p className="offlineDoc-top-left">
                      <img src={icon4} />
                      <span>问诊信息</span>
                    </p>
                    <p className="offlineDoc-top-right">
                      <span className="name">{item?.patientName}</span>
                      <span className="sex1">
                        {item?.patientSex == 1 ? "男" : "女"}
                      </span>
                      <span className="sex1">{item?.patient_age}</span>
                    </p>
                  </p>
                  <p className="offlineDoc-sure">
                    患者已确认本次为复诊，并使用过该药品，且无相关禁忌和不良反应
                  </p>
                  <p className="offlineDoc-body">
                    <span className="ill-one">
                      疾病史：
                      <span
                        className={item.past_history == 1 ? "blue" : "blue1"}
                      >
                        {item.past_history_note}
                      </span>
                    </span>
                    <span className="ill-one">
                      过敏史：
                      <span
                        className={
                          item.allergic_history == 1 ? "blue" : "blue1"
                        }
                      >
                        {item.allergic_history_note}
                      </span>
                    </span>
                  </p>
                  <p className="offlineDoc-body">
                    <span className="ill-one">
                      肝肾功能：
                      <span
                        className={
                          item.abnormal_liver_function == 0 ? "blue1" : "blue"
                        }
                      >
                        {item.abnormal_liver_function == 0 ? "无异常" : "异常"}
                      </span>
                    </span>

                    <span className="ill-one">
                      {item.patientSex == 2 ? (
                        <p>
                          怀孕情况：
                          <span className="blue1">
                            {item.pregnancy_status == 1
                              ? "未怀孕"
                              : item.pregnancy_status == 2
                                ? "备孕中"
                                : item.pregnancy_status == 3
                                  ? "已怀孕"
                                  : item.pregnancy_status == 4
                                    ? "哺乳期"
                                    : ''}
                          </span>
                        </p>

                      ) : null}
                    </span>
                  </p>
                  {/* 体温、体重 */}
                  <p className="offlineDoc-body">
                    <span className="ill-one">
                      体温：
                      <span
                        className={item.bodytemperature == 1 && item.bodytemperaturenum && (item.bodytemperaturenum < 36 || item.bodytemperaturenum > 37)  ? "redcolor" : "blue1"}
                      >
                        {item.bodytemperature == 1 && item.bodytemperaturenum ? (item.bodytemperaturenum + '℃') : '正常'}
                      </span>
                    </span>
                    {item.patient_age < 7 ?
                      <span className="ill-one">
                        体重：
                        <span
                          className="blue1"
                        >
                          {item.bodyweight + 'KG'}
                        </span>
                      </span> : null}
                  </p>

                  {retirement != 0 ? (
                    <p className="offlineDoc-body1">
                      身份证号：
                      <span
                        className={item.patientIdcardNum ? "blue" : "blue1"}
                      >
                        {item.patientIdcardNum ? "有" : "无"}
                      </span>
                    </p>
                  ) : (
                    <p className="offlineDoc-body">
                      <span className="ill-one">
                        是否医保统筹：
                        <span
                          className={
                            item.medical_nsurance_coordination == 1
                              ? "blue1"
                              : "blue"
                          }
                        >
                          {item.medical_nsurance_coordination == 1
                            ? "是"
                            : "否"}
                        </span>
                      </span>
                      <span className="ill-one">
                        身份证号：
                        <span
                          className={item.patientIdcardNum ? "blue1" : "blue"}
                        >
                          {item.patientIdcardNum ? "有" : "无"}
                        </span>
                      </span>
                    </p>
                  )}
                  <p className="line"></p>
                  
                  
                  {retirement != 0 ? null : (
                    <p className="offlineDoc-meds">
                      {ischinese.length ? (
                        <>
                          <p className="off-chameds">
                            <span>中药需求：</span>
                            <p className="meds-onenews">
                              {/* {medsNews.length
                                ? medsNews.map((item) => <p>{item}</p>)
                                : null} */}
                              {medsNews.length ? medsNews.map(i=> (
                                    <>
                                      {i.superType !== '' && <span className="classfi-tip">({i.superType})</span>}
                                      <span>{i.namespace}；</span>
                                    </>
                              )) : null}
                            </p>
                          </p>
                          <p className="off-chameds">
                            <span>用法用量：</span>
                            <p className="meds-onenews">
                              {chinesemethodtwo.filter(i => i.consultation_id == item.consultation_id).map(i=>(
                                <span>
                                共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                每次{i.at_time}{i.unit}，{i.med_method}，
                                {i.med_time == 1
                                  ? "饭前用药"
                                  : i.med_time == 2
                                  ? "饭后用药"
                                  : i.med_time == 3
                                  ? "睡前用药"
                                  : i.med_time == 4
                                  ? "晨起用药"
                                  : ""}
                              </span>
                              ))}                      
                            </p>
                          </p>
                          
                        </>
                      ) : (
                        <>
                          <p className="off-chameds">
                            <span>药品需求：</span>
                            <p className="meds-onenews">
                              {/* {medsNews.length
                                ? medsNews.map((item) => <p>{item}</p>)
                                : null} */}
                              {medsNews.length ? medsNews.map(i=> (
                                    <>
                                      {i.superType !== '' && <span className="classfi-tip">({i.superType})</span>}
                                      <span>{i.namespace}；</span>
                                    </>
                              )) : null}
                            </p>
                          </p>
                        </>
                      )}
                    </p>
                  )}
                  {retirement != 0 ? null : (
                    <p className="offlineDoc-meds">
                      <p className="off-chameds">
                        <span>病情描述：</span>
                        <p className="off-miaosu">
                          {item?.consultation_detail}
                        </p>
                      </p>
                      
                    </p>
                  )}
                </div>
              ) : null}
              {list.length ? (
                <div
                  className={
                    list[0].Identity == "1"
                      ? "offlineDoc-right"
                      : "offlineDoc-left"
                  }
                >
                  {list[0].Identity == "1" ? (
                    <img src={icon5} alt="" />
                  ) : (
                    <img
                      src={
                        item.docter_avatar && item.docter_avatar != "null"
                          ? item.docter_avatar
                          : icon2
                      }
                      alt=""
                    />
                  )}
                  <div
                    className={
                      list[0].Identity == "1" ? "right-text" : "left-text"
                    }
                    style={{ wordWrap: "break-word" }}
                  >
                    {list[0].detailContent}
                  </div>
                </div>
              ) : null}
              {list.length
                ? list.map((item1, index) => {
                  return (
                    <>
                      {index > 0 ? (
                        <div
                          className={
                            item1.Identity == "1"
                              ? "offlineDoc-right"
                              : "offlineDoc-left"
                          }
                        >
                          {item1.Identity == "1" ? (
                            <img src={icon5} alt="" />
                          ) : item1.detailType != "3" ? (
                            <img
                              src={
                                item1.docter_avatar
                                  ? item1.docter_avatar
                                  : this.state.docterAvatar
                              }
                              alt=""
                            />
                          ) : null}
                          {item1.detailType == "1" ? (
                            <div
                              className={
                                item1.Identity == "1"
                                  ? "right-text"
                                  : "left-text"
                              }
                              style={{ wordWrap: "break-word" }}
                            >
                              {item1.detailContent}
                            </div>
                          ) : item1.detailType == "2" ? (
                            <div
                              className={
                                item1.Identity == "1"
                                  ? "right-text1"
                                  : "left-text1"
                              }
                            >
                              <img
                                src={item1.detailContent}
                                alt=""
                                onClick={() =>
                                  this.setState({
                                    image: {
                                      url: item1.detailContent,
                                      visible: true,
                                    },
                                  })
                                }
                              />
                            </div>
                          ) : item1.detailType == "3" ? (
                            <div className="offcontainer-cfd">
                              <div className="offdan-left">
                                <div className="left-top">
                                  <img src={icon4} />

                                  {isPhaExamine ? (
                                    <>
                                      {item1.detail &&
                                        (item1.detail.prescribing_state == -1 || item1.detail.prescring_pha_state == 2) ? (
                                        <p style={{ color: "#FF4041" }}>
                                          药师审核失败
                                        </p>
                                      ) : (
                                        <p>电子处方</p>
                                      )}
                                      {item1.detail.prescribing_mark &&
                                        item1.detail.prescribing_state == 0 ? (
                                        <span style={{ marginLeft: "95px" }}>
                                          处方失效，处方已被修改
                                        </span>
                                      ) : item1.detail.original_prescribing ==
                                        "" ? (
                                        <span>处方未生效，请提交输入密码</span>
                                      ) : item1.detail.original_prescribing &&
                                        !item1.detail.prescribing_mark &&
                                        ((item1.detail.prescribing_state == 0 || item1.detail.prescribing_state == 1) && item1.detail.prescring_pha_state == 0) ? (
                                        <span
                                          style={{
                                            marginLeft: "160px",
                                            color: "#ff7800",
                                          }}
                                        >
                                          处方审核中
                                        </span>
                                      ) : null}
                                    </>
                                  ) : (
                                    <>
                                      {item1.detail &&
                                        item1.detail.prescribing_state == -1 ? (
                                        <p style={{ color: "#FF4041" }}>
                                          药师审核失败
                                        </p>
                                      ) : (
                                        <p>电子处方</p>
                                      )}
                                      {item1.detail.prescribing_mark &&
                                        item1.detail.prescribing_state == 0 ? (
                                        <span style={{ marginLeft: "95px" }}>
                                          处方失效，处方已被修改
                                        </span>
                                      ) : item1.detail.original_prescribing ==
                                        "" ? (
                                        <span>处方未生效，请提交输入密码</span>
                                      ) : item1.detail.original_prescribing &&
                                        !item1.detail.prescribing_mark &&
                                        item1.detail.prescribing_state == 0 ? (
                                        <span
                                          style={{
                                            marginLeft: "160px",
                                            color: "#ff7800",
                                          }}
                                        >
                                          处方审核中
                                        </span>
                                      ) : null}
                                    </>
                                  )}

                                </div>

                                {isPhaExamine ? (
                                  <> 
                                    {item1.detail && item1.detail.prescribing_state == 1 && item1.detail.prescring_pha_state == 1 ? (
                                      <img className="biao" src={icon12} />
                                    ) : item1.detail && item1.detail.prescribing_state == -1 ? (
                                      <p className="call-pha">
                                        <a
                                          onClick={(e) =>
                                            this.tocall(
                                              e,
                                              item1.detail.pharmacistPhone
                                            )
                                          }
                                        >
                                          联系药师
                                        </a>
                                      </p>
                                    ) : item1.detail && item1.detail.prescring_pha_state == 2 ? 
                                    (
                                      <p className="call-pha">
                                        <a
                                          onClick={(e) =>
                                            this.tocall(
                                              e,
                                              item1.detail.pharmacistPhone
                                            )
                                          }
                                        >
                                          联系药师
                                        </a>
                                      </p>
                                    ) : null}
                                  </>
                                ) : (
                                  <>
                                    {item1.detail &&
                                      item1.detail.prescribing_state == 1 ? (
                                      <img className="biao" src={icon12} />
                                    ) : item1.detail &&
                                      item1.detail.prescribing_state == -1 ? (
                                      <p className="call-pha">
                                        <a
                                          onClick={(e) =>
                                            this.tocall(
                                              e,
                                              item1.detail.pharmacistPhone
                                            )
                                          }
                                        >
                                          联系药师
                                        </a>
                                      </p>
                                    ) : null}
                                  </>
                                )}

                                {/*</div>*/}
                              </div>
                              {item1.detail?.prescribing_state == -1 || item1.detail.prescring_pha_state == 2 ? (
                                <>
                                  {isPhaExamine ? (
                                    <>
                                      <p className="no-tips">
                                        备注：{item1.detail.prescribing_state == -1 ? <>{item1.detail.reason}</> : <>药店药师驳回：{item1.detail.pha_refu_reson}</>}
                                      </p>
                                      {item1.detail.pha_refu_reson ? null : (
                                        <>
                                          {item.consultation_stu == "0" ? (
                                            <div className="left-title left-title1">
                                              {!item1.detail.prescribing_mark ? (
                                                <button
                                                  onClick={() =>
                                                    this.prescribingCf(
                                                      item1.detailContent
                                                    )
                                                  }
                                                >
                                                  重新开方
                                                </button>
                                              ) : (
                                                <button>已重新上传</button>
                                              )}
                                            </div>  
                                          ) : null}
                                        </>
                                      )}
                                     
                                      
                                    </>
                                  ) : (
                                    <>
                                      <p className="no-tips">
                                        备注：{item1.detail.reason}
                                      </p>
                                      {item.consultation_stu == "0" ? (
                                        <div className="left-title left-title1">
                                          {!item1.detail.prescribing_mark ? (
                                            <button
                                              onClick={() =>
                                                this.prescribingCf(
                                                  item1.detailContent
                                                )
                                              }
                                            >
                                              重新开方
                                            </button>
                                          ) : (
                                            <button>已重新上传</button>
                                          )}
                                        </div>
                                      ) : null}
                                    </>
                                  )}
                                  
                                </>
                              ) : (
                                <>
                                  <p className="offuser">
                                    患者：{item?.patientName}（
                                    {item?.patientSex == 1 ? "男" : "女"}{" "}
                                    {item?.patient_age}岁）
                                  </p>
                                  <p className="offuser">
                                    <p>
                                      诊断： {item1.detail?.docter_diagnosis}
                                    </p>
                                    <p>
                                      开方时间：{item1.detail?.create_time}
                                    </p>
                                  </p>
                                  <div className="left-title">
                                    {item1.detail?.doc_edit != 1 &&
                                      item1.detail?.prescribing_state == 0 &&
                                      !item1.detail?.prescribing_mark ? (
                                      <button
                                        className="doc-edit"
                                        onClick={() =>
                                          this.updateInfo(item1.detailContent)
                                        }
                                      >
                                        修改处方
                                      </button>
                                    ) : null}
                                    <button
                                      onClick={(v) =>
                                        this.toinfo(
                                          item1.detailContent,
                                          item1.detail.doc_edit
                                        )
                                      }
                                    >
                                      查看详情
                                    </button>
                                  </div>
                                </>
                              )}
                            </div>
                          ) : item1.detailType == "4" ? (
                            <div className="right-text2">
                              <p>
                                <i className="iconfont icon-shipinbofang" />
                                <span>视频已挂断，通话时长 {duration}</span>
                              </p>
                            </div>
                          ) : null}
                          {/*</div>*/}
                        </div>
                      ) : null}
                    </>
                  );
                })
                : null}


              

              {isPhaBohui ? null : (
                <>
                  {item.consultation_stu == "0" && visible6 ? (
                    <div className="offbox-bottom">
                      <input
                        type="text"
                        id="inputword"
                        placeholder="输入信息"
                        value={detailContent}
                        ref={this.inputRef}
                        onChange={(e) => this.changevalue(e)}
                      />
                      <p>
                        <img
                          src={icon1}
                          onClick={() => this.setState({ visible6: !visible6 })}
                        />
                      </p>

                      <button onClick={this.sendout}>发送</button>
                    </div>
                  ) : item.consultation_stu == "0" && !visible6 ? (
                    <div className="offbox-bottom1">
                      <p className="offboc-bottom-top">
                        <input
                          type="text"
                          placeholder="输入信息"
                          value={detailContent}
                          id="inputword"
                          ref={this.inputRef}
                          onChange={(e) => this.changevalue(e)}
                        />
                        <p>
                          <img
                            src={icon1}
                            onClick={() => this.setState({ visible6: !visible6 })}
                          />
                        </p>
                        <button onClick={this.sendout}>发送</button>
                      </p>
                      <p className="line"></p>
                      <p className="offboc-bottom-top-bottom">
                        {FilingDaysTime>=0 && caPassTime >0 ? (
                          <>
                            <p className="xc">
                              <span className="pic-bord">
                                <img className="one" src={icon6} />
                              </span>
                              <span>相册</span>
                              <ImagePicker
                                  files={fileList}
                                  length={1}
                                  style={{
                                    width: "38px !important",
                                    height: "58px",
                                    padding: "0",
                                    display: "inline-block",
                                    verticalAlign: "middle",
                                    opacity: 0,
                                    position: "absolute",
                                    marginLeft: "-55px",
                                    marginTop: "-12px",
                                  }}
                                  onChange={this.imgChange}
                                  selectable={fileList.length < 1 && FilingDaysTime>0}
                                  accept="image/gif,image/jpeg,image/jpg,image/png"
                              />                              
                            </p>
                          </>
                        ) : null}
                        
                          
                        
                        {item.consultation_type == "3" ? (
                          <p
                            onClick={() => this.showNews()}
                            className="refuse-owder"
                          >
                            <span className="pic-bord">
                              <i className="iconfont icon-huanzhe" />
                            </span>

                            <span>患者信息</span>
                          </p>
                        ) : null}

                        <p onClick={() => this.prescribingCf("")} className="kf">
                          <span className="pic-bord">
                            <i className="iconfont icon-yaopin" />
                          </span>
                          <span>开方</span>
                        </p>
                        

                        {qxcode ? (
                          <p
                            onClick={() => this.refusewz()}
                            className="refuse-owder"
                          >
                            <span className="pic-bord">
                              <i className="iconfont icon-xingzhuangjiehe" />
                            </span>
                            <span>取消本单</span>
                          </p>
                        ) : (
                          <p></p>
                        )}
                      </p>
                    </div>
                  ) : null}
                </>
              )}

              {item.consultation_stu == "-1" ? (
                <div className="offline-botton">
                  <button onClick={this.sureReceive}>确认接诊</button>
                </div>
              ) : item.consultation_stu == "1" ? (
                <div className="offline-botton offline-botton1">
                  <button>对话已结束</button>
                </div>
              ) : item.consultation_stu == "3" ? (
                <div className="offline-botton offline-botton1">
                  <button>医生已拒诊</button>
                </div>
              ) : null}
            </div>
          </div>
        ) : null}
        {visible03 ? (
          <div className="add-icd">
            <div className="icd-top">
              <span
                style={{
                  width: "100%",
                  display: "inline-block",
                  textAlign: "center",
                }}
              >
                诊断
              </span>
            </div>
            <ul className="icd-details icd-details1">
              {icdList.length
                ? icdList.map((item) => (
                  <li
                    className="icd-details-one"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <span>
                      {item.icdValue} &nbsp; {item.icdName}
                    </span>
                    <input
                      onClick={(e) => this.setIcd(e, item)}
                      type="checkbox"
                      checked={item.state == 2}
                      name="icd"
                    />
                    <br />
                  </li>
                ))
                : null}
            </ul>
            <div className="icd-button">
              <img src={icon5} onClick={() => this.onSure()} />
            </div>
          </div>
        ) : null}
        

        {visible00 ? (
          <div className="up-bottom">
            <div>
              {day.map((item, index) => (
                <span
                  className={index == j ? "red" : ""}
                  onClick={() => this.dianji(index)}
                >
                  {item}
                </span>
              ))}
              <p onClick={this.closeDay}>确定</p>
            </div>
          </div>
        ) : null}
        {visible01 ? (
          <div className="up-bottom">
            <div>
              {danwei.map((item, index) => (
                <span
                  className={index == i ? "red" : ""}
                  onClick={() => this.dianji1(index)}
                >
                  {item}
                </span>
              ))}
              <p onClick={this.closeDanwei}>确定</p>
            </div>
          </div>
        ) : null}
        {visible02 ? (
          <div className="up-bottom">
            <div>
              {count.map((item, index) => (
                <span
                  className={index == k ? "red" : ""}
                  onClick={() => this.dianji2(index)}
                >
                  {item}
                </span>
              ))}
              <p onClick={this.closeCount}>确定</p>
            </div>
          </div>
        ) : null}
        {visible04 ? (
          <div className="up-bottom">
            <div>
              {/* <span>自填用药方法</span> */}
              {useway.map((item, index) => (
                <span
                  className={index == m ? "red" : ""}
                  onClick={() => this.dianji4(index)}
                >
                  {item}
                </span>
              ))}
              <p onClick={this.closeway}>确定</p>
            </div>
          </div>
        ) : null}

        {visible13 ? (
          <>
            <div className="visible-alert-box">
              <div className="visible-alert">
                <p>患者等待30秒还未开方，已返回到等待接诊状态</p>
                <span onClick={this.hanldSure}>确定</span>
              </div>
            </div>
          </>
        ) : null}

        {/* 执业医生到期之后提醒 */}
        {visibledocter2 && (
          <>
            <div className='tipbox'>
              <div className='alertbox'>
                  <div className='top-box'>本医院执业到期提醒</div>
                  <p className='tipmsg'>您在本医院执业已经到期，</p>
                  <p className='tipmsg'>暂时无法使用</p>
                  <p className='tipmsg'>请联系客服：19048012565</p>
                  <div className='knowbtns' onClick={()=>{
                    this.handleCloseAlert2()
                  }}>我已知晓</div>
              </div>
            </div>
          </>
        )}

        {/* 确认取消本单 */}
        {visibleCancel ? (
            <div
                className="cancel-cons-wrap-radio"
                onClick={() => this.setState({ visibleCancel: false,reason:'',otherReasonVis:false })}
            >
                <div className='refusewz-edit' onClick={(e) => e.stopPropagation()}>
                    <p className="refusewz-tips">请选择取消理由</p>
                    <div className='refuse-radio-box'>
                        <Radio.Group onChange={this.handleChangeRadio}>
                            <Space direction='vertical'>
                                {radioList.length && radioList.map((item,index)=>(
                                    <Radio value={item.value}>{item.label}</Radio>
                                ))}
                            </Space>
                        </Radio.Group>
                    </div>
                    
                    {otherReasonVis && <textarea
                        placeholder="请输入取消原因(50字以内)"
                        maxLength={50}
                        defaultValue={reason}
                        onChange={(e) => this.getreason(e)}
                    ></textarea>}
                    <p className="refusewz-button">
                        <span
                            onClick={() =>
                                this.setState({
                                    visibleCancel: false,
                                    reason:'',
                                    otherReasonVis:false
                                })
                            }
                        >
                            取消
                        </span>
                        <span onClick={() => this.sureRefuse()}>确认</span>
                    </p>
                </div>
            </div>
        ) : null}


        {/* 超量提醒弹窗  visibleIsExcess */}
        {visibleExcess && visible12 && isExcessArrList ? (
                <>
                <div className="cons-type-box-wrap">
                    <div className="cons-type-box">
                        <p className="cons-type-tit">提醒</p>
                        <p className="cons-type-tip">当前问诊：<span className="tip-tuwen">{isExcessArrList}</span>；数量过大，请医生核实后再开方。</p>
                        <p className="cons-type">
                            <span className="type-three" onClick={() => this.handleExcessSure()}>确认</span>
                        </p>
                    </div>
                </div>
                </>
        ) : null}

        {visibleunit ? (
          <>
              <div className="cons-type-box-wrap">
                  <div className="cons-type-box">
                      <p className="cons-type-tit">用法用量提醒</p>
                      <p className="cons-type-tip">您现在用药单位为<span className="tip-units">{unitss ? unitss : chamedsmethod[0].unit}</span>,请注意</p>
                      <p className="cons-type">
                          <span className="type-three" onClick={() => this.sureUnits()}>确认</span>
                      </p>
                  </div>
              </div>
          </>
        ) : null}


        <Popup
          visible={visible11}
          onMaskClick={() => {
            this.setState({
              visible11: false,
            });
          }}
          bodyStyle={{
            maxHeight: "80vh",
            borderTopRightRadius: "14px",
            borderTopLeftRadius: "14px",
          }}
        >
          <div className="video-news-title">
            <i
              className="iconfont icon-cuo"
              onClick={() =>
                this.setState({
                  visible11: false,
                })
              }
            />
            <span>患者信息</span>
            <span></span>
          </div>
          <div className="video-patient-news">
            <Patientnews news={item} />
          </div>
        </Popup>
        <Popup
          visible={visible12}
          onMaskClick={() => {
            this.setState({
              visible12: false,
            });
          }}
          bodyStyle={{
            maxHeight: "80vh",
            borderTopRightRadius: "14px",
            borderTopLeftRadius: "14px",
          }}
        >
          <div className="video-news-title">
            <i
              className="iconfont icon-cuo"
              onClick={() =>
                this.setState({
                  visible12: false,
                })
              }
            />
            <span>开具处方</span>
            <span></span>
          </div>
          <p className="dutydetail-phone">
            <span>
              如有问题，联系客服：<span id="docterPhone">19983479886</span>
            </span>
            <span className="fuzhi" onClick={() => this.copykefuhone()}>
              复制
            </span>
          </p>
          <div className="video-patient-news1">
            <Icdlistsnews
              icdlists={icdlists}
              icdList={icdList}
              visible5={visible5}
              onscroll={this.onscroll1}
              changeuserinfo={this.changeuserinfo}
              setIcd={this.setIcd}
              closev5={this.closev5}
              delIcd={this.delIcd}
              onSearch={this.onSearch}
              changeState={this.changeState}
            />
            {ischinese.length ? (
              <>
                <p className="offaddcf-ill-tipszd offaddcf-ill-tipszd1">
                  <p className="offaddcf-ill-tipszd-top offaddcf-ill-tipszd-top1 offaddcf-ill-tipszd-top12">
                    <span className="offaddcf-zd-title">中药需求：</span>
                    <span className="chameds-count">以下用量为1剂，1剂共计{somegoods.length}味</span>
                  </p>
                </p>
              </>
            ) : (
              <>
                <p className="offaddcf-ill-tipszd offaddcf-ill-tipszd1">
                  <p className="offaddcf-ill-tipszd-top offaddcf-ill-tipszd-top1 offaddcf-ill-tipszd-top12">
                    <span className="offaddcf-zd-title">药品需求：</span>
                    <span className="offaddcf-zd-lx">
                      （
                      {supervisionType == 1
                        ? "中药"
                        : supervisionType == 2
                          ? "西药"
                          : supervisionType == 3
                            ? "成药"
                            : "西药"}
                      ）
                    </span>
                  </p>
                </p>
              </>
            )}
            
            <Offsomegoods
              somegoods={somegoods}
              visible6={visible06}
              index1={index01}
              showall={1}
              showUseways={this.showUseways}
              showV4={this.showV4}
              getvalue={this.getvalue}
              changenum2={this.changenum2}
              showV1={this.showV1}
              showV01={this.showV01}
              showV2={this.showV2}
              ischinese={ischinese}
            />

            {ischinese.length ? (
              <>
                <p className="offaddcf-ill-tipszd offaddcf-ill-tipszd1">
                  <p className="offaddcf-ill-tipszd-top offaddcf-ill-tipszd-top1 offaddcf-ill-tipszd-top12">
                    <span className="offaddcf-zd-title">用法用量：</span>
                  </p>
                </p>
                {chinesemethodtwo.map((i)=>(
                    <p className="cha-methods">
                        <div className="yongfa">
                            <p className="yongfa1">中药共需
                                <input type="text" maxLength={4} defaultValue={i.co_demand} onChange={(e) => this.handleInputChange(e, "allarr")}/>剂;
                            </p>
                        <p className="yongfa2">
                            <input
                                className="yfunit"
                                type="text"
                                readOnly
                                defaultValue={i.meiday}
                                onChange={(e) => this.handleInputChange(e, "meidays")}
                                ref={(input) => (this.inputRef1 = input)}
                                onClick={() => {
                                    this.setState({
                                        pickerVisible: true,
                                    });
                                }}
                            />
                            <img className="yfunit-img1" src={xiala} />
                            {pickerVisible ? (
                                <div className="up-bottom">
                                    <div>
                                        <div className="use-medicine" >
                                            <span onClick={()=>{
                                                this.setState({
                                                    pickerVisible: false,
                                                })
                                            }}>取消</span>
                                        </div>

                                        {meidayss.map((item, index) => (
                                            <span 
                                                className={ item == this.state.selectedDay ? "red" : "" }
                                                onClick={() =>this.setState({ selectedDay: item })}
                                            >
                                                {item}
                                            </span>
                                        ))}
                                        <p
                                            onClick={() => {
                                                this.handleInputChange(
                                                    this.state.selectedDay,
                                                    "meidays"
                                                );
                                                this.inputRef1.value = this.state.selectedDay;
                                                this.setState({
                                                    pickerVisible: false,
                                                });
                                            }}
                                        >
                                            确定
                                        </p>
                                    </div>
                                </div>
                            ) : null}
                            {/* 每日 */}
                            <input className="input2" type="text" maxLength={4} defaultValue={i.daily} onChange={(e) => this.handleInputChange(e, "dayarr")} />剂
                        </p>
                        </div>
                        <p className="yongfaline1"></p>
                        <div className="yongfa">
                        <p className="yongfa1">每剂分<input type="text" maxLength={4} defaultValue={i.each_dose} onChange={(e) => this.handleInputChange(e, "mlarr1")} />次使用;
                        </p>
                        <p className="yongfa2">每次
                            <input type="text" maxLength={4} defaultValue={i.at_time} onChange={(e) => this.handleInputChange(e, "mlarr2")} />
                            <input
                                className="yfunit"
                                type="text"
                                readOnly
                                defaultValue={i.unit}
                                onChange={(e) => this.handleInputChange(e, "units")}
                                ref={(input) => (this.inputRef = input)}
                                onClick={() => {
                                    this.setState({
                                        visible10: true,
                                    });
                                }}
                            />
                            <img className="yfunit-img" src={xiala} />
                            {visible10 ? (
                                <div className="up-bottom">
                                    <div>
                                    <div
                                        className="use-medicine"
                                        onClick={() => {
                                        this.setState({ visible10: false }, () => {
                                            this.useMedicineAndUnitcha(
                                            "自填用药单位",
                                            "units",
                                            unit
                                            );
                                        });
                                        }}
                                    >
                                        <span>自填用药单位</span>
                                    </div>

                                    {unit.map((item, index) => (
                                        <span
                                            className={
                                                item == this.state.selectedUnit ? "red" : ""
                                            }
                                            onClick={() =>
                                                this.setState({ selectedUnit: item })
                                            }
                                        >
                                            {item}
                                        </span>
                                    ))}
                                    <p
                                        onClick={() => {
                                            this.handleInputChange(
                                                this.state.selectedUnit,
                                                "units"
                                            );
                                            this.inputRef.value = this.state.selectedUnit;

                                            this.setState({
                                                visible10: false,
                                            });

                                            this.handleSure(this.state.selectedUnit,index,'units')
                                        }}
                                    >
                                        确定
                                    </p>
                                    </div>
                                </div>
                            ) : null}
                        </p>
                        </div>
                        <p className="yongfaline1"></p>
                        <div className="yongfa3">
                            <span>用药方式：</span>
                            <p className={ i.med_method == "水煎内服" ? "yongfa3-method2" : "yongfa3-method1"} onClick={() => this.clickMethod("水煎内服")}>
                                {i.med_method == "水煎内服" ? ( 
                                    <img src={gou} alt="" />
                                ) : null}
                                <span className="yongfa4">水煎内服</span>
                            </p>
                            <p className={ i.med_method == "水煎外用" ? "yongfa3-method2" : "yongfa3-method1" } onClick={() => this.clickMethod('水煎外用')}>
                                {i.med_method == "水煎外用" ? (
                                    <img src={gou} alt="" />
                                ) : null}
                                <span className="yongfa4">水煎外用</span>
                            </p>
                        </div>

                        <div className="yongfa33">
                            <p className={ (i.med_method !== "水煎内服" && i.med_method !== "水煎外用") ? "yongfa3-method2" : "yongfa3-method1" }  onClick={() => {this.clickMethod(3);}}>
                                {(i.med_method !== "水煎内服" && i.med_method !== "水煎外用") ? (
                                    <img src={gou} alt="" />
                                ) : null}
                                <span className="yongfa4">其他</span>
                            </p>
                            <input
                                type="text"
                                maxLength={6}
                                placeholder="请输入用药方式"
                                defaultValue={(i.med_method !== "水煎内服" && i.med_method !== "水煎外用") ? i.med_method : ''}
                                onChange={(e) => this.handleInputChange(e,'methodarr')}
                                disabled={i.med_method === "水煎内服" || i.med_method === "水煎外用"}
                            />
                        </div>

                        <p className="yongfaline"></p>
                        <div className="yongfa5">
                        <span style={{ whiteSpace: "nowrap" }}>用药时间：</span>
                        <p className="yongfa6">
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="1"
                                checked={i.med_time == "1"}
                                onChange={(e)=>this.handleInputChange(1,'radio')}
                            />
                            <span>饭前用药</span>
                            </label>
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="2"
                                checked={i.med_time == "2"}
                                onChange={(e)=>this.handleInputChange(2,'radio')}
                            />
                            <span>饭后用药</span>
                            </label>
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="3"
                                checked={i.med_time == "3"}
                                onChange={(e)=>this.handleInputChange(3,'radio')}
                            />
                            <span>睡前用药</span>
                            </label>
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="4"
                                checked={i.med_time == "4"}
                                onChange={(e)=>this.handleInputChange(4,'radio')}
                            />
                            <span>晨起用药</span>
                            </label>
                        </p>
                        </div>
                    </p>                   
                ))}

              </>
            ) : null}

            {/* 病史信息 */}
            <div className="offaddcf-historyinfo-box">
              <div className='offaddcf-historyinfo-tit'>
                  <span className='span-yuan'></span>
                  <span className='span-tit'>病史信息</span>
              </div>
              <Historyinfo 
                  news={historyInfo}
                  setvalue={this.setvalue}
              />
            </div>

            {/* 医生建议 */}
            <p className="offaddcf-ill-tips">
              <span className="offaddcf-yuan"></span>
              <span className="offaddcf-user">医生建议:</span>
            </p>
            <p className="shuoming-jianyi">
              <form
                target="#"
                id="search_from"
                action="javascript:return true"
                target="frameFile"
              >
                <textarea
                  placeholder={docterProposal1}
                  value={docterProposal}
                  onChange={(e) => this.changeuserinfo(e, 5)}
                />
                <iframe
                  id="frameFile"
                  name="frameFile"
                  style={{ display: "none" }}
                ></iframe>
              </form>
            </p>
            <div className="offaddcf-button-cancel-submit">
              {/*<button>驳回需求</button>*/}
              {isSumbit ? null : (
                <>
                    <button className='cancel-btn' onClick={()=>{
                        this.setState({
                            visibleCancel: true,
                        })
                    }}>取消本单</button>
                </>
              )}
              <button  className='submit-btn' onClick={this.handleSubmitTip}>提交处方</button>
            </div>
          </div>
          {visible002 ? (
            <div className="bohui-outer1">
              <div className="bohui">
                <p className="customstyle title">请输入密码</p>
                <p className="content">
                  <span className="customstyle">密码：</span>
                  <input
                    className="customstyle"
                    type="text"
                    placeholder="请输入密码"
                    maxLength={20}
                    onChange={(e) => this.passwordValue(e)}
                  />
                </p>
                <p className="content-jizhu">
                  <input
                    type="radio"
                    checked={knowcode}
                    onClick={() =>
                      this.setState({
                        knowcode: !knowcode,
                      })
                    }
                  />
                  <span className="customstyle">
                    记住密码，下次无需输入密码
                  </span>
                </p>
                <p className="button">
                  <span
                    className="customstyle"
                    onClick={() => {
                      this.setState(
                        {
                          visible002: false,
                          visible12: false,
                          password: "",
                          subcode: 1,
                        },
                        () => {
                          this.getnews();
                        }
                      );
                    }}
                  >
                    取消
                  </span>
                  <span
                    className="customstyle"
                    onClick={() => this.passwordSure()}
                  >
                    确认
                  </span>
                </p>
              </div>
            </div>
          ) : null}
          {flog == 2 ? (
            <p className="chat-center-outer">
              <SpinLoading />
              {/*<p>跳转中...</p>*/}
            </p>
          ) : null}
        </Popup>
        {/*{visible4 ? <div className='add-icd1' onScroll={this.onscroll} onClick={(e) => {*/}
        {/*    e.stopPropagation()*/}
        {/*    this.setState({*/}
        {/*        visible5: false*/}
        {/*    })*/}
        {/*}}>*/}
        {/*    <div className='register-head1' onClick={(e) => {*/}
        {/*        e.stopPropagation()*/}
        {/*    }}>*/}
        {/*        <img src={icon3}/>*/}
        {/*        <input type="text" value={icdone} placeholder='搜索icd编码、icd名称'*/}
        {/*               onChange={(e) => this.changeuserinfo(e, 4)}/>*/}
        {/*        {icdone ?*/}
        {/*            <img onClick={() => this.setState({icdone: ''})} className='wrong' src={icon10}/> : null}*/}
        {/*        <span onClick={() => this.onSearch()}>搜索</span>*/}
        {/*    </div>*/}
        {/*    {visible5 ? <ul className='icd-details1'>*/}
        {/*        {icdList.length ? icdList.map(item => (*/}
        {/*            // <p className={item.state ? 'icd-details-one1' : 'icd-details-one0'}*/}
        {/*            //    style={{height: '26px', lineHeight: '26px'}} onClick={(e) => this.setIcd(e, item)}>*/}
        {/*            //   <span style={{marginRight: '20px'}}>{item.icdValue}</span>*/}
        {/*            //   <span>{item.icdName}</span>*/}
        {/*            // </p>*/}
        {/*            <li className='icd-details-one' onClick={(e) => {*/}
        {/*                e.stopPropagation()*/}
        {/*            }}>*/}
        {/*                <span>{item.icdValue} &nbsp; {item.icdName}</span>*/}
        {/*                <input*/}
        {/*                    onClick={(e) => this.setIcd(e, item, 'add')}*/}
        {/*                    type='checkbox' checked={item.state}*/}
        {/*                    name='icd'/><br/>*/}
        {/*            </li>*/}
        {/*        )) : <Nodata type='病历'/>}*/}
        {/*        <li className='icd-details-sure'>*/}
        {/*            <span>已选病情（{icdlists.length}）</span>*/}
        {/*            <button onClick={() => this.setState({visible5: false})}>确认</button>*/}
        {/*        </li>*/}
        {/*    </ul> : null}*/}
        {/*    <div className='select-all1'>*/}
        {/*        {icdlists.length ? <p className='has-chose'>已选病情（{icdlists.length}）</p> : null}*/}
        {/*        {icdlists.length ? icdlists.map(i => (*/}
        {/*            <p>*/}
        {/*                <span>{i.icdName}</span><img onClick={(e) => this.setIcd(e, i, 'del')} src={icon11}/>*/}
        {/*            </p>)) : <Nodata type='病历'/>}*/}
        {/*    </div>*/}
        {/*    <div className='icd-button1'>*/}
        {/*        <button className='quxiao'*/}
        {/*                onClick={() => this.setState({visible4: false, visible5: false})}>取消诊断*/}
        {/*        </button>*/}
        {/*        {icdlists.length ? <button className='sure1' onClick={() => this.onSure()}>确认诊断</button> :*/}
        {/*            <button className='sure1' onClick={(e) => this.onSure1(e)}>确认诊断</button>}*/}
        {/*    </div>*/}
        {/*</div> : null}*/}
        <ImageViewer
          image={image.url}
          visible={image.visible}
          onClose={() => {
            this.setState({
              image: {
                url: "",
                visible: false,
              },
            });
          }}
        />
        <Popup
          visible={visible7}
          onMaskClick={() => {
            this.setState({
              visible7: false,
            });
          }}
          bodyStyle={{
            maxHeight: "92vh",
            borderTopRightRadius: "14px",
            borderTopLeftRadius: "14px",
          }}
        >
          <div className="offline-popup">
            <h1 className="title">药品详情</h1>
            <span
              onClick={() => {
                this.setState({
                  visible7: false,
                });
              }}
              style={{
                position: "absolute",
                right: "12px",
                top: "12px",
                fontSize: "16px",
                color: "#177FFF",
              }}
            >
              确定
            </span>
          </div>
          {originalPrescribing ? (
            <div className="offline-body">
              <div className="offprescription-outer">
                <h1 className="title">中江云益健互联网医院</h1>
                <p className="offprescription-jian">
                  <h1>处方笺</h1>
                  <img src={icon14} />
                </p>
                <p className="offprescription-number">
                  处方编号：{detail.prescribing_number}
                </p>
                {/*<p className='offprescription-line'></p>*/}
                <p className="offprescription-one">
                  <p className="offprescription-one-left">
                    <span>科室:</span>
                    <p>{detail.docter_department}</p>
                  </p>
                  <p className="offprescription-one-right">
                    <span>开具日期:</span>
                    <p>{year}</p>
                    <span>年</span>
                    <p>{month}</p>
                    <span>月</span>
                    <p>{day}</p>
                    <span>日</span>
                  </p>
                </p>
                <p className="offprescription-one">
                  <p className="offprescription-one-left">
                    <span>姓名:</span>
                    <p>{detail.patient_name}</p>
                  </p>
                  <p className="offprescription-one-right2">
                    <span>性别:</span>
                    <p>{detail.patient_sex == "1" ? "男" : "女"}</p>
                    <span>年龄:</span>
                    <p>{detail.patient_age} 岁</p>
                  </p>
                </p>
                <p className="offprescription-one2">
                  <span>初步诊断：</span>
                  <p>{detail.docter_diagnosis}</p>
                </p>
                {/*<img className='zj-zhang1' src={icon13}/>*/}
                {/*<p className='offprescription-line'></p>*/}
                <p className="offprescription-RP">RP</p>
                {ischinese.length ? (
                  <>
                    <p className="all-chameds">
                      <p className="chameds-list">
                        {list3
                          ? list3.map((item, index) => (
                              <p className="offprescription-chameds-left">
                                <span>
                                  {item.med_com_name
                                    ? item.med_com_name
                                    : item.medicinal_name}
                                </span>
                                <span>
                                  {item.medicinal_quantity}g
                                </span>
                              </p>
                            ))
                          : null}
                      </p>
                      <p className="cha-methods">
                        <p className="mtthod-tit">用法：</p>
                        <p className="mtthod-con">
                        {chinesemethodtwo.filter(i => i.consultation_id == item.consultation_id).map(i=>(
                                <span>
                                  共{i.co_demand}剂，{i.meiday}{i.daily}剂，每剂分{i.each_dose}次使用，
                                  每次{i.at_time}{i.unit}，{i.med_method}，
                                  {i.med_time == 1
                                    ? "饭前用药"
                                    : i.med_time == 2
                                    ? "饭后用药"
                                    : i.med_time == 3
                                    ? "睡前用药"
                                    : i.med_time == 4
                                    ? "晨起用药"
                                    : ""}
                                </span>
                          ))} 
                        </p>
                      </p>
                      {list3.length<39 && <p className="kongbailine">(以下空白)</p>} 
                    </p>
                  </>
                ) : (
                  <>
                    <p className="all-meds">
                      {list3
                        ? list3.map((item, index) => (
                            <p className="offprescription-meds">
                              <p className="offprescription-meds-news">
                                <p className="offprescription-meds-left">
                                  <span>
                                    {index + 1}、
                                    {item.med_com_name
                                      ? item.med_com_name
                                      : item.medicinal_name}
                                  </span>
                                  <span>
                                    {item.medicinal_spec} x{item.medicinal_quantity}
                                    {item.packing_unit &&
                                    item.packing_unit != "null"
                                      ? item.packing_unit
                                      : ""}
                                  </span>
                                </p>
                              </p>
                              <p className="offprescription-meds-details">
                                用法用量：{item.medicinal_frequency},每次
                                {item.medicinal_usage},{item.medicinal_day}天的量,{" "}
                                {item.medicinal_method}
                              </p>
                              {item.medicinal_supplementary ? (
                                <p className="offprescription-meds-details">
                                  补充说明：{item.medicinal_supplementary}
                                </p>
                              ) : null}
                              {index + 1 == list3.length && index < 4 ? (
                                <p className="xu-line"></p>
                              ) : null}
                              {index + 1 == list3.length && index < 4 ? (
                                <p className="kongbai">(以下空白)</p>
                              ) : null}
                            </p>
                          ))
                        : null}
                    </p>
                  </>
                )}
                
                {/*<p className='offprescription-line'></p>*/}
                <p className="offprescription-doctor">
                  <p className="offprescription-doctor1">
                    <span className="offname">医师:</span>
                    <p>
                      <img className="ys-img1" src={docterAutograph} />
                      <span className="xia-line"></span>
                    </p>
                  </p>
                  <p className="offprescription-doctor15">
                    <span>医院药师:</span>
                    <p>
                      {detail.prescribing_state == 1 ? (
                        <img className="ys-img" src={docterAutograph1} />
                      ) : (
                        <span className="no-line"></span>
                      )}
                      <span className="xia-line"></span>
                    </p>
                  </p>
                  <p className="offprescription-doctor5">
                    <span>药店药师审核:</span>
                    <p>
                      {docterAutograph3 && detail.prescring_pha_state == 1 ? 
                        <img className="ys-img" src={docterAutograph3} />
                       :  detail.prescring_pha_state == 1 && pharmacist_img ? 
                       <img className="ys-img" src={pharmacist_img} /> : 
                        <span className="no-line"></span> }
                      <span className="xia-line"></span>
                    </p>
                  </p>
                </p>
                <p className="offprescription-doctor2">
                  <p className="offprescription-doctor3">
                    <span className="customstyle customstyle">调配:</span>
                    <p>
                      {docterAutograph3 && detail.prescring_pha_state == 1 ? 
                        <img className="ys-img" src={docterAutograph3} />
                       :  detail.prescring_pha_state == 1 && pharmacist_img1 ? 
                       <img className="ys-img" src={pharmacist_img1} /> : 
                        <span className="no-line"></span> }

                      <span className="xia-line"></span>
                    </p>
                  </p>
                  <p className="offprescription-doctor32">
                    <span className="customstyle customstyle">核对/发药:</span>
                    <p>
                      {/* {detail.prescring_pha_state == 1 ? ( <img className="ys-img" src={staff_img} />) : ( <span className="no-line"></span>)} */}
                      {detail.prescring_pha_state == 1 && phaStaffIdStore && phaStaffIdStoreImg ? <img className="ys-img" src={phaStaffIdStoreImg}/> : 
                                                    detail.prescring_pha_state == 1 && staff_img ? <img className="ys-img" src={staff_img}/> : <span className='no-line'></span>}
                      <span className="xia-line"></span>
                    </p>
                  </p>
                  <p className="offprescription-doctor4">
                    <span>金额:</span>
                    <p></p>
                  </p>
                </p>

                <p className="offprescription-tips">
                  <span>备注：</span>
                  <p>{detail.docter_proposal}</p>
                </p>
                <p className="offprescription-tips1">
                  1、医师处方仅开具当日有效（医生注明除外）。
                </p>
                <p className="offprescription-tips1">
                  2、处方超过7日用量为病情需要。
                </p>
              </div>
            </div>
          ) : null}
        </Popup>
        {visible2 ? (
          <div className="box-tipsbox">
            <div className="tipsbox-text">
              <h1>请选择退诊原因</h1>
              <div>
                <p>咨询不对症</p>{" "}
                <input
                  type="radio"
                  value="咨询不对症"
                  name="reason"
                  onChange={(e) => this.getreason(e)}
                />
              </div>
              <div>
                <p>患者资料不全</p>{" "}
                <input
                  type="radio"
                  value="患者资料不全"
                  name="reason"
                  onChange={(e) => this.getreason(e)}
                />
              </div>
              <div>
                <p>患者病情复杂</p>{" "}
                <input
                  type="radio"
                  value="患者病情复杂"
                  name="reason"
                  onChange={(e) => this.getreason(e)}
                />
              </div>
              <button
                onClick={() =>
                  this.setState({
                    visible2: false,
                  })
                }
              >
                取消
              </button>
              <button className="text-btn2" onClick={this.cancelchat}>
                确定
              </button>
            </div>
          </div>
        ) : null}
        {visible9 ? (
          <div
            className="phaphone-edit-outer"
            onClick={() => this.setState({ visible9: false })}
          >
            <div className="phaphone-edit" onClick={(e) => e.stopPropagation()}>
              <p className="pha-tips">复制号码到通讯拨打电话</p>
              <p className="pha-phone">
                药师电话：<span id="dingdanhao">{pharmacistPhone}</span>
              </p>
              <p className="pha-copy" onClick={() => this.copyDanhao()}>
                点击复制
              </p>
            </div>
          </div>
        ) : null}

        {visible10 ? (
          <div
            className="cancel-cons-wrap-radio"
            onClick={() => this.setState({ 
              visibleCancel: false,
              reason:'',
              otherReasonVis:false,
              visible10:false, 
            })}
          >
              <div className='refusewz-edit' onClick={(e) => e.stopPropagation()}>
                  <p className="refusewz-tips">请选择取消理由</p>
                  <div className='refuse-radio-box'>
                      <Radio.Group onChange={this.handleChangeRadio}>
                          <Space direction='vertical'>
                              {radioList.length && radioList.map((item,index)=>(
                                  <Radio value={item.value}>{item.label}</Radio>
                              ))}
                          </Space>
                      </Radio.Group>
                  </div>
                  
                  {otherReasonVis && <textarea
                      placeholder="请输入取消原因(50字以内)"
                      maxLength={50}
                      defaultValue={reason}
                      onChange={(e) => this.getreason(e)}
                  ></textarea>}
                  <p className="refusewz-button">
                      <span
                          onClick={() =>
                              this.setState({
                                  visibleCancel: false,
                                  reason:'',
                                  otherReasonVis:false,
                                  visible10:false,
                              })
                          }
                      >
                          取消
                      </span>
                      <span onClick={() => this.sureRefuse()}>确认</span>
                  </p>
              </div>
          </div>
        ) : null}

        {visible07 ? (
          <div className="change-meds-outer">
            <div className="change-meds-inner">
              <h1>修改药品</h1>
              {/* <p className="del-tips">删除药品后不可恢复，请医生谨慎修改</p> */}
              {somegoods.map((item) => (
                <div className="one-meds">
                  <p className="name-spec">
                    <span className="name">{item.medComName}</span>
                    <span className="spec">{item.medicinalSpec}</span>
                  </p>
                  <p className="del-update">
                    {/* <span
                      className="delete"
                      onClick={() => this.toDelete(item.medId)}
                    >
                      <i className="iconfont icon-shanchu1" />
                      <span>删除</span>
                    </span> */}
                    <span className="add-reduce-count">
                      <span
                        className="reduce"
                        onClick={() =>
                          this.reduceCount(item.medId, item.medicinalQuantity)
                        }
                      >
                        -
                      </span>
                      <span className="count">{item.medicinalQuantity}</span>
                      <span
                        className="add"
                        onClick={() =>
                          this.addCount(item.medId, item.medicinalQuantity)
                        }
                      >
                        +
                      </span>
                    </span>
                  </p>
                </div>
              ))}
              <div className="refuse-button">
                <span className="refuse" onClick={() => this.toRefuse()}>
                  取消
                </span>
                <span
                  className="sure-button"
                  onClick={() => this.toSureupdate()}
                >
                  确定
                </span>
              </div>
            </div>
          </div>
        ) : null}
        <ImageViewer
          image={image.url}
          visible={image.visible}
          onClose={() => {
            this.setState({
              image: {
                url: "",
                visible: false,
              },
            });
          }}
        />
      </>
    );
  }
}
