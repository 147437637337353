import { axiosInstance } from "../config"

//药品评价
export const commentmedRequest = (data) => {
    return axiosInstance.post("/api/hosp/comment/add", data)
}

//查询药品评价
export const medcommentRequest = (data) => {
    return axiosInstance.post(`/api/hosp/comment/list`, data)
}

// 查询订单药品评价
export const medCommentDetail = (orderMedId) => {
    return axiosInstance.get(`/api/hosp/comment/medicine?orderMedId=${orderMedId}`)
}

//查询单个药品评价
export const medowncommentRequest = (data) => {
    return axiosInstance.post(`/api/hosp/comment/usermed`,data)
}