import React from 'react'

import Head from '../../../component/Head'
import StepButon from '../../../baseui/stepbutton/index'
import Tag from '../../../baseui/tag/index'

import IMG from'../../../static/img/head.png'

import './pay.less'

export default function Pay(props) {
    const {type=2}=props
    return (
        <>
            <Head title='支付订单'></Head>
            <div className='screen'>
                <div>
                    <div className='pay-doctor-card flexr'>
                        <img src={IMG} alt=""/>
                        <div className='pay-doctor-card-detail flexc'>
                            <div className='pay-doctor-name'>
                                <p>朱心寒</p>
                                <div className='pay-doctor-tag flexr'>
                                    <Tag text='副主任医师' type='frame'/>
                                    <Tag text='三甲甲等' type='frame'/>
                                </div>
                                <span></span>
                            </div>
                            <div className='pay-doctor-belong'>绵阳市人民医院&emsp;内科</div>
                            <div className='pay-doctor-data flexr'>
                                好评率&nbsp;<p>98%</p>&emsp;&emsp;问诊次数&nbsp;<p>700</p>&emsp;&emsp;<i className='iconfont icon-xingxing'> 4.8</i>
                            </div>
                        </div>
                    </div>
                </div>
                {type===1?
                <div className='pay-choose'>
                    <div className='flex-apart-row'>
                        <span>选择付款方式</span>
                        <div className='flex-row' style={{height:'100%'}}>
                            <span>微信</span>
                            <i className='iconfont-l icon-xiangyou'></i>
                        </div>
                        
                    </div>
                    <div style={{
                        height: '1px',
                        width: '100%',
                        backgroundColor: '#DDDFE5'
                    }}></div>
                    <div className='flex-apart-row'>
                        <span>回复时间</span>
                        <span>预计3小时，24小时未回复全额退款</span>
                    </div>
                </div>
                :null
                }
                {type===2?
                <>
                    <div className='pay-choose'>
                        <div className='flex-apart-row'>
                            <span>选择付款方式</span>
                            <div className='flex-row' style={{height:'100%'}}>
                                <span>微信</span>
                                <i className='iconfont-l icon-xiangyou'></i>
                            </div>
                            
                        </div>
                        <div style={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#DDDFE5'
                        }}></div>
                        <div className='flex-apart-row'>
                            <span>预约时间</span>
                            <span>周六，12:00-13:00</span>
                        </div>
                        <div style={{
                            height: '1px',
                            width: '100%',
                            backgroundColor: '#DDDFE5'
                        }}></div>
                        <div className='flex-apart-row'>
                            <span>接听手机</span>
                            <span>13885426958</span>
                        </div>
                    </div>
                    <div className='pay-tips'>
                        <p>用户须知</p>
                        <span>1.服务流程：选择需要预约的时间段，由医生再预约时间段内拨打您愉
悦的电话。<br/>
2.医生本人通话，请确保您在预约时间段内电话畅通。<br/>
3.未接通或通话失败全额退款，如通话意外中断医生会再次拨打您愉悦
的电话。<br/>
4.请控制时间在15分钟内，未满15分钟一律按15分钟处理。</span>
                    </div>
                </>
                :null
                }
            </div>
            <StepButon name='立即支付'>
                <span>合计：￥10</span>
            </StepButon>
        </>
    )
}