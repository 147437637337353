import React from 'react'
import { withRouter } from 'react-router-dom'
import { resetdirect } from '../../redux/user.redux'
import { connect } from 'react-redux'

@withRouter
@connect(
	state=>state.user,
	{resetdirect}
)
class AuthRoute extends React.Component{
	componentDidMount() {
		const publicList = ['/login','/introduce']
		const pathname = this.props.location.pathname
		const isguided = this.props.isguided
		// 现在的url地址  login是不需要跳转的
		if (publicList.indexOf(pathname)>-1) {
			return null
		}
		//登陆异步请求

		// 是否走过引导
		if(isguided===false){
			this.props.history.push('/introduce')
		}

		//清空redirect
		// resetdirect('')
		
	}
	render(){
		return null
	}

}
export default AuthRoute