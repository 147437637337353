import React, { Component } from 'react'
// import { withRouter } from 'react-router-dom'

import RecommmendMed from '../../component/Goods/rmd-med'

import { actMedListRequest } from '../../api/request/med'

import './style.less'


class OtherActivity extends Component {
    constructor(props) {
        super(props)
        this.state = {
            pharmacyId: props.pharmacyId,
            type: props.type,
            recommand: []
        }
    }

    componentDidMount() {
        actMedListRequest({
            type:this.state.type,
            pharmacyId:this.state.pharmacyId
        }).then(res => {
            if(res.code === 1 && res.data[0].length > 0) {
                this.setState({
                    recommand: res.data[0]
                })
            }
        })
    }

    backPage=()=>{
        window.history.back()
    }

    render() {
        const { recommand } = this.state

        return (
            <div className='active-container'>
                <div className='shop-header'>
                    <div className='shop-header-zhanwei flex-row' onClick={this.backPage}>
                        <i className='iconfont icon-xiangzuo'></i>
                    </div>
                    <div className='shop-search flexr'
                    style={{border: '1px solid #414141'}}>
                        <i className='iconfont icon-suosou'></i>
                        <input type="text" placeholder='999感冒灵' />
                    </div>
                    <div className='shop-header-zhanwei'></div>
                </div>
                <div className='recommend-med-list'>
                    {recommand.length>0?<RecommmendMed recommand={recommand} />:null}
                </div>
            </div>
        )
    }
}

export default OtherActivity