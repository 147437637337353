import React from 'react'
import ReactDOM from 'react-dom'
// import { createStore, applyMiddleware, compose } from 'redux'
// import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import 'lib-flexible'

import store from './redux/store'
import AppRouter from './router/AppRouter'
import './static/css/common.less'
const phonestate=window.localStorage.getItem("userId")
if(phonestate){
  window.localStorage.setItem("phonestate",true)
}
// const store = createStore(reducers, compose(
// 	applyMiddleware(thunk),
// 	window.devToolsExtension?window.devToolsExtension():f=>f
// ))

const persistor = persistStore(store)

ReactDOM.render(
  (<Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppRouter />
    </PersistGate>
  </Provider>),
document.getElementById('root')
)