import React ,{Component}from 'react'
import cls from 'classnames'

import Scroll from '../../../component/scroll/index'
import Head from '../../../component/Head/index'
import './style.less'

const category=[
  {id:1,name:'常见疾病',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'感冒用药'},{id:2,name:'肠胃疾病'},{id:3,name:'皮肤用药'}]},
  {id:2,name:'内科',depart:20,ill:120,tab:['全部','呼吸内科','消化内科','心血管内科','神经内科','肿瘤科','内分泌科','血液内科','传染病科','小儿内科'],sub_categories:[{id:1,name:'冠心病'},{id:2,name:'高血压'},{id:3,name:'心率失常'},{id:4,name:'心衰'},{id:5,name:'房颤'},{id:6,name:'心肌炎'},{id:7,name:'阵发性室上性心动过速'},{id:8,name:'心肌病'},{id:9,name:'风湿性心脏病'},{id:10,name:'房间隔缺损'},{id:11,name:'心肌缺血'},{id:12,name:'心肌梗死'}]},
  {id:3,name:'外科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:4,name:'妇产科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:5,name:'儿科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:6,name:'骨外科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:7,name:'五官科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:8,name:'肿瘤科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:9,name:'皮肤病科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:10,name:'男科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]},
  {id:11,name:'中医科',depart:20,ill:120,tab:[],sub_categories:[{id:1,name:'男科用药'},{id:2,name:'妇科用药'},{id:3,name:'儿童用药'}]}
]

class OnDisease  extends Component{
  constructor(props) {
    super(props)
    this.state = {
      subCategories: [],
      subTabs:[],
      selectMenuId: undefined,
      category_ids: []
    }
  }
  menuClick = (val) => {
    this.setState({
      subCategories: val.sub_categories,
      subTabs:val.tab,
      selectMenuId: val.id,
      category_ids: [],
    })
  }
    render(){
      const {
        subCategories,
        subTabs,
        selectMenuId,
        category_ids,
      } = this.state
      const menuModalItemStyle = (id, compareArr, compareId) => {
        if (selectMenuId===undefined) {
          this.setState({
            subCategories: compareArr[0].sub_categories,
            subTabs:compareArr[0].tab,
            selectMenuId: compareArr[0].id,
            category_ids: [],
          })
          const cid = compareArr[0].id
          return cls("item", cid === id ? "active" : null)
        }
        return cls("item", id === compareId ? "active" : null)
      }
        return(
            <>
                <Head title='疾病分类' iconname='icon-weimingmingwenjianjia_sousuo'/>
                <div className="disease-classify-body" style={{top:'63px'}}>
                  <div className="menu">
                    <Scroll
                      className='menu-scroll'
                      dataSource={category}>
                      {
                        category.map(v => (
                          <div
                            key={v.id}
                            className={menuModalItemStyle(v.id, category, selectMenuId)}
                            onClick={() => this.menuClick(v)}>
                            <span>{v.name}</span>
                          </div>
                        ))
                      }
                    </Scroll>
                  </div>
                  <div className="detail">
                    <Scroll
                        dataSource={subCategories}>
                        <div className='tabs'>
                            {subTabs.map((v,index)=>(<div key={index}>&emsp;{v}</div>))}
                        </div>
                        <div className='total'>共20个科室&emsp;120种疾病</div>
                        {
                            subCategories.map(v => (
                            <div
                                key={v.id}
                                // onClick={() => this.subItemClick(v.id)}
                                className={menuModalItemStyle(
                                v.id,
                                subCategories,
                                category_ids[0],
                                )}>
                                    <h1 className="title">{v.name}</h1>
                            </div>
                            ))
                        }
                    </Scroll>
                  </div>
                </div>
            </>
        )
    }
}

export default OnDisease