import React,{useState,useEffect}from 'react'

import Head from '../../../component/Head/index'

import { topiclistRequest } from '../../../api/request/health'

import icon1 from '../../../static/img/health/1.png'
import icon2 from '../../../static/img/health/2.png'
import icon3 from '../../../static/img/health/3.png'
import icon4 from '../../../static/img/health/4.png'

import './style.less'

export default function HealthRecommand(props){

    const [topicList, setTopicList] = useState([])
    // 获取顶部图片，底部话题列表
    useEffect(() => {
        topiclistRequest().then(res => {
            if(res.code === 1 && res.data[0].length > 0) {
                setTopicList(res.data[0])
            }
        })
    }, [])

    const toCommand = (topicId) => {
        // props.history.push(`/health/post/${topicId}`)
    }

    return(
        <>
            <Head title='推荐话题' />
            <div className='health-screen-page'>
                <div className='health-rmd flex-col'>
                {topicList.map((item, index) => (
                    index > 4 ? null :
                    <div key={index}
                    className='health-backimg'
                    style={{backgroundImage:`url(${item.topicPic})`}}
                    onClick={()=>toCommand(item.topicId)}>
                        <div className='health-mask'></div>
                        <div className='health-title'>{item.topicTitle}</div>
                    </div>
                ))}
                </div>
                <div className='flexc'>
                {topicList.map((item, index) => (
                    index < 5 ? null :
                    <div className='health-tips flexr' key={index}
                    onClick={()=>toCommand(item.topicId)}>
                        <img src={index === 5 ? icon1 : index === 6 ? icon2 : index === 7 ? icon3 : icon4} alt=''/>
                        <span>{item.topicTitle}</span>
                    </div>
                ))}
                </div>
            </div>
        </>
    )
}