import React from 'react'

import Head from '../../../component/Head'

import './inquirylist2.less'

const text='详细描述您的疾病症状，包括发病诱因、症状、病情加重或环境情况、家族病史、是否怀孕等...'

export default function PatientInfo(props) {
    return (
        <>
            <Head title='问题描述'></Head>
            <div className='screen'>
                <span className='inquiry-list-note'>已预约以下时间</span>
                <div className='inquiry-list-item' style={{height:73}}>
                    <div className='inquiry-selecttime'>
                        <span>周六，12:00-13:00</span>
                        <p>医生会在该时间段联系你，请保持手机畅通</p>
                    </div>
                    <span className='re-reserve'>重新预约</span>
                </div>
                <div className='inquiry-list-item' style={{marginTop:26}}>
                    <span>接听手机</span>
                    <input type="text" placeholder='请填写'/>
                </div>
                <div className='inquiry-inputlist2'>
                    <div>
                        <span>病情描述</span>
                        <textarea className='disease-text' placeholder={text} name="" id="illmark" cols="30" rows="10"></textarea>
                        <p>0/700</p>
                    </div>
                    <div style={{
                        height: '1px',
                        width: '100%',
                        backgroundColor: '#DDDFE5'
                    }}></div>
                    <div>
                        <div>
                            <span>添加图片</span>
                            <span>可添加患处、病历、检查单（6张）</span>
                        </div>
                        <div className='upload-img flex-row'>
                            <span>+</span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='bottom-button'>下一步</div>
        </>
    )
}