import React,{Component} from 'react'
import SwipeableViews from 'react-swipeable-views'
import Pagination from './Pagination'
import { connect } from 'react-redux'
import {Redirect,withRouter} from 'react-router-dom'
import {guideComplete} from '../../redux/user.redux'

import './style.less'
import Banner1 from '../../static/img/app/in1.png'
import Banner2 from '../../static/img/app/in2.png'
import Banner3 from '../../static/img/app/in3.png'

const banners=[Banner1,Banner2,Banner3]

@connect(
	state=>state.user,
	{guideComplete}
)
class Introduce extends Component{

    constructor(){
        super();
        this.state={
            index:0,
            touchable:false,
            env: localStorage.env,
            pharmacyId: localStorage.pharmacyId
        }
    }

    handleChangeIndex(index){
        this.setState({
            index:index
        })
        if(index===2){
            setTimeout(()=>{
                if(this.state.index===2){
                    this.setState({
                        touchable:true
                    })
                }
            },1000)
        }else{this.setState({
            touchable:false
        })}
    }

    handleComplete=(e)=>{
        e.preventDefault();
        if(this.state.touchable===false) {return}
        else {this.props.guideComplete(this.state)}
        this.props.guideComplete(this.state)
        // this.props.history.push("/login")
    }


    render(){
        return(
            <div>
                {this.props.redirectTo? <Redirect to={this.props.redirectTo} />:null}
                <div className='introduce-page'>
                    <SwipeableViews enableMouseEvents onChangeIndex={this.handleChangeIndex.bind(this)}>
                        <div className='introduce-pageitem flexc'>
                            <img src={Banner1} alt=""/>
                            <p>优质的性价比</p>
                            <span>药品质量有保障</span>
                            <span>平台优惠利益多</span>
                        </div>
                        <div className='introduce-pageitem flexc' >
                        <img src={Banner2} alt=""/>
                            <p>专业的医生问诊</p>
                            <span>多种方式问名医</span>
                            <span>对症下药疗效好</span>
                        </div>
                        <div className='introduce-pageitem flexc'>
                        <img src={Banner3} alt=""/>
                            <p>2小时必达</p>
                            <span>下单即送速度快</span>
                            <span>配送自取任你选</span>
                        </div>
                    </SwipeableViews>
                    {this.state.index!==2?
                    <Pagination dots={banners.length} index={this.state.index}/>
                    :
                    <div id='complete-introduce' className='introduce-page-in flex-row' onClick={this.handleComplete}>立即体验</div>}
                    
                </div>
            </div>
        
        )
    }
}
export default withRouter(Introduce)