import { axiosInstance } from '../config'

//添加地址
export const addadressRequest = (data) => {
    return axiosInstance.post('/api/hosp/address/add', data)
}

//地址列表
export const adressListRequest = (userId) => {
    return axiosInstance.get(`/api/hosp/address/list?userId=${userId}`)
}

//地址详情
export const addressDetailsRequest = (addressId) => {
    return axiosInstance.get(`/api/hosp/address/details?addressId=${addressId}`)
}

//删除地址
export const delAdressRequest = (addressId) => {
    return axiosInstance.get(`/api/hosp/address/delete?addressId=${addressId}`)
}

//修改地址
export const updateAdressRequest = (data) => {
    return axiosInstance.post('/api/hosp/address/update', data)
}

//获取省
export const getProvince = (data) => {
    return axiosInstance.post('/api/hosp/address/get-province', data)
}
//获取市
export const getCity = (data) => {
    return axiosInstance.post('/api/hosp/address/get-city', data)
}
//获取区
export const gitDistrict = (data) => {
    return axiosInstance.post('/api/hosp/address/get-district', data)
}
