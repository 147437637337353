import React from 'react'
import {withRouter} from "react-router-dom"

import Head from '../../../component/Head'
import Tag from '../../../baseui/tag/index'

import './style.less'

const hospitallist=[
    {id:'1',name:'成都市锦江区第二人民医院',title:['三级甲等','综合医院'],location:'成都市青羊区青龙街82号',phone:'028-61312288',count:700,num:58},
    {id:'2',name:'成都市锦江区第二人民医院',title:['三级甲等','综合医院'],location:'成都市青羊区青龙街82号',phone:'028-61312288',count:700,num:58}
];

function InquiryHospial(props) {

    return(
        <>
            <Head title='医院列表' iconname='icon-weimingmingwenjianjia_sousuo'/>

            <div className='hospital-listtab flex-row'>
                <div>
                    <span>推荐</span>
                    <i className='iconfont icon-xiangxia1'></i>
                </div>
                <div>
                    <span>城市</span>
                    <i className='iconfont icon-xiangxia1'></i>
                </div>
            </div>
            <div>
                {hospitallist.map((v,index)=>{
                    return(
                        <div key={index} className='hospital-item flexc' onClick={()=>props.history.push('/inquiry/hospital/id')}>
                            <div className='hospital-item-title'>
                                <span>{v.name}</span>
                                {
                                    v.title.map((t,i)=>{
                                        return(<Tag key={i} text={t} type='frame' maincolor={i===0?'#FFB740':'#FF6778'} subcolor={i===0?'#FFF3E0':'#FFE8EA'}/>)
                                    })
                                }
                            </div>
                            <div className='hospital-item-detail'>
                                <i className='iconfont icon-dingwei1'></i>
                                <span>{v.location}</span>
                            </div>
                            <div className='hospital-item-detail'>
                                <i className='iconfont icon-tubiao210'></i>
                                <span>{v.phone}</span>
                            </div>
                            <div className='hospital-item-heal'>
                                入驻医生&emsp;<span>{v.num}</span>&emsp;&emsp;&emsp;累计诊断次数&emsp;<span>{v.count}</span>
                            </div>
                        </div>
                    )
                })
                }
            </div>
        </>
    )
}

export default withRouter(InquiryHospial)