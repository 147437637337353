import React, { Component } from 'react';
import "./style.less"
import icon1 from "../../../static/img/icon/user.png"
import icon2 from "../../../static/img/icon/down.png"
import icon3 from "../../../static/img/icon/add.png"
import icon4 from "../../../static/img/icon/jian.png"

import xiala from "../../../static/img/xiala.png";
import gou from "../../../static/img/gou.png";
import { getuserinfo } from "../../../api/hospitalapi/login"
import { 
    allIllsnews, 
    getAllills, 
    getSamemeds, 
    prescribingPrice, 
    docUpdate, 
    submitPrescribing, 
    prescriptionValidation,
    recordUsage,
    docLine, 
    dialogueTemplate,
    selectPharmacyInfo,
} from "../../../api/hospitalapi/offline"
import {
    getlist,
    addPrescribing,
    saveIcd,
    updateOrdernumber,
    patientxq,
    updateDoctype,
    changestatus,
    getLsdetails,
    getIcdCode,
    docAddDisRecord,
    allIcd, docAllicdnews,
    addnews, allMedicinal,
    chineseMethod, isChineseMeds,selectConState,updateOrderinfo,
    selectExcessNumber,
    chineseMethodDoc,
    refusestatus,
    isSumbitPrescribing,
    chineseMedsTaboo,
    updateChineseDocSubmit,
    selectInitMethodChinese,
    updateInitQuantityMethod,
    updatePatientHistoryInfo
} from "../../../api/hospitalapi/news"
import { Modal, Button, WingBlank, WhiteSpace, Toast,Picker, } from 'antd-mobile';
import { dealParams } from "../../../api/config";
import { caAuthentication, caPasstime } from "../../../api/hospitalapi/upload";
import getUrl from '../../../component/GetUrlParam'
import { caUrl, baseUrl, jumpUrl } from '../../../publicurl'
import '../../../static/fonts/font_3834206_frl0uihyft5/iconfont.css'
import moment from "moment";
import icon5 from "../../../static/img/sure1.png";
import chaticon from "../../../static/img/chat/chat-icon.png";
import icon7 from "../../../static/img/sure2.png";
import Nodata from "../../../component/Nodata";
import Patientnews from "../../../component/offline";
import Icdlistsnews from "../../../component/offline/icdlists";
import Offsomegoods from "../../../component/offline/Offsomegoods";
import Historyinfo from "../../../component/offline/historyinfo";
import { SpinLoading,Image,Radio,Space } from "antd-mobile-v5";
const prompt = Modal.prompt;
const alert = Modal.alert
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            visibleMyModal: false,
            docterPhone: localStorage.getItem("docterPhone"),
            doctortext: {},
            usertext: {},
            item: localStorage.getItem("item") ? JSON.parse(localStorage.getItem("item")) : '',
            // username: "",
            // userage: "",
            // usersex: "",
            somegoods: '',
            updatesomegoods: '',
            nowarr: [],
            vibisable: 'none',
            memberId: localStorage.getItem("memberId"),
            tip: true,
            refresh: true,
            medAmount: 0,
            docterId: localStorage.getItem("docterId"),
            caPassTime: localStorage.getItem("caPassTime"),
            docterProposal: localStorage.getItem("docterProposal") ? localStorage.getItem("docterProposal") : '',
            docterProposal1: '请严格按原处方和药品说明书使用，严禁超量超范围使用；如用药过程中出现病情变化或其它不适症状，请立即停药并及时就医。',
            docterProposal2: '输入建议',
            recipeType: localStorage.getItem("recipeType") ? localStorage.getItem("recipeType") : 2,
            icdList: [],
            icdlists: [],
            icdhave: 0,
            page: 1,
            size: 20,
            icdone: '',
            icdValue: localStorage.getItem("icdValue"),
            visible: false,
            visible1: false,
            visible2: false,
            visible3: false,
            visible4: false,
            visible5: false,
            visible6: false,
            visible7: false,
            index1: 0,
            day: ['每天', '隔日', '每周', '每月'],
            danwei: ['mg', 'g', 'ml', '片', '丸', '粒', '袋', '枚', '喷', '支', 'ug', '滴', '帖', '瓶', '张', '剂', 'IU'],
            count: ['1次', '2次', '3次', '4次', '5次', '6次'],
            useway: ['口服', '舌下含化', '吸入', '外敷', '直肠给药', '皮下注射', '肌肉注射', '静脉注射', '外用', '涂抹', '雾化', '灌肠', '滴眼'],
            day0: '',
            danwei0: '',
            count0: '',
            medId: '',
            i: 0,
            j: 0,
            k: 0,
            m: 0,
            supervisionType: '',
            ills: [{ name: '流行性感冒', state: 1 }, { name: '季节性感冒', state: 1 }, { name: '病毒性感冒', state: 1 }],
            subcode: 1,
            detailContent: "",
            priceArray: 0,
            updateId: '',
            chamedsmethod: [],
            ischinese: [],
            visible13:false,
            filteringArr:[],
            isPhaExamine: false, //是否需要药店药师审核
            // price:0,
            pickerVisible: false,
            // meiday:localStorage.getItem("meiday") ? localStorage.getItem("meiday")  : "一日",
            selectmedsmethod:localStorage.getItem("selectmedsmethod")|| [],
            visible10:false,
            unit: ['mg', 'g', 'ml', '片', '丸', '粒', '袋', '枚', '喷', '支', 'ug', '滴', '帖', '瓶', '张', '剂', 'IU'],//药品单位
            showAll:false,//不显示所有的中药
            meidayss:["一日",'两日','三日','四日','五日','六日','七日'],
            visible11:false,//用法用量选择弹窗提醒
            unitss:'',
            tipcode:0,//1表示点了确定，0表示未点
            visibleExcess:false,//超量弹窗
            excessArr:[],//超量JSON数组
            visibleCancel: false,//取消本单弹窗
            cancelReason: "",//取消本单原因
            isSumbit:false,//是否提交处方
            somegoodssystem:'',//系统推荐的用法用量

            initChineseMethod:[],//中药初始的用法用量
            initChineseMeds:[],//中药材初始的药品数量

            historyInfo:{},//疾病基本信息

            radioList:[
                {value:'药品超量',label:'药品超量'},
                {value:'药品用法用量超量',label:'药品用法用量超量'},
                {value:'重复用药',label:'重复用药'},
                {value:'疾病错误',label:'疾病错误'},
                {value:'患者年龄不符合',label:'患者年龄不符合'},
                {value:'患者信息错误',label:'患者信息错误'},
                {value:'其他',label:'其他'},
            ],
            otherReasonVis: false, //其他原因输入框
            isSetUseday:1,//是否设置用药天数，1：默认(未设置)，2:自定义
            setUseday:'',//设置的天数
        }
        this.button = true
    }


    /**
     * 医生用药方式自定义
     * 用药单位自填
     * @param {String} title 输入框标题
     * @param {String} stateName 修改的状态名称
     * @param {Array} stateData 修改状态的数据
     * @param {function} func 提交函数
     */
    useMedicineAndUnit = (title, popTip,stateName, stateData, func) => {
        prompt(title, popTip, [
            { text: '取消' },
            {
                text: '确认', onPress: value => {
                    if (!value) {
                        return
                    }
                    let newData = stateData
                    newData.unshift(value)
                    this.setState({
                        stateName: newData
                    })
                    func()
                },
            },
        ])
    }


    componentDidMount = async () => {
        this.initData()
    }
    initData = async () => {
        const {
          somegoods,
          medicinaldays,
          item,
          memberId,
          docterId,
          visibleExcess,
          excessArr,
          isSumbit,
          somegoodssystem,
          initChineseMethod,
          initChineseMeds,
          historyInfo
        } = this.state;

        // 查询店铺信息
        let resInfo = await selectPharmacyInfo({pharmacyId:item.pharmacy_id})
        if(resInfo.data[0][0].is_set_useday && resInfo.data[0][0].set_useday){
            this.setState({
                isSetUseday:resInfo.data[0][0].is_set_useday,
                setUseday:parseInt(resInfo.data[0][0].set_useday)
            })
        }

        let list = []
        // localStorage.removeItem('somegoods')
        if (getUrl('detailContent', this.props.location.search)) {
            this.setState({
                detailContent: getUrl('detailContent', this.props.location.search)
            })
            let res = await allMedicinal({ prescribingId: getUrl('detailContent', this.props.location.search) })

            if (res && res.data && res.data[0] && res.data[0].length) {
                list = res.data[0]
            }
        }

        let res0 = await prescribingPrice({ docterId })
        if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
            res0.data[0].forEach(i => {
                i.start_time = i.start_time.replace(/:/g, '')
                i.end_time = i.end_time.replace(/:/g, '')
            })
            this.setState({
                priceArray: res0.data[0][0].price
            })
        }

        let chamedsmethod = []
        let nowarr = []
        let newUsageArr=[]
        let sumPrice = 0
        if (item) {

            this.setState({
                historyInfo:item
            })

            // 查询问诊医生是否提交处方
            let isSumbitRes = await isSumbitPrescribing({ consultation_id: item.consultation_id })
            if(isSumbitRes && isSumbitRes.data && isSumbitRes.data[0] && isSumbitRes.data[0][0].total>0){
                this.setState({
                    isSumbit: true
                })
            }else{
                this.setState({
                    isSumbit: false
                })
            }

            // 查询该处方单上超量的药品
            let resExcess = await selectExcessNumber({ consultation_id: item.consultation_id })
            // console.log('resExcess',resExcess);
            if(resExcess.code= 1 && resExcess.data && resExcess.data[0] && resExcess.data[0][0].excess_med_number != null){
                // 只有109, 110, 114, 118, 111,113,116二级分类需要出现超量弹窗提示
                const checkIds = [109, 110, 114, 118, 111,113,116];
                let excessArrClassify = JSON.parse(resExcess.data[0][0].excess_med_number)
                excessArrClassify.forEach(itemA => {
                    if(checkIds.includes(itemA.classifyId)){
                        this.setState({
                            visibleExcess:true,
                            excessArr:JSON.parse(resExcess.data[0][0].excess_med_number)
                        })
                    }
                })
            }

            let res04 = await isChineseMeds({ consultation_id: item.consultation_id })
            this.setState({
                ischinese: res04.data[0],
            })

            if (getUrl('detailContent', this.props.location.search)) {
                list.forEach(item => {
                    let obj = {}
                    obj.medAllCount = item.med_all_count
                    obj.medLargeUsage = item.med_large_usage
                    obj.medLargeDay = item.med_large_day
                    obj.medFrequencyLarge = item.med_frequency_large

                    obj.medId = item.med_id
                    obj.isOtc = item.is_otc
                    obj.libraryId = item.library_id ? item.library_id : '0'
                    obj.contacts = item.contacts
                    obj.medQrcode = item.med_qrcode
                    obj.thirdPartyId = item.third_party_id
                    obj.medComName = item.med_com_name
                    obj.doseUnit = item.doseUnit
                    obj.medicinalName = item.medicinal_name
                    obj.medicinalSpec = item.medicinal_spec
                    obj.medicinalType = item.medicinal_type
                    obj.medicinalDay = item.medicinal_day
                    obj.medicinalDosage_form = item.medicinal_dosage_form
                    obj.medicinalPacking = item.medicinal_packing
                    obj.medicinalApplySymptoms = item.medicinal_apply_symptoms
                    obj.medicinalQuantity = item.medicinal_quantity
                    obj.medicinalFrequency = item.medicinal_frequency
                    obj.medicinalUsage = item.medicinal_usage
                    obj.medicinalMethod = item.medicinal_method
                    obj.medicinalSupplementary = item.medicinal_supplementary
                    obj.medicinalPrice = item.medicinal_price
                    obj.medApproval = item.med_approval
                    obj.medCompany = item.med_company
                    obj.useQuantity = item.medicinal_quantity
                    obj.packingUnit = item.packing_unit
                    obj.useSpec = ''
                    obj.useDay = item.medicinal_day
                    obj.medDayCountSys = item.medicinal_day ? item.medicinal_day : ''
                    obj.usePl = item.doseUnit
                    obj.useCount = '1次'
                    obj.canchange = 1
                    obj.useWay = item.medicinal_method
                    obj.supervisionType = item.supervision_type
                    sumPrice += item.medicinal_price * item.medicinal_quantity
                    nowarr.push(obj)

                    newUsageArr.push({...obj})
                })

                

                let nArr1 = nowarr.filter((currentValue, currentIndex, selfArr) => {
                    return selfArr.findIndex(x => x.medId === currentValue.medId) === currentIndex
                });

                let newUsageArr1 = newUsageArr.filter((currentValue, currentIndex, selfArr) => {
                    return selfArr.findIndex(x => x.medId === currentValue.medId) === currentIndex
                });

                this.setState({
                    somegoodssystem:newUsageArr1,
                    somegoods: nArr1,
                    supervisionType: list[0].supervision_type,
                    docterProposal: list[0].docterProposal == '请严格按原处方和药品说明书使用，严禁超量超范围使用；如用药过程中出现病情变化或其它不适症状，请立即停药并及时就医。' ? '' : list[0].docterProposal
                })

                let mtharr = [];
                let res10 = await chineseMethodDoc({ consultation_id: item.consultation_id })
                this.setState({
                    mtharr: res10.data[0][0]
                })


                if (res10.data[0].length) {
                    res10.data[0].map((i) => {
                        let obj2 = {}
                        obj2.co_demand = i.co_demand
                        obj2.meiday = i.meiday
                        obj2.daily = i.daily
                        obj2.each_dose = i.each_dose
                        obj2.med_method = i.med_method
                        obj2.med_time = i.med_time
                        obj2.at_time = i.at_time
                        obj2.unit = i.unit
                        chamedsmethod.push(obj2)
                    })
                    this.setState({
                        chamedsmethod: chamedsmethod
                    })
                }

            } else {
                let res = await allIllsnews({ consultationId: item.consultation_id,pharmacyId:item.pharmacy_id })

                // debugger
                if (res && res.data && res.data[1] && res.data[1].length) {
                    let arr = []
                    let arr1 = []
                    let params = {}

                    res.data[1].map(async item => {
                        let obj = {}
                        obj.medId = item.med_id
                        obj.isOtc = item.is_otc
                        obj.libraryId = item.library_id ? item.library_id : '0'
                        obj.contacts = item.contacts
                        obj.medQrcode = item.med_qrcode ? item.med_qrcode : ''
                        obj.thirdPartyId = item.third_party_id ? item.third_party_id : ''
                        obj.medComName = item.med_com_name ? item.med_com_name : ''
                        obj.doseUnit = item.doseUnit ? item.doseUnit : ''
                        obj.medicinalName = item.med_name
                        obj.medicinalSpec = item.med_spec
                        obj.medicinalType = item.med_type ? item.med_type : '西药'
                        obj.medicinalDay = item.med_day ? item.med_day : 1
                        obj.medicinalDosage_form = item.med_dosage_form ? item.med_dosage_form : ''
                        obj.medicinalPacking = item.med_packing
                        obj.medicinalApplySymptoms = item.med_apply_symptoms ? item.med_apply_symptoms : ''
                        obj.medicinalQuantity = item.med_quantity
                        obj.medicinalFrequency = item.med_frequency ? item.med_frequency : item.medicinalFrequency ? item.medicinalFrequency : "每天1次"
                        obj.medicinalUsage = item.med_usage ? item.med_usage : item.medicinalUsage ? item.medicinalUsage : ""
                        obj.medicinalMethod = item.med_method ? item.med_method : item.medicinalMethod ? item.medicinalMethod : "口服"
                        obj.medicinalSupplementary = item.notes ? item.notes : ""
                        obj.medicinalPrice = item.med_price
                        obj.medApproval = item.med_approval
                        obj.medCompany = item.med_company
                        obj.packingUnit = item.packing_unit
                        obj.useQuantity = 1
                        obj.useSpec = ''
                        obj.useDay = item.med_day ? item.med_day : 1
                        obj.medAllCount = item.med_all_count ? item.med_all_count : ''
                        obj.medLargeUsage = item.med_large_usage ? item.med_large_usage : ''
                        obj.medLargeDay = item.med_large_day ? item.med_large_day : ''
                        obj.medFrequencyLarge = item.med_frequency_large ? item.med_frequency_large : ''
                        obj.medDayCountSys = item.med_day ? item.med_day : ''
                        obj.usePl = '每天'
                        obj.useCount = '1次'
                        obj.canchange = item.med_frequency || item.med_usage ? 1 : 2
                        obj.useWay = item.med_method ? item.med_method : '口服'
                        obj.supervisionType = item.supervision_type
                        sumPrice += item.med_price * item.med_quantity
                        nowarr.push(obj)
                        newUsageArr.push({...obj})
                    })
                    let nArr1 = nowarr.filter((currentValue, currentIndex, selfArr) => {
                        return selfArr.findIndex(x => x.medId === currentValue.medId) === currentIndex
                    });

                    let newUsageArr1 = newUsageArr.filter((currentValue, currentIndex, selfArr) => {
                        return selfArr.findIndex(x => x.medId === currentValue.medId) === currentIndex
                    });

                    // 存初始的数量
                    let initarraymeds = []
                    nArr1.forEach((item,key) => {
                        initarraymeds.push({
                            medId: item.medId,
                            medQuantity: item.medicinalQuantity ? item.medicinalQuantity : 0,
                            medComName: item.medComName ? item.medComName : item.medicinalName,
                        })
                    })

                    this.setState({
                        somegoodssystem:newUsageArr1,
                        somegoods: nArr1,
                        supervisionType: res.data[0][0].supervision_type,
                        initChineseMeds: initarraymeds
                    })

                    // 查询该处方单上超量的药品
                    let resDataExcess = await selectExcessNumber({ consultation_id: item.consultation_id })
                    if(resDataExcess.code= 1 && resDataExcess.data && resDataExcess.data[0] && resDataExcess.data[0][0].excess_med_number != null){
                        let cancelArr = []
                        nArr1.forEach((item)=>{
                            cancelArr.push({
                                medId:item.medId,
                                medComName:item.medComName, //通用名
                                medicinalSpec:item.medicinalSpec,//规格
                                UsageDosage: item.medComName + item.medicinalSpec + "：" +item.medicinalFrequency + '，每次' + item.medicinalUsage + ',' + item.medicinalMethod + '，用药' + item.medicinalDay + '天'
                            })
                        })
                        let cancelArrItem = JSON.stringify(cancelArr)
                        localStorage.setItem("cancelArrItem", cancelArrItem)
                    }

                }
                let initMethod = []
                if (res && res.data && res.data[2]) {
                    res.data[2].map(async i => {
                        let obj2 = {}
                        obj2.co_demand = i.co_demand
                        obj2.meiday = i.meiday
                        obj2.daily = i.daily
                        obj2.each_dose = i.each_dose
                        obj2.med_method = i.med_method
                        obj2.med_time = i.med_time
                        obj2.at_time = i.at_time
                        obj2.unit = i.unit
                        chamedsmethod.push(obj2)
                        initMethod.push(obj2)
                    })
                    let nArr2 = chamedsmethod.filter((currentValue, currentIndex, selfArr) => {
                        return selfArr.findIndex(x => x.medId === currentValue.medId) === currentIndex
                    });
                    this.setState({
                        chamedsmethod: nArr2,
                    })
                }

            }
            this.setState({
                // nowarr: nowarr,
                medAmount: sumPrice
            })

            this.getdoctor()
            this.toIcd()
        }        
    }
    //获取医生信息
    getdoctor = async () => {
        const { docterPhone } = this.state
        const res = await getuserinfo({
            docterPhone
        })
        this.setState({
            doctortext: res.data[0][0]
        })
    }

    gobank = () => {
        // localStorage.removeItem('somegoods')
        window.history.back()
    }

    // 处理 medicinalQuantity 去掉 'g'
    cleanQuantityg = (quantity)=> {
        if (typeof quantity == 'string') {
            return parseFloat(quantity.replace('g', ''));
        }
        return quantity;
    }

    // 十八反十九畏提醒
    handleSubmitTip = async ()=>{
        const {
            somegoods,
            item, 
            chamedsmethod,
            ischinese,
            somegoodssystem,
            initChineseMeds,
            initChineseMethod,
        } = this.state

        let arraymed = []
        if(ischinese.length > 0){
            // 判断药品是否违反了十八反十九畏
            somegoods.forEach((item,key) => {
                arraymed.push({
                    medId: item.medId,
                    medQuantity: item.medicinalQuantity ? item.medicinalQuantity : 0,
                    medComName: item.medComName ? item.medComName : item.medicinalName,
                })
            })
            let restaboo = await chineseMedsTaboo({ array1:arraymed }) 
    
            if(restaboo.data.twoBind && restaboo.data.twoBind.length > 0 && ischinese.length>0){
                let combinedStrings = restaboo.data.twoBind.map(item => {
                  return `${item.medComNameb}与${item.med_com_name}；`;
                })
                alert('中药配伍禁忌',
                  <div>
                    <div style={{ textAlign:'left',fontSize:'14px'}}>
                      根据中药配伍禁忌十八反十九畏，以下药品有冲突：
                    </div>
                    <div style={{ color:'red',textAlign:'left',fontSize:'14px'}}>
                      {combinedStrings}
                    </div>
                  </div>,[
                  {
                    text: "取消",
                    onPress: () => {},
                  },
                  {
                    text: "继续提交",
                    onPress: () => {
                        // 修改医生继续提交十八反十九畏
                        updateChineseDocSubmit({ consultationId:item.consultation_id}).then((res)=>{
                            console.log('修改成功');
                        })
                      this.submit()
                    },
                  },
                ])
            }else{
                this.submit()
            }
        }else{
            this.submit()
        }
        
    }

    //点击提交
    submit = async () => {
        const {
            caPassTime,
            doctortext,
            icdValue,
            supervisionType,
            icdlists,
            somegoods,
            medAmount,
            item, subcode,
            patientName,
            patientAge,
            patientSex,
            docterDiagnosis, detailContent,
            docterProposal,visible13,
            chamedsmethod,
            ischinese,
            tipcode,
            visible11,
            docterId,
            somegoodssystem,
            historyInfo
        } = this.state

        const res = await selectConState({consultationId:item.consultation_id})
        let resState = res.data[0][0]
        // return 
        if((resState.consultation_stu == '-1' && resState.docter_id == null) || 
            (resState.consultation_stu != '-1' && resState.docter_id != docterId) ||
            (resState.consultation_stu == '3' && resState.docter_id == docterId)
        ){
            this.setState({
                visible13:true, //弹窗
            })
            return
        }else{
            let arr = []
            let arr1 = []
            if (subcode == 1) {

                let resChat = await docLine({
                    consultationId:item.consultation_id,
                    token1: localStorage.getItem("jwtTokendoc"),
                });
                let dataChat = resChat.data[2]
                let lastDataChat = dataChat[dataChat.length - 1].detailContent;

                let lastDataChatTime = dataChat[dataChat.length - 1].createTime;
                let lastChatTime =moment(lastDataChatTime).format("YYYY-MM-DD HH:mm:ss") //最后一个时间
                let delayTime = moment(lastChatTime).add( 1 , 'seconds')
                let chatTime = delayTime.format("YYYY-MM-DD HH:mm:ss")  //最终需传递时间

                let reDialogDataThree = await dialogueTemplate({dialogSequence:3})
                let isInResData = reDialogDataThree.data[0].some(item => item.template_content == lastDataChat); //查看是否存在是否补充

                if(isInResData){
                    const resChatOne = await addnews({
                        consultationId: item.consultation_id,
                        Identity: 1,
                        detailType: 1,
                        detailContent: `没有补充`,
                        createTime:chatTime
                    });
                }

                if (icdlists.length) {
                    icdlists.forEach(i => {
                        let params0 = {}
                        if (i.state == 1) {
                            params0.icdName = i.icdName
                            params0.icdValue = i.icdValue
                            arr.push(i.icdName)
                            arr1.push(params0)
                        }
                    })
                } else {
                    Toast.info('请先添加疾病诊断信息')
                }
                let array = []
                for (let i = 0; i < somegoods.length; i++) {
                    let params = {}
                    params.medname = somegoods[i].medicinalName
                    params.medspec = somegoods[i].medicinalSpec
                    array.push(params)
                    // console.log(/^\d+$/.test(somegoods[i].medicinalUsage),'000000');
                    // return
                    if ((!somegoods[i].medicinalFrequency || !somegoods[i].medicinalUsage) && ischinese.length == 0) {
                        Toast.info('药品用法用量有误，请修改后再提交')
                        return;
                    }

                    if(somegoods[i].medicinalUsage){
                        let strnum = /^\d+$/.test(somegoods[i].medicinalUsage)
                        if(strnum){
                            Toast.info('药品用法用量有误，请修改后再提交')
                            return;
                        }
                    }

                    if((somegoods[i].medicinalQuantity == '0' || somegoods[i].medicinalQuantity == '' || somegoods[i].medicinalQuantity == 'g') && ischinese.length){
                        Toast.info('药品不能为0g')
                        return;
                    }
                }

                if(ischinese.length){
                    if(chamedsmethod[0].co_demand=="" || chamedsmethod[0].daily == "" || chamedsmethod[0].at_time== "" || chamedsmethod[0].each_dose == "" || chamedsmethod[0].unit ==""){
                        Toast.info('请正确填写药品用法用量')
                        return;
                    }
                    if(chamedsmethod[0].med_method == ""){
                        Toast.info('请正确填写药品用药方式')
                        return;
                    }
                    if(chamedsmethod[0].unit != 'ml' && tipcode == 0){
                        this.setState({
                            visible11:true
                        })
                        return
                    }
                }


                let medArray = []
                somegoods.forEach(item => {
                    medArray.push({
                        medApprovalNumber: item.medApproval ? item.medApproval : null,
                        medName: item.medicinalName ? item.medicinalName : item.medComName,
                        medComname: item.medComName,
                        medCompany: item.medCompany ? item.medCompany : null,
                        isOtc: item.isOtc == 2 ? false : true,
                        medType: item.medicinalType ? item.medicinalType : null,
                        medSpec: item.medicinalSpec ? item.medicinalSpec : null,
                        useQuantity: item.medicinalQuantity,
                        medPackage: item.medicinalPacking,
                        medQuantity: item.medicinalQuantity,
                        medPackageUnit: item.packingUnit,
                        medDoseUnit: item.medicinalUsage.replace(/[0-9]+/g, ""),
                        medDosageForm: item.medicinalDosage_form,
                        medDay: item.useDay,
                        medDosageFrequency: item.medicinalFrequency,
                        medDosage: item.medicinalUsage,
                        medMethod: item.medicinalMethod,
                        useDoseUnit: item.medicinalUsage.replace(/[0-9]+/g, ""),
                        useDay: item.useDay,
                        useFrequency: item.medicinalFrequency.substring(0, 2),
                        useTimes: item.medicinalFrequency.substring(2),
                        useMethod: item.medicinalMethod,
                        medAllCount:item.medAllCount,//药品总量
                        medLargeUsage:item.medLargeUsage,//药品最大用量
                        medLargeDay:item.medLargeDay,//药品最大用药天数
                        medFrequencyLarge:item.medFrequencyLarge//药品频率

                    })
                })

                let diagnoseInfo = {
                    updatetype: 1,
                    age: item.patient_age,
                    gender: item.patientSex == '男' ? 1 : 2,
                    pregnant: "",
                    lactation: "",
                    allergyHistory: [item.allergic_history_note],
                    liverDisease: item.abnormal_liver_function == 0 ? false : true,
                    kidneyDisease: item.abnormal_renal_function == 0 ? false : true,
                    patientIllness: item.consultation_detail,
                    doctorDiagnosis: (arr.toString()).replace(/,/g, '|'),
                    doctorProposal: "请严格按原处⽅和药品说明书使⽤，严禁超量超范围使⽤；如⽤药过程中出现病情变化或其它不适症状，请⽴即停药并及时就医。"
                }

                if(ischinese.length > 0){
                    if(arr.length > 3){
                        Toast.info('只能添加总数不超过3种的疾病')
                        return
                    }
                }


                if(arr.length > somegoods.length && !ischinese.length){
                    Toast.info('疾病诊断存在问题，请检查修改')
                    return
                }

                // 判断病史信息是否完整
                if(historyInfo.past_history == 1 && !historyInfo.past_history_note){
                    Toast.info('有无疾病史信息存在问题，请检查')
                    return
                }else if(historyInfo.allergic_history == 1 && !historyInfo.allergic_history_note){
                    Toast.info('有无过敏史信息存在问题，请检查')
                    return
                }else if(historyInfo.bodytemperature == 1 && !historyInfo.bodytemperaturenum){
                    Toast.info('体温信息存在问题，请检查')
                    return
                }

                if (arr.length == 0) {
                    Toast.info('请选择疾病诊断')
                } else {
                    if (detailContent) {
                        if (getUrl('updateId', this.props.location.search)) {
                            alert('确认修改处方？', '修改后原处方单失效，失效处方单将不计入单量统计中', [
                                {
                                    text: '取消', onPress: () => {
                                        console.log('取消')
                                    }
                                },
                                {
                                    text: '确认', onPress: () => {
                                        if (this.button) {
                                            this.button = false
    
                                            this.setState({
                                                subcode: 2
                                            }, async () => {
                                                let res = await docUpdate({ prescribingId: detailContent })
                                                if (res && res.data && res.data[0] && res.data[0].length) {
                                                    if (res.data[0][0].prescribingState == 0) {
                                                        this.submitContinue()
                                                    } else {
                                                        Toast.info('原处方单已被药师审核，无法继续修改')
                                                        this.props.history.push("/offlineDoctorchat")
                                                    }
                                                }
                                            })
                                        } else {
                                            Toast.info('请勿重复提交')
                                        }
                                    }
                                },
                            ])
                        } else {
                            if (this.button) {
                                this.button = false
                                this.setState({
                                    subcode: 2
                                }, async () => {
                                    this.submitContinue()
                                })
                            } else {
                                Toast.info('请勿重复提交')
                            }
                        }
                    } else {
                        let res000 = await getSamemeds({ consultationId: item.consultation_id, array })

                        if (res000 && res000.data && res000.data.length > 0) {
                            Toast.info('有相同药品的处方单不能重复提交')
                        } else {
                            if (this.button) {
                                this.button = false
                                this.setState({
                                    subcode: 2
                                }, async () => {                            
                                    this.submitContinue()
                                })
                            }
                        }
                    }
                }
            } else {
                Toast.info('请勿重复提交')
            }
        }

    }
    submitContinue = async () => {
        const {
            caPassTime,
            doctortext,
            icdValue,
            supervisionType,
            icdlists,
            somegoods,
            medAmount,
            item, subcode,
            patientName,
            patientAge,
            patientSex, priceArray,
            docterDiagnosis, detailContent,
            docterProposal, docterProposal1,
            docterProposal2,
            chamedsmethod,
            ischinese,
            docterId,
            somegoodssystem,
            initChineseMeds,
            initChineseMethod,
            // price,
            historyInfo
        } = this.state       

        if(ischinese.length){
            // 修改订单信息
            for (let i = 0; i < somegoods.length; i++) {
                // console.log('somegoods[i].useQuantity',typeof(somegoods[i].medicinalQuantity) == 'string');
                updateOrderinfo({
                    medQuantity:typeof(somegoods[i].medicinalQuantity) == 'string' ? parseInt(somegoods[i].medicinalQuantity.slice(0, -1)) : somegoods[i].medicinalQuantity,
                    medId:somegoods[i].medId,
                    consultationId:item.consultation_id
                }).then((res)=>{
                    // console.log('修改订单',res);
                }) 
            }
        }

        let updateQuantityInit = 1 //1：克数为修改都相等，2：存在不同，修改了
        if(ischinese.length > 0){
            
            // 查询中药材初始用法用量
            let resultinit = await selectInitMethodChinese({ consultationId:item.consultation_id })
            let initmethod = resultinit.data[0][0]
            // 判断这几个字段是否有不同
            const fieldsToCheck = ["at_time", "co_demand", "daily", "each_dose", "med_method", "med_time", "meiday", "unit"];
            // 判断是否有不同，如果初始和提交的存在字段不同，则返回true,否则false
            const isAnyFieldDifferent = chamedsmethod.some(updateItem => {
                return fieldsToCheck.some(field => initmethod[field] !== updateItem[field]);
            });

            // 判断医生是否修改了药材克数
            // 判断两数组中 medId 对应的 medicinalQuantity 是否相等
            const hasDifferentQuantities = initChineseMeds.some(initItem => {
                const updateItem = somegoods.find(item => item.medId == initItem.medId)
                if (updateItem) {
                    const initQuantity = this.cleanQuantityg(initItem.medQuantity)
                    const updateQuantity = this.cleanQuantityg(updateItem.medicinalQuantity)
                    // 如果数量不相等，则返回 true，updateQuantityInit 为 1
                    return initQuantity !== updateQuantity
                }
                return true 
            })
            
            // 如果修改了数量或者修改了用法用量，那么updateQuantityInit 为 2，
            if (hasDifferentQuantities || isAnyFieldDifferent) {
                updateQuantityInit = 2
            }

            if(updateQuantityInit == 2){
                // 医生修改了用法用量或者克数
                let updateResInit = await updateInitQuantityMethod({ consultationId:item.consultation_id })
            }
        }

        let arr = []
        let arr1 = []
        let arrvalue = []
        icdlists.forEach(i => {
            let params0 = {}
            if (i.state == 1) {
                params0.icdName = i.icdName
                params0.icdValue = i.icdValue
                arr.push(i.icdName)
                arr1.push(params0)
                arrvalue.push({ icdValue: i.icdValue })
            }
        })

        let medicationSafety = []
        if (ischinese.length) {
            chamedsmethod.map((i) => {
                let atTime;
                if (i.med_time == 1) {
                    atTime = '饭前用药';
                } else if (i.med_time == 2) {
                    atTime = '饭后用药';
                } else if (i.med_time == 3) {
                    atTime = '睡前用药';
                } else if (i.med_time == 4) {
                    atTime = '晨起用药';
                }

                medicationSafety.push('用法用量：' + '共' + i.co_demand + '剂，' + i.meiday + i.daily + '剂，每剂分' + i.each_dose + '次使用，每次' + i.at_time + i.unit +'，' + i.med_method + '，' + atTime);
            })
        } else {
            somegoods.forEach((item, index) => {
                medicationSafety.push((index + 1) + '、' + item.medComName + '(' + item.medicinalSpec + ')' + item.medicinalMethod +
                    '，每次' + item.medicinalUsage + '，' + item.medicinalFrequency + ' ')
            })

            somegoodssystem.forEach((item) => {
                const matchingItem = somegoods.find((el) => el.medId == item.medId);
                if (matchingItem) {
                  matchingItem.sysMedMethod = (item.canchange == 1) ? item.medicinalMethod : item.medicinalMethod;
                  matchingItem.sysMedUsage = (item.canchange == 1) ? item.medicinalUsage : item.useQuantity;
                  matchingItem.sysMedDay = (item.canchange == 1) ? item.medDayCountSys : item.useDay;
                  matchingItem.sysMedFrequency = (item.canchange == 1) ? item.medicinalFrequency : item.usePl + item.useCount;
                } else {
                  somegoods.push({ ...item, medId: item.medId });
                }
            });
        }

        let price = priceArray
        let res0 = await allIcd(item.consultation_id)
        if (res0 && res0.data && res0.data[0] && res0.data[0].length) {
            docAllicdnews({ arrvalue: arrvalue, consultationId: item.consultation_id, type: 2 }).then(res => {
            })
        } else {
            docAllicdnews({ arrvalue: arrvalue, consultationId: item.consultation_id, type: 1 }).then(res => {
            })
        }

        let nArr1 = somegoods.filter((currentValue, currentIndex, selfArr) => {
            return selfArr.findIndex(x => x.medId === currentValue.medId) === currentIndex
        });
        let obj;

        if (ischinese.length) {
            obj = {
                docterId: doctortext.docter_id,
                prescribingPrice: price,
                userId: item.user_id,
                medicalnsuranceCoordination: item.medical_nsurance_coordination,
                patientName: item.patientName,
                patientAge: item.patient_age,
                patientSex: item.patientSex,
                docterDepartment: doctortext.depName,
                patientIllness: item.consultation_detail,
                docterDiagnosis: arr.toString().replace(/\,/g, '|'),
                docterProposal: docterProposal ? docterProposal : docterProposal1,
                docterName: doctortext.docter_name,
                pharmacistName: '',
                originalPrescribing: '',
                eSeal: '',
                fatherId: detailContent,
                pharmacyId: item.pharmacy_id,
                pharmacyName: item.pharmacy_name,
                consultationId: item.consultation_id,
                array: nArr1,
                contacts: somegoods[0].contacts,
                medAmount: medAmount,
                isRetail: 1,
                recipeType: supervisionType,
                icdValue,
                is_chinese_meds: 1,
                medicationSafety: medicationSafety.toString() + '。考虑用药安全:1、请您再次确认用过该药品且无不良反应。如未用过，请到实体医疗机构就诊;2、应严格按原处方和《药品说明书》使用(严格对照用法用量、不良反应、禁忌、注意事项和药物相互作用)。3、如病情发生变化，或用药期间如有不适，请立即停药并及时到当地医院就诊。',
                ischinese:1,
                co_demand : chamedsmethod[0].co_demand,
                at_time : chamedsmethod[0].at_time,
                daily : chamedsmethod[0].daily,
                each_dose : chamedsmethod[0].each_dose,
                med_method : chamedsmethod[0].med_method,
                med_time : chamedsmethod[0].med_time,
                meiday : chamedsmethod[0].meiday,
                unit : chamedsmethod[0].unit,
            }
        } else {
            obj = {
                docterId: doctortext.docter_id,
                prescribingPrice: price,
                userId: item.user_id,
                medicalnsuranceCoordination: item.medical_nsurance_coordination,
                patientName: item.patientName,
                patientAge: item.patient_age,
                patientSex: item.patientSex,
                docterDepartment: doctortext.depName,
                patientIllness: item.consultation_detail,
                docterDiagnosis: arr.toString().replace(/\,/g, '|'),
                docterProposal: docterProposal ? docterProposal : docterProposal1,
                docterName: doctortext.docter_name,
                pharmacistName: '',
                originalPrescribing: '',
                eSeal: '',
                fatherId: detailContent,
                pharmacyId: item.pharmacy_id,
                pharmacyName: item.pharmacy_name,
                consultationId: item.consultation_id,
                array: nArr1,
                contacts: somegoods[0].contacts,
                medAmount: medAmount,
                isRetail: 1,
                recipeType: supervisionType,
                icdValue,
                medicationSafety: medicationSafety.toString() + '。考虑用药安全:1、请您再次确认用过该药品且无不良反应。如未用过，请到实体医疗机构就诊;2、应严格按原处方和《药品说明书》使用(严格对照用法用量、不良反应、禁忌、注意事项和药物相互作用)。3、如病情发生变化，或用药期间如有不适，请立即停药并及时到当地医院就诊。'
            }

        }


        // 医生修改患者病史信息
        let updateResHis = await updatePatientHistoryInfo({
            consultationId: item.consultation_id,
            past_history: historyInfo.past_history,  //是否存在疾病史
            past_history_note: historyInfo.past_history == '0' ? '' : historyInfo.past_history_note,    //疾病史内容
            allergic_history: historyInfo.allergic_history,   //是否存在过敏史
            allergic_history_note: historyInfo.allergic_history == '0' ? '' : historyInfo.allergic_history_note,   //过敏史内容
            abnormal_liver_function: historyInfo.abnormal_liver_function,   //肝肾功能是否异常
            bodytemperature: historyInfo.bodytemperature,   //体温是否正常
            bodytemperaturenum: historyInfo.bodytemperature == '0' ? '' : historyInfo.bodytemperaturenum,   //温度
        })

        if(updateResHis && updateResHis.data && updateResHis.data[1]){
            localStorage.setItem("item", JSON.stringify(updateResHis.data[1][0]))
        }


        let test = ischinese.length == 0 ? 1: 2
        
        let res = await submitPrescribing({
            consultationId: item.consultation_id,
            detailContent: arr.toString(), 
            obj: obj
        })

        if(typeof(res.data) == 'string'){
            let str = res.data.substring(0, 9)
            // console.log(str,'str');
            if(str == '[API执行引擎]'){
                Toast.info('当前药品信息有误，请联系技术人员处理后再提交')
                return 
            }
        }else if (res && res.code == 1) {
            localStorage.removeItem('docterDiagnosis')
            localStorage.removeItem('icdValue')
            localStorage.removeItem('docterProposal')
            localStorage.removeItem('somegoods')
            localStorage.removeItem('stagingsomegoods')

            let prescribingId = ''
            this.props.history.replace("/offlineDoctorchat")
            if (res.data[1][0].daynum == 1) {
                prescribingId = res.data[4][0].prescribingId
            } else {
                prescribingId = res.data[1][0].prescribingId
            }

            if( test == 1 ){
                let resRecord = await recordUsage({
                    consultationId: item.consultation_id,
                    prescribingId:prescribingId,
                    obj: obj
                })
            }
            this.props.history.push(`/prescribing/prescription?prescribingId=${prescribingId}&type=${1}`)
        }

            
    }
    //获取输入框用户信息
    changeuserinfo = (e, text) => {
        switch (text) {
            case 4:
                this.setState({
                    // docterDiagnosis: e.target.value
                    icdone: e.target.value
                })

                break;
            case 5:
                if ((e.target.value.replace(/\s*/g, "").replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "")).length <= 60) {
                    this.setState({
                        docterProposal: e.target.value.replace(/\s*/g, "").replace(/\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]/g, "")
                    }, () => {
                        localStorage.setItem("docterProposal", this.state.docterProposal)
                    })
                } else {
                    Toast.info('医生建议内容不能超过60字')
                }
                break;
        }
    }
    nosubmit = () => {
        Toast.info('请勿重复提交')
    }

    toIcd = async () => {
        const { item, icdlists } = this.state

        // if (!icdList.length){
        let res = await allIcd(item.consultation_id)

        if (res && res.data && res.data[0]) {
            if (res.data[0].length) {
                // console.log(JSON.parse(res.data[0][0].electronic_icd))
                let arr = JSON.parse(res.data[0][0].electronic_icd)
                let arr2 = []
                let arr3 = []
                arr.map(item => {
                    item.state = 1
                    arr2.push(item.icdName)
                    arr3.push(item.icdValue)
                })
                this.setState({
                    icdlists: arr,
                    // visible3: true,
                    docterDiagnosis: arr2.toString().replace(/\,/g, '; '),
                    icdValue: arr3.toString().replace(/\,/g, '|'),
                })
            } else {
                Toast.info('请先选择诊断结果')
            }
        }
    }
    delIcd = (e, el) => {
        const { page, size, icdList, icdone, icdlists,item } = this.state
        e.stopPropagation()

        // console.log(el,item);
        // return 

        alert('确认删除此病历？', el.icdName, [
            {
                text: '取消', onPress: () => {
                    console.log('取消')
                }
            },
            {
                text: '确认', onPress: () => {
                    // 记录医生自己添加疾病记录
                    docAddDisRecord({
                        consultationId: item.consultation_id,
                        docterId: item.docter_id,
                        docterName: item.docter_name,
                        patientName: item.patientName,
                        electronicIcdOld: item.electronic_icd,
                        electronicIcdNew: `${el.icdName}_${el.icdValue}`,
                        pharmacyId: item.pharmacy_id,
                        recordAction: `删除疾病--${el.icdName}`,
                    }).then((res)=>{
                        console.log('删除疾病');
                    })

                    let arr = []
                    let arr1 = []
                    let arr2 = []
                    icdlists.map(j => {
                        if (j.icdValue != el.icdValue) {
                            arr2.push(j)
                        }
                    })
                    this.setState({
                        icdlists: arr2
                    })
                }
            },
        ])
    }
    setIcd = (e, el) => {
        const { icdList, icdlists,item } = this.state
        e.stopPropagation()
        if(el){
            alert("选择疾病提醒", `确认添加：【${el.icdName}】`, [
                {
                    text: "取消",
                    onPress: () => {
                        this.setState({
                            visible5: false
                        })
                    },
                },
                {
                    text: "确定",
                    onPress: () => {
                        // 记录医生自己添加疾病记录
                        docAddDisRecord({
                            consultationId: item.consultation_id,
                            docterId: item.docter_id,
                            docterName: item.docter_name,
                            patientName: item.patientName,
                            electronicIcdOld: item.electronic_icd,
                            electronicIcdNew: `${el.icdName}_${el.icdValue}`,
                            pharmacyId: item.pharmacy_id,
                            recordAction: `添加疾病--${el.icdName}`,
                        }).then((res)=>{
                            console.log('添加成功');
                        })

                        el.state = 1
                        if (icdlists.length) {
                            let code = 0
                            icdlists.forEach(i => {
                                if (i.icdValue == el.icdValue) {
                                    code = 1
                                    Toast.info('此病例已添加，请不要重复添加')
                                    return;
                                }
                            })
                            if (!code) {
                                this.setState({
                                    icdlists: [...icdlists, el],
                                    visible5: false
                                })
                            }
                        } else {
                            this.setState({
                                icdlists: [...icdlists, el],
                                visible5: false
                            })
                        }
                    },
                },
            ]);
        }

    }
    changeState = (item) => {
        const { icdList, icdlists } = this.state
        if (item.state == 1) {
            item.state = 2
        } else {
            item.state = 1
        }
        this.setState({
            icdlists: [...icdlists],
        })
    }
    //确认病历
    onSure = () => {
        const { icdList } = this.state
        let arr = []
        let arr1 = []
        let arr2 = []
        icdList.map(item => {
            if (item.state == 2) {
                arr.push(item)
                arr1.push(item.icdName)
                arr2.push(item.icdValue)
            }
        })

        if (arr1.length) {
            this.setState({
                visible3: false,
                docterDiagnosis: arr1.length ? arr1.toString().replace(/\,/g, '|') : '',
                icdValue: arr2.length ? arr2.toString().replace(/\,/g, '|') : '',
            })
            localStorage.setItem("docterDiagnosis", arr1.toString().replace(/\,/g, '|'))
            localStorage.setItem("icdValue", arr2.toString().replace(/\,/g, '|'))
        } else {
            Toast.info('请先选择诊断结果')
        }
    }
    //搜索病历
    onSearch = async () => {
        const { page, size, icdone, icdList, icdlists } = this.state
        const params = {
            content: icdone,
            page: 1,
            size: size,
        }
        let res = await getIcdCode(params)
        if (res && res.data && res.data[0]) {
            res.data[0].forEach(i => {
                icdlists.forEach(j => {
                    if (i.id == j.id) {
                        i.state = 2
                    }
                })
            })
            this.setState({
                icdList: res.data[0],
                page: 1,
                visible5: true
            })

        }
    }
    // 获取单位
    dianji1 = (index) => {
        const { danwei, i } = this.state
        this.setState({
            i: index
        })
    }
    // 获取频率
    dianji = (index) => {
        const { day } = this.state
        this.setState({
            j: index
        })
    }
    // 隐藏频率
    closeDay = () => {
        const { day, j, medId, somegoods } = this.state
        somegoods.map(item => {
            if (item.medId == medId) {
                item.usePl = day[j]
                item.medicinalFrequency = day[j] + item.useCount
                this.setState({
                    somegoods,
                    visible: false,
                    j: 0
                })
                // localStorage.setItem("somegoods", somegoods)
            }
        })

    }
    // 获取次数
    dianji2 = (index) => {
        const { day } = this.state
        this.setState({
            k: index
        })
    }
    // 隐藏次数
    closeCount = () => {
        const { count, k, medId, somegoods } = this.state
        somegoods.map(item => {
            if (item.medId == medId) {
                item.useCount = count[k]
                item.medicinalFrequency = item.usePl + count[k]
                this.setState({
                    somegoods,
                    visible2: false,
                    k: 0
                })
                // localStorage.setItem("somegoods", somegoods)
            }
        })

    }
    // 获取次数
    dianji4 = (index) => {
        const { day } = this.state
        this.setState({
            m: index
        })
    }
    // 隐藏次数
    closeway = () => {
        const { useway, m, medId, somegoods } = this.state
        somegoods.map(item => {
            if (item.medId == medId) {
                item.useWay = useway[m]
                item.medicinalMethod = useway[m]
                this.setState({
                    somegoods,
                    visible4: false,
                    m: 0
                })
                // localStorage.setItem("somegoods", somegoods)
            }
        })

    }
    // 隐藏单位
    closeDanwei = () => {
        const { danwei, i, medId, somegoods } = this.state
        // console.log(danwei[i],'993');
        somegoods.map(item => {
            if (item.medId == medId) {
                item.useSpec = danwei[i]
                item.doseUnit = danwei[i]
                item.medicinalUsage = item.useQuantity + danwei[i]
                this.setState({
                    somegoods,
                    visible1: false,
                    i: 0
                })
            }
        })
    }
    getvalue = async (e, index, text) => {
        const { somegoods } = this.state
        // console.log(e.target.value,index,text,'1008============');
        // console.log(somegoods[index].medicinalUsage,'1009-----');
        // console.log(somegoods[index].useSpec,'1011---');

        switch (text) {
            case 'way'://用药方法
                somegoods[index].useWay = e.target.value.replace(/\s*/g, "")
                somegoods[index].medicinalMethod = e.target.value.replace(/\s*/g, "")
                this.setState({
                    somegoods
                })
                break;
            case 'medicinalSupplementary'://备注
                somegoods[index].medicinalSupplementary = e.target.value.replace(/\s*/g, "")
                this.setState({
                    somegoods
                })
                break;
            case 'quantity':
                somegoods[index].useQuantity = e.target.value.replace(/\s*/g, "")
                // somegoods[index].medicinalUsage = somegoods[index].medicinalUsage ? e.target.value.replace(/\s*/g, "") : e.target.value.replace(/\s*/g, "") + somegoods[index].useSpec
                somegoods[index].medicinalUsage = e.target.value.replace(/\s*/g, "")
                // somegoods[index].medicinalUsage = somegoods[index].medicinalUsage

                const str = somegoods[index].medicinalUsage;
                const isAllDigits = /^\d+$/.test(str);
                if (isAllDigits) {
                    // 字符串是全数字
                    // console.log('字符串是全数字');
                    somegoods[index].medicinalUsage = e.target.value.replace(/\s*/g, "") + somegoods[index].useSpec
                } else {
                    // 字符串不是全数字
                    // console.log('字符串不是全数字');
                    somegoods[index].medicinalUsage = e.target.value.replace(/\s*/g, "")
                }
                this.setState({
                    somegoods
                })
                break;
            case 'quantity2':
                if (e.target.value) {
                    somegoods[index].medicinalQuantity = e.target.value.replace(/\s*/g, "")
                } else {
                    somegoods[index].medicinalQuantity = ''
                }
                this.setState({
                    somegoods
                })
                break;
            case 'medfrequency':
                if (e.target.value) {
                    somegoods[index].medicinalFrequency = e.target.value.replace(/\s*/g, "")
                } else {
                    somegoods[index].medicinalFrequency = ''
                }
                this.setState({
                    somegoods
                })
                break;
            case 'chineseQuantity':
                if(e.target.value){
                    somegoods[index].medicinalQuantity = e.target.value.replace(/\s*/g, "")
                }else{
                    somegoods[index].medicinalQuantity = ''
                }
                this.setState({
                    somegoods
                })
                break;
        }
    }
    changenum2 = (text, index) => {
        const { somegoods } = this.state
        // console.log(text,'1067============');
        let list = somegoods[index]
        // console.log(list,'1069=====');
        switch (text) {
            case 'quantityreduce'://单次用量
                if (list.useQuantity > 1) {
                    list.useQuantity = parseInt(list.useQuantity) - 1
                    list.medicinalUsage = (parseInt(list.useQuantity)) + list.useSpec
                } else {
                    list.useQuantity = 1
                    list.medicinalUsage = 1 + list.useSpec
                }
                this.setState({
                    somegoods
                })
                // localStorage.setItem("somegoods", somegoods)
                break;
            case 'quantityadd':
                list.useQuantity = parseInt(list.useQuantity) + 1
                list.medicinalUsage = (parseInt(list.useQuantity)) + list.useSpec
                this.setState({
                    somegoods
                })
                // localStorage.setItem("somegoods", somegoods)
                break;
            case 'dayreduce'://用药天数
                if (list.useDay > 1) {
                    list.useDay = parseInt(list.useDay) - 1
                    list.medicinalDay = parseInt(list.useDay)
                } else {
                    list.useDay = 1
                    list.medicinalDay = 1
                }
                this.setState({
                    somegoods
                })
                // localStorage.setItem("somegoods", somegoods)
                break;
            case 'dayadd':
                list.useDay = parseInt(list.useDay) + 1
                list.medicinalDay = parseInt(list.useDay)
                this.setState({
                    somegoods
                })
                // localStorage.setItem("somegoods", somegoods)
                break;
        }
    }
    //分页搜索病历
    pageMedlist = async () => {
        const { page, size, icdList, icdone } = this.state
        const params = {
            content: icdone,
            page: page + 1,
            size: size,
        }
        let res = await getIcdCode(params)
        if (res && res.data && res.data[0]) {
            this.setState({
                icdList: [...icdList, ...res.data[0]],
                page: page + 1
            })
        }
    }
    onscroll = e => {
        if (e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight < 10) {
            this.pageMedlist()
        }
    }
    showUseways = (index1) => {

        this.setState({
            visible6: true,
            index1
        }, () => {
            let timer = setTimeout(() => {
                this.setState({
                    visible6: false
                }, () => {
                    window.clearTimeout(timer)
                })
            }, 8000)
        })
    }
    closev5 = (e) => {
        e.stopPropagation()
        this.setState({
            visible5: false
        })
    }
    showV4 = (medId) => {
        this.setState({ visible4: true, medId: medId })
    }
    showV1 = (medId) => {
        this.setState({ visible1: true, medId: medId })
    }
    showV01 = (medId) => {
        this.setState({ visible: true, medId: medId })
    }
    showV2 = (medId) => {
        this.setState({ visible2: true, medId: medId })
    }
    modifyingDrugs = () => {
        const { somegoods, updatesomegoods } = this.state
        let array = []
        somegoods.forEach(item => {
            let params = {}
            params.medId = item.medId
            params.medicinalQuantity = item.medicinalQuantity
            array.push(params)
        })
        this.setState({ visible7: true, updatesomegoods: array })
        localStorage.setItem("stagingsomegoods", JSON.stringify(somegoods))
    }
    toDelete = (medId) => {
        const { somegoods, updatesomegoods } = this.state
        let array = []
        if (somegoods.length > 1) {
            alert('弹窗提示', '删除药品后不能恢复，请谨慎修改', [
                {
                    text: '取消', onPress: () => {
                        console.log('取消')
                    }
                },
                {
                    text: '确认', onPress: () => {
                        somegoods.forEach(item => {
                            if (item.medId != medId) {
                                array.push(item)
                            }
                        })
                        this.setState({
                            somegoods: array
                        })
                    }
                },
            ])
        } else {
            Toast.info('药品无法继续删除')
        }
    }
    reduceCount = (medId, count) => {
        const { somegoods, updatesomegoods } = this.state
        if (count == 1) {
            let array = []
            if (somegoods.length > 1) {
                alert('弹窗提示', '删除药品后不能恢复，请谨慎修改', [
                    {
                        text: '取消', onPress: () => {
                            console.log('取消')
                        }
                    },
                    {
                        text: '确认', onPress: () => {
                            somegoods.forEach(item => {
                                if (item.medId != medId) {
                                    array.push(item)
                                }
                            })
                            this.setState({
                                somegoods: array
                            })
                        }
                    },
                ])
            } else {
                Toast.info('药品无法继续减少')
            }
        } else {
            somegoods.forEach(item => {
                if (item.medId == medId) {
                    item.medicinalQuantity = count - 1
                }
            })
            this.setState({
                somegoods: somegoods
            })
        }
    }
    addCount = (medId, count) => {
        const { somegoods } = this.state
        somegoods.forEach(item => {
            if (item.medId == medId) {
                item.medicinalQuantity = count + 1
            }
        })
        this.setState({
            somegoods
        })
    }
    toRefuse = () => {
        const { somegoods, updatesomegoods } = this.state
        this.setState({
            visible7: false,
            somegoods: JSON.parse(localStorage.getItem("stagingsomegoods"))
        })

    }
    toSureupdate = () => {
        const { updatesomegoods } = this.state
        this.setState({
            visible7: false,
        })
    }
    // 复制客服电话
    copykefuhone = () => {
        const spanText = document.getElementById('docterPhone').innerText;
        const oInput = document.createElement('input');
        oInput.value = spanText;
        document.body.appendChild(oInput);
        oInput.select(); // 选择对象
        document.execCommand('Copy'); // 执行浏览器复制命令
        oInput.className = 'oInput';
        oInput.style.display = 'none';
        document.body.removeChild(oInput);
        Toast.info('复制成功')
    }
    hanldSure = () =>{
        const { visible13 } = this.state
        this.setState({
          visible13:false
        })
        this.props.history.replace('/')
    }
    handleInputChange = async (e,text) => {
        const {chamedsmethod} = this.state
        switch(text){
            case 'allarr':
                if (e.target.value) {
                    chamedsmethod[0].co_demand = e.target.value.replace(/\s*/g, "")
                } else {
                    chamedsmethod[0].co_demand = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;
            case 'dayarr':
                if (e.target.value) {
                    chamedsmethod[0].daily = e.target.value.replace(/\s*/g, "")
                } else {
                    chamedsmethod[0].daily = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;
            case 'mlarr1':
                if (e.target.value) {
                    chamedsmethod[0].each_dose = e.target.value.replace(/\s*/g, "")
                } else {
                    chamedsmethod[0].each_dose = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;
            case 'mlarr2':
                if (e.target.value) {
                    chamedsmethod[0].at_time = e.target.value.replace(/\s*/g, "")
                } else {
                    chamedsmethod[0].at_time = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;
            case 'units':
                if (e) {
                    chamedsmethod[0].unit = e.replace(/\s*/g, "")
                } else {
                    chamedsmethod[0].unit = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;
            case 'meidays':
                if (e) {
                    chamedsmethod[0].meiday = e.replace(/\s*/g, "")
                } else {
                    chamedsmethod[0].meiday = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;
            case 'radio':
                if (e) {
                    chamedsmethod[0].med_time = e
                } else {
                    chamedsmethod[0].med_time = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;   
            case 'methodarr':
                if (e) {
                    chamedsmethod[0].med_method = e.target.value
                } else {
                    chamedsmethod[0].med_method = ''
                }
                this.setState({
                    chamedsmethod
                })
                break;          
        }
    }
    clickMethod = async (e)=> {
        const {chamedsmethod} = this.state
        if (e && e !=3) {
            chamedsmethod[0].med_method = e
        } else {
            chamedsmethod[0].med_method = ''
        }
        this.setState({
            chamedsmethod
        })
    }

      /**
     * 用户中药用药单位自定义
     * 用药单位自填
     * @param {String} title 输入框标题
     * @param {String} stateName 修改的状态名称
     * @param {Array} stateData 修改状态的数据
     */
    useMedicineAndUnitcha = (title, stateName, stateData) => {
        const { chamedsmethod,unitss } = this.state
        // console.log(title, stateName, stateData,'3774');
        this.setState(
        {
            chamedsmethod
        },
        () => {
            prompt(title, '请输入用药单位', [
            { text: '取消' },
            {
                text: '确认',
                onPress: value => {
                    if (value.replace(/\s*/g, "") == '') {
                        Toast.show('请输入正确的单位');
                        return;
                    }
                    if(value != "ml"){
                        this.setState({
                            unitss:value,
                            visible11:true
                        })
                    }
                    let newData = stateData;
                    newData.unshift(value);
                    this.setState(
                        {
                            [stateName]: newData,
                            selectedUnit: value.replace(/\s/g, "") // 将输入的值设置为 selectedUnit
                        },() => {
                                this.inputRef.value = this.state.selectedUnit; // 设置 input 框的值
                                this.getvalue(this.state.selectedUnit, index, 'units'); // 触发一次 onChange 事件更新 selectedUnit 的值
                            }
                    );
                    if(value){
                        chamedsmethod[0].unit = value
                    }
                    this.setState({
                        chamedsmethod
                    })
                }
            }
            ]);
        }
        );
    };

    // 确定用法用量弹窗
    sureUnits=()=>{
        this.setState({
            visible11:false,
            tipcode:1,//1表示点了确定，0表示未点
        })
    }
    // 关闭自填用药弹窗
    handleSure=(e)=>{
        const { units,unitss } = this.state
        if(e !== 'ml'){
            this.setState({
                unitss:e,
                visible11:true,
            })
        }
        this.setState({
            visible10:false
        })
    }

    // 关闭超量提示弹窗
    handleExcessSure =()=>{
        const { visibleExcess } = this.state
        this.setState({
            visibleExcess:false
        })
    }

    // 取消本单
    handleCancelOrder = async() =>{
        const { visibleCancel,item,docterId } = this.state

        const res = await selectConState({consultationId:item.consultation_id})
        let resState = res.data[0][0]

        if((resState.consultation_stu == '-1' && resState.docter_id == null) || 
            (resState.consultation_stu != '-1' && resState.docter_id != docterId) ||
            (resState.consultation_stu == '3' && resState.docter_id == docterId)
        ){
            this.setState({
                visible13:true,
            })
            return
        }else{
            this.setState({
                visibleCancel: true,
            })
        }        
    }

    // 取消本单原因
    getreason = (e) => {
        this.setState({
          cancelReason: e.target.value.replace(/\s*/g, ""),
        });
    };

    // 取消本单原因
    sureRefuse = async () => {
        const { cancelReason, item, docterId, videofig,vedioTaskId,somegoods,cancelArr } = this.state;
        if (cancelReason) {
            // 超量的问诊中药品的用法用量
            let cancelArrItems=localStorage.getItem("cancelArrItem")
            // 取消本单问诊
            const res = await refusestatus({
                consultationStu: 3,
                consultationId:item.consultation_id,
                consultationReason: cancelReason,
                cancelArrItems: cancelArrItems ? cancelArrItems : null
            });
            if (res && res.code == 1) {
                this.setState({
                    visibleCancel: false,
                    cancelReason:'',
                    otherReasonVis:false
                });
                Toast.info("取消成功");
                this.props.history.replace("/");
            }
        } else {
            Toast.info("请选择取消问诊理由或者输入取消问诊理由");
        }
    };
    
    // 跳转聊天页面 handleChat
    handleChat = async () => {
        const { visibleCancel,item,docterId } = this.state

        // 查询本问诊状态
        const res = await selectConState({consultationId:item.consultation_id})
        let resState = res.data[0][0]

        if((resState.consultation_stu == '-1' && resState.docter_id == null) || 
            (resState.consultation_stu != '-1' && resState.docter_id != docterId) ||
            (resState.consultation_stu == '3' && resState.docter_id == docterId)
        ){
            this.setState({
                visible13:true,
            })
            return
        }else{
            this.props.history.replace("/offlineDoctorchat");
        }
    }

    // 修改病史信息
    setvalue = (flag, e) =>{
        const { historyInfo } = this.state
        let updatedHistoryInfo = {...historyInfo}
        switch (flag) {
            // 疾病史
            case "疾病史有":
                updatedHistoryInfo.past_history = 1
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "疾病史无":
                updatedHistoryInfo.past_history = 0
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "疾病史内容":
                if (e.target.value.replace(/\s*/g, "")) {
                    updatedHistoryInfo.past_history_note = e.target.value.replace(/\s*/g, "")
                    this.setState({
                        historyInfo: updatedHistoryInfo
                    });
                }else{
                    updatedHistoryInfo.past_history_note = ''
                    this.setState({
                        historyInfo: updatedHistoryInfo
                    });
                }
                break;
            
            // 过敏史
            case "过敏史有":
                updatedHistoryInfo.allergic_history = 1
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "过敏史无":
                updatedHistoryInfo.allergic_history = 0
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "过敏史内容":
                if (e.target.value.replace(/\s*/g, "")) {
                    updatedHistoryInfo.allergic_history_note = e.target.value.replace(/\s*/g, "")
                    this.setState({
                        historyInfo: updatedHistoryInfo
                    });
                }else{
                    updatedHistoryInfo.allergic_history_note = ''
                    this.setState({
                        historyInfo: updatedHistoryInfo
                    });
                }
                break;

            // 肝肾功能
            case "肝肾功能有异常":
                updatedHistoryInfo.abnormal_liver_function = 1
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "肝肾功能无异常":
                updatedHistoryInfo.abnormal_liver_function = 0
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            
            // 体温
            case "体温有异常":
                updatedHistoryInfo.bodytemperature = 1
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "体温无异常":
                updatedHistoryInfo.bodytemperature = 0
                this.setState({
                    historyInfo: updatedHistoryInfo
                });
                break;
            case "体温内容":
                // 去除空格
                let bodytempnum = e.target.value.replace(/\s*/g, "");
                // 只能输入数字和小数点
                const bodytempnumSaniti = bodytempnum.replace(/[^0-9.]/g, '')
                if (bodytempnum.replace(/\s*/g, "")) {
                    updatedHistoryInfo.bodytemperaturenum = bodytempnumSaniti
                    this.setState({
                        historyInfo: updatedHistoryInfo
                    });
                }else{
                    updatedHistoryInfo.bodytemperaturenum = ""
                    this.setState({
                        historyInfo: updatedHistoryInfo
                    });
                }
                break;
        }
    }

    // 取消本单选择的值
    handleChangeRadio = (e) =>{
        // console.log(e,'oooooooooooooo');
        const { otherReasonVis } = this.state
        if(e != '其他'){
            this.setState({
                cancelReason: e,
                otherReasonVis:false
            });
        }else{
            // 否则就需要弹出取消输入文本框
            this.setState({
                otherReasonVis: true,
                cancelReason:''
            })
        }
        
    }

    

    render() {
        const {
            visibleMyModal,
            item,
            somegoods,
            visible5,
            icdlists,
            docterProposal, docterProposal1,
            docterProposal2,
            visible,
            visible1,
            m,
            visible3,
            visible4,
            day,
            useway,
            supervisionType, index1,
            danwei,
            icdList, visible6, visible7,
            i,
            j, subcode,
            count,
            k,
            visible2,
            docterDiagnosis,
            chamedsmethod,
            ischinese,
            visible13,
            pickerVisible,
            selectmedsmethod,
            visible10,
            unit,
            meiday,
            meidayss,
            visible11,
            units,
            unitss,
            excessArr,
            visibleExcess,
            visibleCancel,
            cancelReason,
            isSumbit,
            somegoodssystem,
            historyInfo,
            radioList,
            otherReasonVis,
            isSetUseday,
            setUseday,
        } = this.state

        if(somegoods){
            somegoods.forEach(itemA => {
                excessArr.forEach(itemExcess => {
                    if (itemA.medComName == itemExcess.medComName) {
                        itemExcess.medicinalQuantity = itemA.medicinalQuantity
                    }
                });
            });
        }
        // 当前问诊:阿司匹林肠溶片最多9盒，当前12盒；数量过大，请医生核实后再开方。
        const isExcessArrList = excessArr.map(item => `${item.medComName}最多${item.maxDataLargeSplit}${item.packingUnit}，当前${ item.medicinalQuantity? item.medicinalQuantity : ''}${item.packingUnit}`).join('、');

        return (
          <div className="offaddcf-box">
            {/* 超量提醒弹窗  visibleIsExcess */}
            {visibleExcess ? (
                <>
                <div className="cons-type-box-wrap">
                    <div className="cons-type-box">
                        <p className="cons-type-tit">提醒</p>
                        <p className="cons-type-tip">当前问诊：<span className="tip-tuwen">{isExcessArrList}</span>；数量过大，请医生核实后再开方。</p>
                        <p className="cons-type">
                            <span className="type-three" onClick={() => this.handleExcessSure()}>确认</span>
                        </p>
                    </div>
                </div>
                </>
            ) : null}

            {visible11 ? (
                <>
                    <div className="cons-type-box-wrap">
                        <div className="cons-type-box">
                            <p className="cons-type-tit">用法用量提醒</p>
                            <p className="cons-type-tip">您现在用药单位为<span className="tip-units">{unitss ? unitss : chamedsmethod[0].unit}</span>,请注意</p>
                            <p className="cons-type">
                                <span className="type-three" onClick={() => this.sureUnits()}>确认</span>
                            </p>
                        </div>
                    </div>
                </>
            ) : null}

            {visible13 ? (
              <>
                <div className="visible-alert-box1">
                  <div className="visible-alert">
                    <p>患者等待30秒还未开方，已返回到等待接诊状态</p>
                    <span onClick={this.hanldSure}>确定</span>
                  </div>
                </div>
              </>
            ) : null}
            <p className="dutydetail-phone">
              <span>
                如有问题，联系客服：<span id="docterPhone">19983479886</span>
              </span>
              <span className="fuzhi" onClick={() => this.copykefuhone()}>
                复制
              </span>
            </p>
            <div className="offaddcf-top">
              <p className="offaddcf-top-tips">
                {/*<span className='offaddcf-yuan'></span>*/}
                <span className="offaddcf-user">患者信息</span>
              </p>
              <Patientnews news={item} />
            </div>
            <Icdlistsnews
                  icdlists={icdlists}
                  icdList={icdList}
                  visible5={visible5}
                  onscroll={this.onscroll}
                  changeuserinfo={this.changeuserinfo}
                  setIcd={this.setIcd}
                  closev5={this.closev5}
                  delIcd={this.delIcd}
                  onSearch={this.onSearch}
                  changeState={this.changeState}
                />

            {ischinese.length ? (
              <>
                <p className="offaddcf-ill-tipszd offaddcf-ill-tipszd1">
                  <p className="offaddcf-ill-tipszd-top offaddcf-ill-tipszd-top1 offaddcf-ill-tipszd-top12">
                    <span className="offaddcf-zd-title">中药需求：</span>
                    <span className="chameds-count">以下用量为1剂，1剂共计{somegoods.length}味</span>
                  </p>
                </p>
              </>
            ) : (
              <>
                <p className="offaddcf-ill-tipszd offaddcf-ill-tipszd1">
                  <p className="offaddcf-ill-tipszd-top offaddcf-ill-tipszd-top1 offaddcf-ill-tipszd-top12">
                    <span className="offaddcf-zd-title">药品需求：</span>
                    <span className="offaddcf-zd-lx">
                      （
                      {supervisionType == 1
                        ? "中药"
                        : supervisionType == 2
                        ? "西药"
                        : supervisionType == 3
                        ? "成药"
                        : "西药"}
                      ）
                    </span>
                  </p>

                </p>
              </>
            )}
            <Offsomegoods
              somegoodssystem={somegoodssystem}
              somegoods={somegoods}
              visible6={visible6}
              index1={index1}
              ischinese={ischinese}
              showUseways={this.showUseways}
              showV4={this.showV4}
              getvalue={this.getvalue}
              changenum2={this.changenum2}
              showV1={this.showV1}
              showV01={this.showV01}
              showV2={this.showV2}
              showAll={this.state.showAll}
              isSetUseday={isSetUseday}
              setUseday={setUseday}
            />
            {ischinese.length ? (
              <>
                <p className="offaddcf-ill-tipszd offaddcf-ill-tipszd1">
                  <p className="offaddcf-ill-tipszd-top offaddcf-ill-tipszd-top1 offaddcf-ill-tipszd-top12">
                    <span className="offaddcf-zd-title">用法用量：</span>
                  </p>
                </p>
                {chamedsmethod.map((i)=>(
                    <p className="cha-methods">
                        <div className="yongfa">
                            <p className="yongfa1">中药共需
                                <input type="text" maxLength={4} defaultValue={i.co_demand} onChange={(e) => this.handleInputChange(e, "allarr")}/>剂;
                            </p>
                        <p className="yongfa2">
                            <input
                                className="yfunit"
                                type="text"
                                readOnly
                                defaultValue={i.meiday}
                                onChange={(e) => this.handleInputChange(e, "meidays")}
                                ref={(input) => (this.inputRef1 = input)}
                                onClick={() => {
                                    this.setState({
                                        pickerVisible: true,
                                    });
                                }}
                            />
                            <img className="yfunit-img1" src={xiala} />
                            {pickerVisible ? (
                                <div className="up-bottom">
                                    <div>
                                        <div className="use-medicine" >
                                            <span onClick={()=>{
                                                this.setState({
                                                    pickerVisible: false,
                                                })
                                            }}>取消</span>
                                        </div>

                                        {meidayss.map((item, index) => (
                                            <span 
                                                className={ item == this.state.selectedDay ? "red" : "" }
                                                onClick={() =>this.setState({ selectedDay: item })}
                                            >
                                                {item}
                                            </span>
                                        ))}
                                        <p
                                            onClick={() => {
                                                this.handleInputChange(
                                                    this.state.selectedDay,
                                                    "meidays"
                                                );
                                                this.inputRef1.value = this.state.selectedDay;
                                                this.setState({
                                                    pickerVisible: false,
                                                });
                                            }}
                                        >
                                            确定
                                        </p>
                                    </div>
                                </div>
                            ) : null}
                            {/* 每日 */}
                            <input className="input2" type="text" maxLength={4} defaultValue={i.daily} onChange={(e) => this.handleInputChange(e, "dayarr")} />剂
                        </p>
                        </div>
                        <p className="yongfaline1"></p>
                        <div className="yongfa">
                        <p className="yongfa1">每剂分<input type="text" maxLength={4} defaultValue={i.each_dose} onChange={(e) => this.handleInputChange(e, "mlarr1")} />次使用;
                        </p>
                        <p className="yongfa2">每次
                            <input type="text" maxLength={4} defaultValue={i.at_time} onChange={(e) => this.handleInputChange(e, "mlarr2")} />
                            <input
                                className="yfunit"
                                type="text"
                                readOnly
                                defaultValue={i.unit}
                                onChange={(e) => this.handleInputChange(e, "units")}
                                ref={(input) => (this.inputRef = input)}
                                onClick={() => {
                                    this.setState({
                                        visible10: true,
                                    });
                                }}
                            />
                            <img className="yfunit-img" src={xiala} />
                            {visible10 ? (
                                <div className="up-bottom">
                                    <div>
                                    <div
                                        className="use-medicine"
                                        onClick={() => {
                                        this.setState({ visible10: false }, () => {
                                            this.useMedicineAndUnitcha(
                                            "自填用药单位",
                                            "units",
                                            unit
                                            );
                                        });
                                        }}
                                    >
                                        <span>自填用药单位</span>
                                    </div>

                                    {unit.map((item, index) => (
                                        <span
                                            className={
                                                item == this.state.selectedUnit ? "red" : ""
                                            }
                                            onClick={() =>
                                                this.setState({ selectedUnit: item })
                                            }
                                        >
                                            {item}
                                        </span>
                                    ))}
                                    <p
                                        onClick={() => {
                                            this.handleInputChange(
                                                this.state.selectedUnit,
                                                "units"
                                            );
                                            this.inputRef.value = this.state.selectedUnit;

                                            this.setState({
                                                visible10: false,
                                            });

                                            this.handleSure(this.state.selectedUnit,index,'units')
                                        }}
                                    >
                                        确定
                                    </p>
                                    </div>
                                </div>
                            ) : null}
                        </p>
                        </div>
                        <p className="yongfaline1"></p>
                        <div className="yongfa3">
                            <span>用药方式：</span>
                            <p className={ i.med_method == "水煎内服" ? "yongfa3-method2" : "yongfa3-method1"} onClick={() => this.clickMethod("水煎内服")}>
                                {i.med_method == "水煎内服" ? ( 
                                    <img src={gou} alt="" />
                                ) : null}
                                <span className="yongfa4">水煎内服</span>
                            </p>
                            <p className={ i.med_method == "水煎外用" ? "yongfa3-method2" : "yongfa3-method1" } onClick={() => this.clickMethod('水煎外用')}>
                                {i.med_method == "水煎外用" ? (
                                    <img src={gou} alt="" />
                                ) : null}
                                <span className="yongfa4">水煎外用</span>
                            </p>
                        </div>

                        <div className="yongfa33">
                            <p className={ (i.med_method !== "水煎内服" && i.med_method !== "水煎外用") ? "yongfa3-method2" : "yongfa3-method1" }  onClick={() => {this.clickMethod(3);}}>
                                {(i.med_method !== "水煎内服" && i.med_method !== "水煎外用") ? (
                                    <img src={gou} alt="" />
                                ) : null}
                                <span className="yongfa4">其他</span>
                            </p>
                            <input
                                type="text"
                                maxLength={6}
                                placeholder="请输入用药方式"
                                defaultValue={(i.med_method !== "水煎内服" && i.med_method !== "水煎外用") ? i.med_method : ''}
                                onChange={(e) => this.handleInputChange(e,'methodarr')}
                                disabled={i.med_method === "水煎内服" || i.med_method === "水煎外用"}
                            />
                        </div>

                        <p className="yongfaline"></p>
                        <div className="yongfa5">
                        <span style={{ whiteSpace: "nowrap" }}>用药时间：</span>
                        <p className="yongfa6">
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="1"
                                checked={i.med_time == "1"}
                                onChange={(e)=>this.handleInputChange(1,'radio')}
                            />
                            <span>饭前用药</span>
                            </label>
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="2"
                                checked={i.med_time == "2"}
                                onChange={(e)=>this.handleInputChange(2,'radio')}
                            />
                            <span>饭后用药</span>
                            </label>
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="3"
                                checked={i.med_time == "3"}
                                onChange={(e)=>this.handleInputChange(3,'radio')}
                            />
                            <span>睡前用药</span>
                            </label>
                            <label className="yongfa6-time">
                            <input
                                type="radio"
                                name="radiotime"
                                className="radio-time"
                                value="4"
                                checked={i.med_time == "4"}
                                onChange={(e)=>this.handleInputChange(4,'radio')}
                            />
                            <span>晨起用药</span>
                            </label>
                        </p>
                        </div>
                    </p>                   
                ))}

              </>
            ) : null}

            {/* 病史信息 */}
            <div className="offaddcf-historyinfo-box">
                <div className='offaddcf-historyinfo-tit'>
                    <span className='span-yuan'></span>
                    <span className='span-tit'>病史信息</span>
                </div>
                <Historyinfo 
                    news={historyInfo}
                    setvalue={this.setvalue}
                />
            </div>

            {/* 医生建议 */}
            <p className="offaddcf-ill-tips">
              <span className="offaddcf-yuan"></span>
              <span className="offaddcf-user">医生建议:</span>
            </p>
            <p className="shuoming-jianyi">
              <form
                target="#"
                id="search_from"
                action="javascript:return true"
                target="frameFile"
              >
                {ischinese.length ? (
                  <>
                    <textarea
                      placeholder={docterProposal1}
                      defaultValue={docterProposal}
                      maxLength={60}
                      onChange={(e) => this.changeuserinfo(e, 5)}
                    />
                  </>
                ) : (
                  <>
                    <textarea
                      placeholder={docterProposal1}
                      defaultValue={docterProposal}
                      maxLength={60}
                      onChange={(e) => this.changeuserinfo(e, 5)}
                    />
                  </>
                )}
                <iframe
                  id="frameFile"
                  name="frameFile"
                  style={{ display: "none" }}
                ></iframe>
              </form>
            </p>
            <div className="offaddcf-button-cancel-submit-chat">
                <div className='chat-icon'>
                    <img src={chaticon} onClick={this.handleChat} />
                    {/* <Image src={chaticon} height={44} weight={44}  onClick={this.handleChat} /> */}
                </div>
                <div className='cancel-submit-btn'>
                    {isSumbit ? null : (
                        <>
                            <button className='cancel-btn' onClick={this.handleCancelOrder}>取消本单</button>
                        </>
                    )}
                    <button className='submit-btn' onClick={this.handleSubmitTip}>提交处方</button>
                </div>
              
            </div>

            {/* 确认取消本单 */}
            {visibleCancel ? (
                <div
                    className="cancel-cons-wrap-radio"
                    onClick={() => this.setState({ visibleCancel: false,cancelReason:'',otherReasonVis:false })}
                >
                    <div className='refusewz-edit' onClick={(e) => e.stopPropagation()}>
                        <p className="refusewz-tips">请选择取消理由</p>
                        <div className='refuse-radio-box'>
                            <Radio.Group onChange={this.handleChangeRadio}>
                                <Space direction='vertical'>
                                    {radioList.length && radioList.map((item,index)=>(
                                        <Radio value={item.value}>{item.label}</Radio>
                                    ))}
                                </Space>
                            </Radio.Group>
                        </div>
                        
                        {otherReasonVis && <textarea
                            placeholder="请输入取消原因(50字以内)"
                            maxLength={50}
                            defaultValue={cancelReason}
                            onChange={(e) => this.getreason(e)}
                        ></textarea>}
                        <p className="refusewz-button">
                            <span
                                onClick={() =>
                                    this.setState({
                                        visibleCancel: false,
                                        cancelReason:'',
                                        otherReasonVis:false
                                    })
                                }
                            >
                                取消
                            </span>
                            <span onClick={() => this.sureRefuse()}>确认</span>
                        </p>
                    </div>
                </div>
            ) : null}

            {/* {visibleCancel ? (
                <div
                    className="cancel-cons-wrap"
                    onClick={() => this.setState({ visibleCancel: false })}
                >
                    <div className='refusewz-edit' onClick={(e) => e.stopPropagation()}>
                        <p className="refusewz-tips">确认取消本单</p>
                        <textarea
                            placeholder="输入取消原因"
                            maxLength={40}
                            defaultValue={cancelReason}
                            onChange={(e) => this.getreason(e)}
                        ></textarea>
                        <p className="refusewz-button">
                            <span
                                onClick={() =>
                                    this.setState({
                                        visibleCancel: false,
                                    })
                                }
                            >
                                取消
                            </span>
                            <span onClick={() => this.sureRefuse()}>确定</span>
                        </p>
                    </div>
                </div>
            ) : null} */}

            {visible ? (
              <div className="up-bottom">
                <div>
                  {day.map((item, index) => (
                    <span
                      className={index == j ? "red" : ""}
                      onClick={() => this.dianji(index)}
                    >
                      {item}
                    </span>
                  ))}
                  <p onClick={this.closeDay}>确定</p>
                </div>
              </div>
            ) : null}
            {visible1 ? (
              <div className="up-bottom">
                <div>
                  {/* <Button 
                        ><span></span></Button> */}
                  <div
                    className="use-medicine"
                    onClick={() => {
                      this.setState({ visible1: false }, () => {
                        // 自填用药单位    let unit = this.state.danwei   this.closeDanwei()
                        this.useMedicineAndUnit(
                          "自填用药单位",
                          "请输入自填用药单位",
                          "danwei",
                          danwei,
                          this.closeDanwei
                        );
                      });
                    }}
                  >
                    <span>自填用药方法</span>
                  </div>

                  {danwei.map((item, index) => (
                    <span
                      className={index == i ? "red" : ""}
                      onClick={() => this.dianji1(index)}
                    >
                      {item}
                    </span>
                  ))}
                  <p onClick={this.closeDanwei}>确定</p>
                </div>
              </div>
            ) : null}
            {visible2 ? (
              <div className="up-bottom">
                <div>
                  {count.map((item, index) => (
                    <span
                      className={index == k ? "red" : ""}
                      onClick={() => this.dianji2(index)}
                    >
                      {item}
                    </span>
                  ))}
                  <p onClick={this.closeCount}>确定</p>
                </div>
              </div>
            ) : null}

            {visible4 ? (
              <div className="up-bottom">
                <div>
                  <div
                    className="use-medicine"
                    onClick={() => {
                      this.setState({ visible4: false }, () => {
                        // this.useMedicine()
                        this.useMedicineAndUnit(
                          "自填用药方法",
                          "请输入自填用药方法",
                          "useway",
                          useway,
                          this.closeway
                        );
                      });
                    }}
                  >
                    <span>自填用药方法</span>
                  </div>

                  {useway.map((item, index) => (
                    <span
                      className={index == m ? "red" : ""}
                      onClick={() => this.dianji4(index)}
                    >
                      {item}
                    </span>
                  ))}
                  <p onClick={this.closeway}>确定</p>
                </div>
              </div>
            ) : null}
            {visible3 ? (
              <div className="add-icd">
                {/*// <p className='icd-details-one' onClick={(e)=>this.setIcd(e,item)}>*/}
                {/*//     <span style={{marginRight:'20px'}}>{item.icdValue}</span>*/}
                {/*//     <span>{item.icdName}</span>*/}
                {/*// </p>*/}
                <div className="icd-top">
                  <span
                    style={{
                      width: "100%",
                      display: "inline-block",
                      textAlign: "center",
                    }}
                  >
                    诊断
                  </span>
                </div>
                <ul className="icd-details icd-details1">
                  {icdList.length
                    ? icdList.map((item) => (
                        <li
                          className="icd-details-one"
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        >
                          <span>
                            {item.icdValue} &nbsp; {item.icdName}
                          </span>
                          <input
                            onClick={(e) => this.setIcd(e, item)}
                            type="checkbox"
                            checked={item.state == 2}
                            name="icd"
                          />
                          <br />
                        </li>
                      ))
                    : null}
                </ul>
                <div className="icd-button">
                  <img src={icon5} onClick={() => this.onSure()} />
                </div>
              </div>
            ) : null}
            {visible7 ? (
              <div className="change-meds-outer">
                <div className="change-meds-inner">
                  <h1>修改药品</h1>
                  {/* <p className='del-tips'>删除药品后不可恢复，请医生谨慎修改</p> */}
                  {somegoods.map((item) => (
                    <div className="one-meds">
                      <p className="name-spec">
                        <span className="name">{item.medComName}</span>
                        <span className="spec">{item.medicinalSpec}</span>
                      </p>
                      <p className="del-update">
                        {/* <span className='delete' onClick={() => this.toDelete(item.medId)}>
                                        <i className='iconfont icon-shanchu1' />
                                        <span>删除</span>
                                    </span> */}
                        <span className="add-reduce-count">
                          <span
                            className="reduce"
                            onClick={() =>
                              this.reduceCount(
                                item.medId,
                                item.medicinalQuantity
                              )
                            }
                          >
                            -
                          </span>
                          <span className="count">
                            {item.medicinalQuantity}
                          </span>
                          <span
                            className="add"
                            onClick={() =>
                              this.addCount(item.medId, item.medicinalQuantity)
                            }
                          >
                            +
                          </span>
                        </span>
                      </p>
                    </div>
                  ))}
                  <div className="refuse-button">
                    <span className="refuse" onClick={() => this.toRefuse()}>
                      取消
                    </span>
                    <span
                      className="sure-button"
                      onClick={() => this.toSureupdate()}
                    >
                      确定
                    </span>
                  </div>
                </div>
              </div>
            ) : null}
            {subcode == 2 ? (
              <p className="chat-center-outer">
                <SpinLoading />
                {/*<p>跳转中...</p>*/}
              </p>
            ) : null}
          </div>
        );
    }
}
