import React, { Component } from 'react'
import "./style.less"
import FH from "../../../static/img/mine/fanhui.png"
import { getuserbusiness } from "../../../api/request/user"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            businesslist: [],
            userId: localStorage.type == 1 ? localStorage.userId1 : localStorage.type == 2 ? localStorage.userId2 : localStorage.userId3
        }
    }
    componentDidMount = async () => {
        const res = await getuserbusiness({
            userId: this.state.userId
        })
        this.setState({
            businesslist: res.data[0].reverse()
        })
    }
    tonext = (item) => {
        this.props.history.push({
            pathname: "/mine/bill/billinfo",
            params: {
                item
            }
        })
    }
    render() {

        const { businesslist } = this.state
        return (
            <div className="billbox">
                <div className="billbox-top">
                    <img src={FH} alt="" onClick={() => window.history.back()} /><h2>账单</h2>
                </div>
                <div className="billbox-search">
                    <input type="text" placeholder="搜索账单相关信息" />
                </div>
                <div className="billbox-item">
                    {businesslist.map((item, index) => {
                        return (
                            <div onClick={() => this.tonext(item)} key={index}>
                                <p className="billbox-item-money">{item.Amount / 100}</p>
                                <p className="billbox-item-p1">{item.Comments == "订单退款" ? "收入" : "支出"}</p>
                                <p className="billbox-item-p2">
                                    <span>{item.createTime}</span>
                                </p>
                            </div>
                        )
                    })}

                </div>
            </div>
        )
    }
}
