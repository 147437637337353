import { axiosInstance } from '../config'

//添加订单
export const uploadQualifications = (data) => {
    return axiosInstance.post('/api/hosp/user/qualifications', data)
}
//用户资质列表
export const qualificationsList = () => {
    return axiosInstance.get('/api/hosp/user/qualifications-list')
}
// 
//采购用户资质修改
export const updateQualifications = (data) => {
    return axiosInstance.post('/api/hosp/user/update-qualifications', data)
}
//取省区域
export const getProvince = (data) => {
    return axiosInstance.post('/api/hosp/address/get-province', data)
}
//取市区域
export const getCity = (data) => {
    return axiosInstance.post('/api/hosp/address/get-city', data)
}
//取区区域
export const getDistrict = (data) => {
    return axiosInstance.post('/api/hosp/address/get-district', data)
}
// 获取用户资质信息及状态
export const getqualifications = (data) => {
    return axiosInstance.post('/api/hosp/user/getqualifications', data)
}