import { axiosInstance } from '../config'

//钱包充值
export const addWallet = (data) => {
    return axiosInstance.post('/api/hosp/wallet/add', data)
}
//钱包消费
export const consumptionWallet = (data) => {
    return axiosInstance.post('/api/hosp/wallet/consumption', data)
}
//钱包余额
export const walletBalance = (data) => {
    return axiosInstance.post('/api/hosp/wallet/balance', data)
}
//钱包收支明细
export const walletList = (data) => {
    return axiosInstance.post('/api/hosp/wallet/list', data)
}
//钱包提现
export const walletWithdrawal = (data) => {
    return axiosInstance.post('/api/hosp/wallet/withdrawal', data)
}
//钱包提现记录
export const walletRecord = (data) => {
    return axiosInstance.post('/api/hosp/wallet/record', data)
}