import React, { Component } from 'react'
import FH from "../../../../static/img/mine/fanhui.png"
import icon from "../../../../static/img/xq.png"
import { oderdetailRequest } from "../../../../api/request/order"
import "./style.less"
export default class index extends Component {
    constructor() {
        super()
        this.state = {
            item: {},
            ordertext: {},
            medlist:[]
        }
    }
    componentDidMount = async () => {
        this.setState({
            item: this.props.location.params.item
        }, async() => {
            const res = await oderdetailRequest(this.state.item.orderNumber)
            //console.log(res);
            this.setState({
                ordertext:res.data[0][0],
                medlist:res.data[1]
            })
        })

    }
    render() {
        const { item ,ordertext,medlist} = this.state
        return (
            <div className="billinfobox">
                <div className="billinfobox-top">
                    <img src={FH} alt="" onClick={() => window.history.back()} /><h2>账单详情</h2>
                </div>
                <div className="billinfobox-title">
                    <p>{item.Comments == "订单退款" ? "+" : "-"}{item.Amount / 100}</p>
                    <p>交易成功</p>
                </div>
                <div className="billinfobox-text">
                    <div>
                        <p>商家</p>
                        <p>{ordertext.pharmacyName}</p>
                    </div>
                    <div className="billinfobox-text-img">
                        <p>商品</p>
                        <div>
                            {medlist.map((item,index)=>{
                                return (
                                    <img src={item.medPhoto} alt="" />
                                )
                            })}
                        </div>
                        <p className="billinfobox-text-img-p">＞</p>
                    </div>
                    <div>
                        <p>支付方式</p>
                        <p>{ordertext.payMethod}</p>
                    </div>
                    <div>
                        <p>下单时间</p>
                        <p>{ordertext.createTime}</p>
                    </div>
                    <div>
                        <p>查看发票</p>
                        <p>＞</p>
                    </div>
                    <div>
                        <p>订单编号</p>
                        <p>{ordertext.orderNumber}</p>
                    </div>
                </div>
                <div className="billinfobox-doubt">
                    <p>对此订单有疑问</p>
                    <p>＞</p>
                </div>
            </div>
        )
    }
}
