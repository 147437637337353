import { axiosInstance } from '../config'

//推荐
export const healthrecommandRequest = (data) => {
    return axiosInstance.post('/api/hosp/health/recommend', data)
}

//话题
export const healthtopicRequest = () => {
    return axiosInstance.get('/api/hosp/health/topic')
}

//话题列表
export const topiclistRequest = () => {
    return axiosInstance.get('/api/hosp/health/topiclist')
}

//发表帖子
export const healthpostRequest = (data) => {
    return axiosInstance.post('/api/hosp/health/publish', data)
}

//点赞
export const likeRequest = (postId) => {
    return axiosInstance.get(`/api/hosp/health/top?postId=${postId}`)
}