import React from 'react'
import {withRouter} from "react-router-dom"

import Head from '../../component/Head'
import BaseInput from '../../baseui/input/index'
import Tag from '../../baseui/tag/index'
import DoctorItem from '../../application/doctor/doctoritem'

import iconA from '../../static/img/icon/on1.png'
import iconB from '../../static/img/icon/on2.png'
import './style.less'
// import IMG from '../../../static/img/goods.jpg'

function Inquiry(props) {

    return(
        <>
            <Head title='在线问诊'/>
            <div className='inquiry-screen-page'>
                <div className='inquiry-search flex-col'>
                    <p>在线问诊·医生开方·送药到家</p>
                    <BaseInput value={'搜索症状、科室、医生、医院等'}/>
                </div>
                
                <div className='inquiry-choose flex-apart-row'>
                     <div className="inquiry-way flex-row" onClick={()=>props.history.push('/inquiry/disease')}>
                        <img src={iconA} alt=''/>
                        <div className="flex-col">
                            <span>按疾病找</span>
                            <span>1780种疾病</span>
                        </div>
                    </div>
                    <div className="inquiry-way flex-row" onClick={()=>props.history.push('/inquiry/hospital')}>
                        <img src={iconB} alt=''/>
                        <div className="flex-col">
                            <span>按医院找</span>
                            <span>2300家医院</span>
                        </div>
                    </div>
                </div>
                <div className='doctor-recommend'>
                    <p>优质推荐</p>
                    <div className='doctor-recommend-tabs flexr'>
                        
                        <Tag text={'全部'} type={'radius'} active={true}/>
                        <Tag text={'肺炎'} type={'radius'} active={false}/>
                        <Tag text={'过敏性鼻炎'} type={'radius'} active={false}/>
                        <Tag text={'支气管炎'} type={'radius'} active={false}/>
                        <Tag text={'咳嗽'} type={'radius'} active={false}/>
                        <Tag text={'血管阻塞'} type={'radius'} active={false}/>
                    </div>
                    <DoctorItem/>
                </div>
            </div>

        </>
    )
}
export default withRouter(Inquiry)