import React ,{useState}from 'react'
import {withRouter} from "react-router-dom"
import { List, WhiteSpace,Tabs } from 'antd-mobile'

import Head from '../../component/Head'
import Tag from '../../baseui/tag/index'

import './recipel.less'

const Item = List.Item

function Recipel(props) {
    
    const tabs = [
        { title: '开具电子处方' },
        { title: '上传处方清单' }
    ]

    const [user]=useState(true)
    const renderContent = (tab,index) =>{
        return(
            <>
            {
            index===0
            ?<div className='recipel-content1'>
                <p>填写已确诊疾病<input type='text' placeholder='线下确诊的疾病'></input></p>
                <Item arrow="horizontal" onClick={() => {}}>选择开方医生（可选）</Item>
                <p>请谨慎确认下列条件：</p>
                <p>1.你服用过该药品且无相关禁忌症。</p>
                <p>2.你对该药物无过敏反应。</p>
                <p>3.你对该药物无不良反应。</p>
                <WhiteSpace></WhiteSpace>
                <p>选择线下就诊的病历/历史处方</p>
                <div className='upload-img flex-row'>
                    <span>+</span>
                </div>
            </div>
            :<div className='recipel-content2'>
                <p>请上传医生开具的处方单：</p>
                <p>1.处方单不等于病历本、检查结果、伤患照片等</p>
                <p>2.处方单需在有效期内使用</p>
                <p>3.处方单信息需与有预约单的用药人、药品名称、规格、剂型、数量等信息一致。</p>
                <div className='flexr'>
                    <div className='upload-img flex-row'>
                    </div>
                    <div className='upload-img flex-row'>
                        <span>+</span>
                    </div>
                </div>

            </div>
            }
            </>

        )
    }

    return(
        <>
            <Head title='处方信息'></Head>
            <div className='screen' style={{bottom:50}}>
                <WhiteSpace></WhiteSpace>
                <p className='recipel-title'>用药人信息</p>
                {user?
                <>
                    <Item arrow="horizontal" onClick={() => {}}><span style={{fontSize:15,fontWeight:'bold'}}>杨煜</span>&nbsp&nbsp&nbsp138 8028 9018</Item>
                    <WhiteSpace></WhiteSpace>
                    <Item><div className='recipel-item'><span>健康信息</span><span>无过敏史</span></div></Item>
                    <WhiteSpace></WhiteSpace>
                    <Item><div className='recipel-item'><span>血糖值</span><span>6mmol/L</span></div></Item>
                    <WhiteSpace></WhiteSpace>
                    <Item><div className='recipel-item'><span>血压值</span><span>6.2mmHg</span></div></Item>
                </>
                   
                :<>
                    <div className='add-user'>
                        <i className='iconfont icon-tianjia'>添加用药人</i>
                    </div>
                    <p className='user-phone'>手机号138 8028 9018</p>
                </>
                }
                

                <WhiteSpace></WhiteSpace>
                <div className='baseill-info flexc'>
                    <span>基础病信息</span>
                    <div>
                        <Tag maincolor='#F34848' subcolor='#F4E3E5' text='高血压'/>
                        <Tag maincolor='#F34848' subcolor='#F4E3E5' text='原发高血压性'/>
                        <Tag maincolor='#F34848' subcolor='#F4E3E5' text='+'/>
                    </div>
                </div>
                <WhiteSpace></WhiteSpace>
                <div className='online-recipel'>
                    <Tabs
                        tabs={tabs}
                        tabBarActiveTextColor="#1F87FF"
                        tabBarUnderlineStyle={{border: '2px #1F87FF solid',bottom:5,transform:'scaleX(.2)'}}
                        renderTabBar={props => <Tabs.DefaultTabBar {...props} page={2} />}>
                        {renderContent}
                    </Tabs>
                </div>
            </div>
            <div className='bottom-button' onClick={()=>props.history.push('/inquiry/list/imagetext')}>下一步</div>
        </>
    )
}

export default withRouter(Recipel)