import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

import acttest from '../../static/img/app/acttest.jpeg'
import './style.less'


class ActPage extends Component {
    constructor(props) {
        super(props)
        this.state = {}
    }

    backPage=()=>{
        window.history.back()
    }

    render() {
        
        return (
            <div className='active-container'>
                <div className='actpage-header'>
                    <div className='actpage-header-zhanwei' onClick={this.backPage}>
                        <i className='iconfont icon-xiangzuo'></i>
                    </div>
                </div>
                <div className='actpage-content'>
                    <img src={acttest} alt=''/>
                </div>
            </div>
        )
    }
}

export default withRouter(ActPage)