import React from 'react'
import { Tabs } from 'antd-mobile'

import DoctorItem from '../../../application/doctor/doctoritem'
import Head from '../../../component/Head'
import Tag from '../../../baseui/tag/index'

import './style.less'

const hospital={id:'1',name:'成都市锦江区第二人民医院',title:['三级甲等','综合医院'],location:'成都市青羊区青龙街82号',phone:'028-61312288',count:700,num:58}
const tabs=[{ title: '全部科室' },{ title: '儿科' },{ title: '五官科' },{ title: '心内科' },{ title: '疼痛科' },{ title: '骨科' },{ title: '神经科' }]


export default function InquiryHospial(props) {

    const  renderContent = (tab,index) =>{
        return(
            <>
                <div className='hospital-listtab flex-row'>
                    <div>
                        <span>推荐</span>
                        <i className='iconfont-s icon-xiangxia1'></i>
                    </div>
                    <div>
                        <span>价格</span>
                        <i className='iconfont-s icon-shaixuan'></i>
                    </div>
                </div>
                <DoctorItem/>
            </>
        )
    }

    return(
        <>
            <Head title='成都市锦江区第二人民医院' iconname='icon-weimingmingwenjianjia_sousuo'/>
            <div className='hospital-item flexc'>
                <div className='fllow-hospital'>
                    <Tag text={'关注'} type={'radius'} active={true}/>
                </div>
                <div className='hospital-item-title'>
                    {
                        hospital.title.map((t,i)=>{
                            return(<Tag key={i} text={t} type='frame' maincolor={i===0?'#FFB740':'#FF6778'} subcolor={i===0?'#FFF3E0':'#FFE8EA'}/>)
                        })
                    }
                </div>
                <div className='hospital-item-detail'>
                    <i className='iconfont icon-dingwei1'></i>
                    <span>{hospital.location}</span>
                </div>
                <div className='hospital-item-detail'>
                    <i className='iconfont icon-tubiao210'></i>
                    <span>{hospital.phone}</span>
                </div>
                <div className='hospital-item-heal'>
                    入驻医生&emsp;<span>{hospital.num}</span>&emsp;&emsp;&emsp;累计诊断次数&emsp;<span>{hospital.count}</span>
                </div>
            </div>
            <div className='hospital-doctorlist'>
                <Tabs
                tabs={tabs}
                tabBarTextStyle={{fontSize:'14px',padding:'0',height:'47px'}}
                tabBarActiveTextColor="#0B1523"
                tabBarInactiveTextColor="#353A42"
                tabBarUnderlineStyle={{border: '3px #1F87FF solid',transform:'scaleX(0.5)',bottom:'5px'}}
                renderTabBar={props => <Tabs.DefaultTabBar {...props} page={6}/>}>
                    {renderContent}
                </Tabs>
            </div>


        </>
    )
}