import React from 'react'

import './style.less'

export default function ChooseButton(props) {
    // const [flex, setflex] = useState('row');
    // const [chooselist, setchooselist] = useState([]);
    
    return(
        <div>
            {
            props.flex==='row'?(
                <div className='row-bottom-button flexr'>
                    {props.children}
                </div>
            ):null
            }
            {
            props.flex==='col'?(
                <div className='col-bottom-button flexc'>
                    {props.children}
                </div>
            ):null
            }
        </div>
    )
}